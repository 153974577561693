import React from 'react';

import { Col, Flex, Row } from 'antd';

import GoalSmall from 'resources/img/faGoalSmall.svg';
import GoalSmallRed from 'resources/img/faGoalSmallRed.svg';
import RedCardSmall from 'resources/img/faRedCardSmall.svg';
import YellowCardSmall from 'resources/img/faYellowCardSmall.svg';
import './index.css';

const EVENT_ICONS: any = {
  red_card: <img height={22} src={RedCardSmall} />,
  yellow_card: <img height={22} src={YellowCardSmall} />,
  goal: <img height={22} src={GoalSmall} />,
  own_goal: <img height={22} src={GoalSmallRed} />,
};
const PlayerRow = (player: any) => {
  console.log(player);
  return (
    <div className="game-protocol-lineup-player-wrapper">
      <Row gutter={8} key={player?.id}>
        <Col span={2}>
          <div className="player-number">{player?.number}</div>
        </Col>
        <Col span={8}>{player?.name}</Col>
        <Col span={4}>
          {player.substOut?.map((evt: any, index: number) => (
            <Flex
              gap={8}
              className="flex-row f-ga-8 ai-c"
              key={index}
              align="center"
            >
              <div className="triangle-down"></div>
              <div className="timing">{evt.time}'</div>
            </Flex>
          ))}
          {player.substIn?.map((evt: any, index: number) => (
            <Flex
              gap={8}
              className="flex-row f-ga-8 ai-c"
              key={index}
              align="center"
            >
              <div className="triangle-up"></div>
              <div className="timing">{evt.time}'</div>
            </Flex>
          ))}
        </Col>
        <Col span={4}>
          {player.events
            .filter(
              (evt: any) =>
                evt.type === 'yellow_card' || evt.type === 'red_card',
            )
            .map((evt: any, index: number) => (
              <Flex
                gap={4}
                key={index}
                align="center"
                style={{ marginBottom: '8px' }}
              >
                {EVENT_ICONS[evt.type]}
                <div className="timing">{evt.time}'</div>
              </Flex>
            ))}
        </Col>
        <Col span={4}>
          {player.events
            .filter((evt: any) => evt.type === 'goal')
            .map((evt: any, index: number) => (
              <Flex
                gap={4}
                key={index}
                align="center"
                style={{ marginBottom: '8px' }}
              >
                {EVENT_ICONS[evt.type]}
                <div className="timing">{evt.time}'</div>
              </Flex>
            ))}
        </Col>
        <Col span={2}>{player?.tagsCount}</Col>
      </Row>
      {(player.substOut.length > 0 || player.substIn.length > 0) && (
        <Row align="middle" style={{ marginTop: '8px' }}>
          <Col span={24}>
            {player.substOut?.map((evt: any, index: number) => (
              <Flex
                gap={8}
                className="flex-row f-ga-8 ai-c"
                key={index}
                align="center"
              >
                <div className="timing">{evt.time}'</div>
                <div className="triangle-up"></div>
                <div className="player-number">{evt.player.number}</div>
              </Flex>
            ))}
            {player.substIn?.map((evt: any, index: number) => (
              <Flex
                gap={8}
                className="flex-row f-ga-8 ai-c"
                key={index}
                align="center"
              >
                <div className="timing">{evt.time}'</div>
                <div className="triangle-down"></div>
                <div className="player-number">{evt.player.number}</div>
              </Flex>
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PlayerRow;
