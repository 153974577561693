import React, { FC } from 'react';

import type { TableColumnsType } from 'antd';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './style.css';

import { SelectTrackForEditAction } from 'store/actions/recorder.actions';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { FastEventType } from 'types';

interface DataType {
  key: React.Key;
  name: string;
  id: number;
  eventId: number;
  event_id: number;
  player: string;
  playerSecond: string;
  team: string;
  team2: string;
  start: string;
  startMs: number;
  fastEvent: string;
}

const makeFilterEvents = (activeEvents: any) => {
  return activeEvents.map((ev: any) => ({
    text: ev.label ? ev.label : ev.name,
    value: ev.label ? ev.label : ev.name,
  }));
};

const getPlayerOne = (track: any, isDuel: boolean, team: { key: string }) => {
  if (!track?.attributes || track?.attributes.length == 0) {
    return '';
  }
  const [firstPlayer = false] = track.attributes.filter(
    (attr: any) =>
      attr.key == 'player' ||
      attr.key == 'player_in' ||
      attr.key == (isDuel ? `player_${team.key.toLowerCase()}` : 'player_home'),
  );
  if (firstPlayer) {
    const { values = [] } = firstPlayer;
    const [plNum = {}] = values;
    const { value = '' } = plNum;
    return value;
  }
  return '';
};

const getPlayerTwo = (track: any, isDuel: boolean, team: { key: string }) => {
  if (!track?.attributes || track?.attributes.length == 0) {
    return '';
  }
  const duelSecondPlayerName =
    isDuel && team.key.toLowerCase() == 'away' ? 'player_home' : 'player_away';
  const [secondPlayer = false] = track.attributes.filter(
    (attr: any) =>
      attr.key == 'player_opposite' ||
      attr.key == 'player_out' ||
      attr.key == duelSecondPlayerName,
  );
  if (secondPlayer) {
    const { values = [] } = secondPlayer;
    const [plNum = {}] = values;
    const { value = '' } = plNum;
    return value;
  }
  return '';
};

const getTeam = (track: any, activeTask: any) => {
  if (!track?.attributes || track?.attributes.length == 0) {
    return { name: '', key: '' };
  }
  const [teamAtr = false] = track.attributes.filter(
    (attr: any) => attr.key == 'team',
  );
  if (teamAtr) {
    const { values = [] } = teamAtr;
    const [team = {}] = values;
    const { value = '' } = team;
    if (value.toLowerCase() == 'home') {
      return { name: activeTask?.home_team_name || value, key: value };
    }
    if (value.toLowerCase() == 'away') {
      return { name: activeTask?.away_team_name || value, key: value };
    }
    return { name: value, key: value };
  }
  return { name: '', key: '' };
};

const getOtherAttrs = (track: any) => {
  if (!track?.attributes || track?.attributes.length == 0) {
    return [];
  }
  return track.attributes.filter(
    (attr: any) =>
      attr.key !== 'team' &&
      attr.key !== 'player_opposite' &&
      attr.key !== 'player_out' &&
      attr.key !== 'player_away' &&
      attr.key !== 'player' &&
      attr.key !== 'player_in' &&
      attr.key !== 'player_home',
  );
};

export const TableEvents: FC<any> = ({ handleVideoScroll }) => {
  const activeTask = useSelector(
    (state: AppStateType) => state.taskReducer.activeTask,
  );
  const { addFastEventAction, setShowCoordinatesModal } =
    fastEventReducer.actions;
  const dispatch = useDispatch();
  const playersOne: any[] = [];
  const playersTwo: any[] = [];
  const teams: any[] = [];
  const teamsTwo: any[] = [];
  const attributes: any = {};
  let elseAttrsForTable: any[] = [];
  const makePlainEventsList = (activeEvents: any) => {
    const plainEvents: any[] = [];
    if (!activeTask) {
      return plainEvents;
    }
    activeEvents.forEach((evGroup: any, i: number) => {
      evGroup.tracks.forEach((track: any, index: number) => {
        const [fastEventData] = activeTask.task_fast_events.filter(
          (fe) => fe.id == track.fast_event,
        );
        const isDuel = fastEventData?.key == 'duel';
        const timeInSec = Math.floor(track.start);
        const secondParts = Math.floor((track.start % 1) * 1000);
        const sec = timeInSec % 60;
        const totalMin = Math.floor(timeInSec / 60);
        const min = totalMin % 60;
        const hour = Math.floor(totalMin / 60);
        const team = getTeam(track, activeTask);
        const playerOne = getPlayerOne(track, isDuel, team);
        const playerTwo = getPlayerTwo(track, isDuel, team);
        let teamTwoName: any = '';
        if (team.name && playerTwo) {
          teamTwoName =
            team.name == activeTask?.away_team_name
              ? activeTask?.home_team_name
              : activeTask?.away_team_name;
        }
        const otherAttrs = {};
        getOtherAttrs(track).forEach((attr: any) => {
          if (!attr.name || !attr.values) {
            return;
          }
          //@ts-expect-error
          otherAttrs[attr.name] = attr.values[0]?.value;
          // Внешняя зависимость от нахождения в компоненте, плохо, но необходимо для производительности
          if (attributes[attr.name]) {
            attributes[attr.name].add(attr.values[0]?.value);
          } else {
            attributes[attr.name] = new Set([attr.values[0]?.value]);
          }
        });
        plainEvents.push({
          key: `${i}_${index}`,
          id: track.id,
          eventId: evGroup.id,
          event_id: evGroup.id,
          fastEvent: track.fast_event,
          name: evGroup.label ? evGroup.label : evGroup.name,
          player: playerOne,
          playerSecond: playerTwo,
          team: team.name,
          team2: teamTwoName,
          start: `${('0' + hour).slice(-2)}:${('0' + min).slice(-2)}:${('0' + sec).slice(-2)}.${('000' + secondParts).slice(-3)}`,
          startMs: track.start * 1000,
          ...otherAttrs,
        });
        // Внешняя зависимость от нахождения в компоненте, плохо, но необходимо для производительности
        playersOne.push(playerOne);
        playersTwo.push(playerTwo);
        teams.push(team.name);
        teamsTwo.push(teamTwoName);
      });
    });
    elseAttrsForTable = Object.keys(attributes).map((atrKey: any) => {
      return {
        title: atrKey,
        dataIndex: atrKey,
        onFilter: (value: string, record: any) => record[atrKey] === value,
        filters: Array.from(attributes[atrKey]).map((val: any) => ({
          text: val,
          value: val,
        })),
      };
    });
    return plainEvents;
  };

  const activeEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.activeEvents,
  );
  // порядок вызова тут крайне важен, так как у makePlainEventsList есть сайд эффект
  const data: DataType[] = makePlainEventsList(activeEvents);
  const playerOneFilter = [...new Set(playersOne)].map((plNum) => ({
    text: plNum,
    value: plNum,
  }));
  const playerTwoFilter = [...new Set(playersTwo)].map((plNum) => ({
    text: plNum,
    value: plNum,
  }));
  const teamFilter = [...new Set(teams)].map((team) => ({
    text: team,
    value: team,
  }));
  const teamTwoFilter = [...new Set(teamsTwo)].map((team) => ({
    text: team,
    value: team,
  }));
  const columns: TableColumnsType<DataType> = [
    {
      title: 'id',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      filters: makeFilterEvents(activeEvents),
      onFilter: (value: string, record) => record.name.indexOf(value) === 0,
    },
    {
      title: 'Игрок 1',
      dataIndex: 'player',
      filters: playerOneFilter,
      onFilter: (value: string, record) => record.player === value,
    },
    {
      title: 'Команда 1',
      dataIndex: 'team',
      filters: teamFilter,
      onFilter: (value: string, record) => record.team === value,
    },
    {
      title: 'Игрок 2',
      dataIndex: 'playerSecond',
      filters: playerTwoFilter,
      onFilter: (value: string, record) => record.playerSecond === value,
    },
    {
      title: 'Команда 2',
      dataIndex: 'team2',
      filters: teamTwoFilter,
      onFilter: (value: string, record) => record.team2 === value,
    },
    {
      title: 'Время',
      dataIndex: 'start',
      sorter: (a, b) => {
        return (
          // @ts-expect-error
          a.start.replace(':', '').replace(':', '').replace('.', '') -
          // @ts-expect-error
          b.start.replace(':', '').replace(':', '').replace('.', '')
        );
      },
    },
    ...elseAttrsForTable,
  ];
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      if (!activeTask) {
        return;
      }
      const [fastEvent]: FastEventType[] = activeTask.task_fast_events.filter(
        (fe: FastEventType) => fe.id == selectedRows[0].fastEvent,
      );
      if (!fastEvent) {
        return;
      }
      const { selectTrackForEdit } = recorderReducerNew.actions;
      dispatch(setShowCoordinatesModal(true));
      dispatch(addFastEventAction(fastEvent));
      dispatch(
        SelectTrackForEditAction(selectedRows[0].eventId, selectedRows[0].id),
      );
      dispatch(
        selectTrackForEdit({
          eventId: selectedRows[0].eventId,
          trackId: selectedRows[0].id,
        }),
      );
      let startPlayerView = selectedRows[0].startMs;
      if (startPlayerView > 1500) {
        startPlayerView = startPlayerView - 1500;
      }
      handleVideoScroll(startPlayerView);
    },
  };
  return (
    <>
      <Table
        rowSelection={{ type: 'radio', ...rowSelection }}
        pagination={false}
        className="eventTable"
        columns={columns}
        dataSource={data}
        size="small"
        scroll={{ x: 1500, y: 300 }}
      />
    </>
  );
};
