/* eslint-disable sonarjs/cognitive-complexity */
import { ActiveEventType, EventAttributeType, TrackType } from 'types';
/* eslint-disable sonarjs/no-duplicate-string */
export function uuidv4() {
  //@ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (
      c ^
      // eslint-disable-next-line no-undef
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}
export function formattedTime(currentTime: any) {
  const date = new Date(0);
  date.setSeconds(currentTime);
  const ms = Math.round((currentTime % 1) * 1000);
  date.setMilliseconds(currentTime);
  return `${date.toISOString().substr(11, 8)}.${ms.toString().padStart(3, '0')}`;
}
export function formattedTimeOnlyMinutes(currentTime: any) {
  return Math.round(currentTime / 60);
}

export function filterAndIndex(
  array: Array<any>,
  attrValue: string | number | boolean,
  attrName: string,
) {
  let element;
  let index = -1;
  for (let i = 0; i < array.length; i++) {
    if (array[i][attrName] === attrValue) {
      element = array[i];
      index = i;
      break;
    }
  }
  return { element, index };
}

export function findRepeatingValues(ArrObj: any): string[] {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  return ArrObj.filter((obj: any) => {
    if (
      obj.key == 'null' ||
      obj.key == null ||
      obj.key == '' ||
      obj.key == undefined
    ) {
      return false;
    }
    return (
      ArrObj.filter((searchObj: any) => searchObj.key == obj.key).length > 1
    );
  });
}

export function simulateMouseClick(element: any) {
  ['mousedown', 'click', 'mouseup'].forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    ),
  );
}

export function getGrouppedKeys(hotkeysForm: any) {
  const unique: any = [];
  const existing: string[] = [];
  for (const value of hotkeysForm || []) {
    if (!existing.includes(value.label)) {
      existing.push(value.label);
      unique.push(value);
    }
  }
  return unique.reduce(
    (acc: any, obj: any) => ({
      ...acc,
      [obj.block_id]: [...(acc[obj.block_id] || []), obj],
    }),
    {},
  );
}
export function getUniqueKeys(hotkeysForm: any) {
  const unique: any = [];
  const existing: string[] = [];
  for (const value of hotkeysForm || []) {
    if (!existing.includes(value.label)) {
      existing.push(value.label);
      unique.push(value);
    }
  }
  return unique;
}

export function validateEvent(event: any, task: any) {
  const { track = {} } = event;
  const { fast_event = '' } = track;
  const { task_fast_events = [] } = task;
  const fEventsFromTask = task_fast_events.filter(
    (fe: any) => fe.id == fast_event,
  );
  if (fEventsFromTask.length == 0) {
    // сохраняется не fast event
    return true;
  }
  const fEvent = fEventsFromTask[0];
  if (fEvent.has_coords && !track.coord_x && !track.coord_y) {
    console.error('event validation failed:', 'no coords');
    return false;
  }
  if (fEvent?.participants) {
    if (fEvent?.participants == 1) {
      if (track.attributes && track.attributes.length < 2) {
        console.error('event validation failed:', 'no all attributes');
        return false;
      }
      const isValid =
        track.attributes.filter(
          (atr: any) => atr.key == 'player' || atr.key == 'team',
        ).length == 2;
      if (!isValid) {
        console.error('event validation failed:', 'no player or team');
      }
      return isValid;
    }
    if (fEvent?.participants == 2) {
      const correction = fEvent.player_skip ? 2 : 3;
      if (track.attributes && track.attributes.length < correction) {
        console.error('event validation failed', 'no all attributes');
        return false;
      }
      const isValid =
        track.attributes.filter(
          (atr: any) =>
            atr.key == 'player' ||
            atr.key == 'team' ||
            atr.key == 'player_in' ||
            atr.key == 'player_out' ||
            atr.key == 'player_home' ||
            atr.key == 'player_away' ||
            atr.key == 'player_opposite',
        ).length >= correction;
      if (!isValid) {
        console.error('event validation failed', 'no player or team');
      }
      return isValid;
    }
  }

  return true;
}

export const checkNewPlayerNumberIsUnique = (
  value: number,
  teamType: string,
  teams_lineup: any[],
) => {
  const teamLineup = teams_lineup.filter(
    (evt: any) => evt.team === teamType.toLowerCase(),
  )[0];
  // console.log(attribute);
  if (!!teamLineup && !!teamLineup.players) {
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const val of teamLineup.players) {
      if (Number(val) === Number(value)) {
        return false;
      }
    }
  }
  return true;
};

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //@ts-expect-error
  return `rgb(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)})`;
}

export function findLastModifiedPlayerNumber(
  subtitutions: any,
  basePlayerNumber: number,
) {
  if (!basePlayerNumber) {
    return basePlayerNumber;
  }
  let transitionValue = basePlayerNumber;
  subtitutions.forEach((sub: any) => {
    if (sub.out == transitionValue && sub.in) {
      transitionValue = sub.in;
    }
    if (sub.out == transitionValue && sub.in == undefined) {
      transitionValue = sub.out;
    }
  });
  return transitionValue;
}

export function getCurrentEditedEvent(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  editedEvents: any[] = [],
  currentFastEvent: any,
) {
  if (editedEvents.length <= 0) {
    return undefined;
  }
  const [currentEditedEvent = undefined] = editedEvents.filter(
    (evt: any) =>
      evt.id === currentFastEvent?.event_id ||
      evt.id === currentFastEvent?.eventId,
  );
  if (currentEditedEvent) {
    return currentEditedEvent;
  }
  if (
    currentFastEvent?.event_name?.toLowerCase() == 'recovery' ||
    currentFastEvent?.event_name?.toLowerCase() == 'receipt'
  ) {
    const [currentEditedEventByName = {}] = editedEvents.filter(
      (evt: any) =>
        evt.name.toLowerCase() == 'recovery' ||
        evt.name.toLowerCase() == 'receipt',
    );
    if (currentEditedEventByName.id) {
      return currentEditedEventByName;
    }
  }
  return undefined;
}

export const getScore = (activeEvents: ActiveEventType[]) => {
  const goals = activeEvents.filter(
    (evt: ActiveEventType) => evt.name.toLowerCase() === 'Shot'.toLowerCase(),
  )[0];
  const own_goals = activeEvents.filter(
    (evt: ActiveEventType) =>
      evt.name.toLowerCase() === 'OwnGoal'.toLowerCase(),
  )[0];
  if (!goals && !own_goals) {
    return '0:0';
  }
  const home_goals =
    (goals?.tracks.filter(
      (trk: TrackType) =>
        trk.attributes.filter(
          (attr: EventAttributeType) =>
            attr.key === 'result' && attr.values[0].value === 'Goal',
        ).length &&
        trk.attributes.filter(
          (attr: EventAttributeType) => attr.key === 'team',
        )[0]?.values[0]?.value === 'Home',
    )?.length || 0) +
    (own_goals?.tracks.filter(
      (trk: TrackType) =>
        trk.attributes.filter(
          (attr: EventAttributeType) => attr.key === 'team',
        )[0]?.values[0]?.value === 'Away',
    )?.length || 0);
  const away_goals =
    (goals?.tracks.filter(
      (trk: TrackType) =>
        trk.attributes.filter(
          (attr: EventAttributeType) =>
            attr.key === 'result' && attr.values[0].value === 'Goal',
        ).length &&
        trk.attributes.filter(
          (attr: EventAttributeType) => attr.key === 'team',
        )[0]?.values[0]?.value === 'Away',
    )?.length || 0) +
    (own_goals?.tracks.filter(
      (trk: TrackType) =>
        trk.attributes.filter(
          (attr: EventAttributeType) => attr.key === 'team',
        )[0]?.values[0]?.value === 'Home',
    )?.length || 0);
  return `${home_goals}:${away_goals}`;
};
