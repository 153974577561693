import React, { FC } from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';

import EventsList from './EventsList';
import EventsOptionsList from './EventsOptionsList';

const OperatorEditorEvents: FC<any> = () => {
  const { hotkeysIsDisplayed } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  return (
    <Row gutter={8}>
      <Col span={6}>
        <EventsOptionsList showHotKey={hotkeysIsDisplayed} />
      </Col>
      <Col span={18}>
        <EventsList showHotKey={hotkeysIsDisplayed} />
      </Col>
    </Row>
  );
};

export default OperatorEditorEvents;
