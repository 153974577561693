/* eslint-disable indent */
import React, { FC, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import { ActiveEventType, NewSegmentType } from 'types';

import { NewSegment } from './NewSegment';
import { Segment } from './Segment';
import './newSegments.css';

interface NewSegmentContainerInterface {
  event: ActiveEventType;
  videoLength: number;
  setSelectedEventId: any;
  handleVideoScroll: (ms: number) => void;
  zoomValue: any;
  currentPosition: number;
}

const makeSegment = (
  id: number | null,
  start: number,
  stop: number,
  isRecorded: boolean,
  hasLeftHandle: boolean,
  hasRightHandle: boolean,
  prev: number,
  next: number,
  name: string,
  eventId: number,
): NewSegmentType => {
  return {
    id: id,
    eventId,
    event_id: eventId,
    start: start,
    stop: stop,
    isRecorded: isRecorded,
    isZeroLength: start === stop,
    hasLeftHandle: hasLeftHandle,
    hasRightHandle: hasRightHandle,
    prev: prev,
    next: next,
    name,
  };
};

export const SegmentsContainer: FC<NewSegmentContainerInterface> = ({
  event,
  videoLength,
  setSelectedEventId,
  handleVideoScroll,
  zoomValue,
  currentPosition,
}) => {
  const activeEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.activeEvents,
  );
  const activeTask = useSelector(
    (state: AppStateType) => state.taskReducer.activeTask,
  );
  const [segments, setSegments] = useState<Array<NewSegmentType>>([]);

  const prepareSegments = (
    activeEvt: ActiveEventType,
  ): Array<NewSegmentType> => {
    let tracks: any[] = [];
    if (activeEvt && activeEvt.tracks) {
      tracks = [...activeEvt.tracks];
    }
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const segments: Array<NewSegmentType> = [];
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      const hasPrev = i > 0;
      const hasNext = i < tracks.length - 1;
      const newSegment = makeSegment(
        track.id,
        // @ts-ignore
        track.start,
        track.stop,
        track.isRecording,
        event.has_duration || true,
        event.has_duration || true,
        hasPrev ? tracks[i - 1].stop : 0,
        hasNext ? tracks[i + 1].start : videoLength,
        activeEvt.name,
        event.id,
      );
      segments.push({ ...newSegment });
    }
    return segments;
  };
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-use-before-define
    const activeEvt = activeEvents.filter(
      (activeEvent: ActiveEventType) => activeEvent.id === event.id,
    )[0];
    const ueSegments = prepareSegments(activeEvt);
    setSegments(ueSegments);
  }, [activeEvents]);
  const ref = useRef<any>(null);

  const editedEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.editedEvents,
  );
  // console.log('rerender segment container');
  const parentWidth = `${100 * (1 + zoomValue / 100)}%`;
  return (
    <div
      ref={ref}
      className="segments-container"
      style={{
        width: parentWidth,
      }}
    >
      {segments.map((segment: NewSegmentType) => {
        if (segment.id == editedEvents[0]?.track.id) {
          return (
            <NewSegment
              event={event}
              key={segment.id}
              parentWidth={ref.current.offsetWidth}
              videoLength={videoLength}
              segment={segment}
              allSegments={segments}
              setSelectedEventId={setSelectedEventId}
              handleVideoScroll={handleVideoScroll}
              currentPosition={currentPosition}
            />
          );
        } else {
          return (
            <Segment
              key={segment.id}
              activeEvents={activeEvents}
              activeTask={activeTask}
              parentWidth={ref.current.offsetWidth}
              segment={segment}
              handleVideoScroll={handleVideoScroll}
            />
          );
        }
      })}
    </div>
  );
};
