import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

import { createCommonHeader } from './_functions';

export async function getPlacementSchemas(sportId: string) {
  const url = `${endpoint}/api/v1/placement_schemas/`;
  const headers = createCommonHeader();
  const params = new URLSearchParams();
  params.append('sport', sportId);
  try {
    const response = await myAxios.GET(url, params, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function savePlacementAPI(
  placementId: string,
  teamId: any,
  activityId: number | null | string,
  startTime: number | null,
  endTime: number | null,
  positions?: any[] | null,
) {
  const url = `${endpoint}/api/v1/placements/${activityId}/`;
  const headers = createCommonHeader();
  const payload = {
    schema_id: placementId,
    team_id: teamId,
    started_at: startTime,
    finished_at: endTime,
    positions: positions,
  };
  try {
    const response = await myAxios.POST(url, payload, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function getActivePlacementsAPI(activityId: number | null) {
  const url = `${endpoint}/api/v1/placements/${activityId}/`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.GET(url, null, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function updatePlacementAPI(
  placementId: string,
  teamId: number,
  positions?: any[],
  finishedAt?: number,
) {
  const url = `${endpoint}/api/v1/placements/${placementId}/update/${teamId}/`;
  const headers = createCommonHeader();
  const payload = {
    ...(positions && { positions }),
    ...(finishedAt && { finished_at: finishedAt }),
  };
  const response = await myAxios.PATCH(url, payload, headers);
  return response.data;
}

export async function deletePlacementAPI(
  activityId: number | null,
  placementId: string,
) {
  const url = `${endpoint}/api/v1/placements/${activityId}/?placement_id=${placementId}`;
  const headers = createCommonHeader();
  const response = await myAxios.DELETE(url, null, headers);
  return response.data;
}
