import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

import { createCommonHeader } from './_functions';

export async function getActiveTask() {
  const taskId = new URLSearchParams(window.location.search).get('taskId');
  let url = `${endpoint}/api/v2/task/active_task/`;
  if (taskId) {
    url = `${url}?taskId=${taskId}`;
  }
  const headers = createCommonHeader();
  try {
    const response = await myAxios.GET(url, null, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function updateTaskStatus(taskId: number, status: number) {
  const url = `${endpoint}/api/v1/task/${taskId}/status/`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.POST(url, { status: status }, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}
