import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

import { createCommonHeader } from './_functions';

export async function getGamePlayers(taskId: string | number) {
  if (!taskId) {
    throw new Error('no task id');
  }
  const url = `${endpoint}/api/v2/task/active_task/${taskId}/lineups/`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.GET(url, null, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function addToMainGamePlayers(
  taskId: string | number,
  teamId: any,
  playerId: string,
) {
  const url = `${endpoint}/api/v2/task/active_task/${taskId}/lineups/${teamId}/game_lineup/ `;
  const headers = createCommonHeader();
  const payload = {
    player_id: playerId,
  };
  try {
    const response = await myAxios.POST(url, payload, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function removeFromMainGamePlayers(
  taskId: string | number,
  teamId: number | null,
  playerId: string,
) {
  const url = `${endpoint}/api/v2/task/active_task/${taskId}/lineups/${teamId}/game_lineup/${playerId}/ `;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.DELETE(url, {}, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}
