import { ThunkAction } from 'redux-thunk';

import * as API from 'api';
import { SetPossessionsTeamAndEventFromTask } from 'store/actions/possession.actions';
import { AppStateType } from 'store/reducers';
import { taskReducer } from 'store/reducers/task.reducer';

const {
  getActiveTaskRequestAction,
  getActiveTaskSuccessAction,
  getActiveTaskErrorAction,
  startTaskRequestAction,
  startTaskSuccessAction,
  startTastErrorAction,
  videoViewErrorAction,
} = taskReducer.actions;

// USED
export const GetActiveTaskAction = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => {
  return async (dispatch) => {
    try {
      dispatch(getActiveTaskRequestAction());
      const responseV2 = await API.getActiveTask();
      await dispatch(getActiveTaskSuccessAction(responseV2));
      dispatch(
        SetPossessionsTeamAndEventFromTask(
          responseV2.task_fast_events,
          responseV2.home_team_name,
          responseV2.away_team_name,
        ),
      );
      return Promise.resolve(responseV2);
    } catch (err: any) {
      dispatch(getActiveTaskErrorAction(err));
    }
  };
};
export const UpdateTaskStatusAction = (
  taskId: number,
  status: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      dispatch(startTaskRequestAction());
      const response = await API.updateTaskStatus(taskId, status);
      dispatch(startTaskSuccessAction(response));
      dispatch(GetActiveTaskAction());
    } catch (err: any) {
      dispatch(startTastErrorAction(err));
    }
  };
};
export const VideoErrorAction = (
  error: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    dispatch(videoViewErrorAction(error));
  };
};
