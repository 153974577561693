import React, { FC, useEffect, useState } from 'react';

import { Button, ConfigProvider, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { SetPlayerActionToEdit } from 'store/actions/recorder.actions';
import { AppStateType } from 'store/reducers';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import {
  findLastModifiedPlayerNumber,
  getCurrentEditedEvent,
} from 'utils/functions';

import { SelectFieldItem } from './SelectFieldItem';
import { PlayersFieldSelect } from '../PlayersFieldSelect';

import './style.css';

export const SelectField: FC<any> = ({
  playerSize = 14,
  playerOpacity = 1,
  popoverDisable = false,
}) => {
  const dispatch = useDispatch();
  const {
    playerSettings: { color_settings = {} },
    invertedTeamDisplay,
  } = useSelector((state: AppStateType) => state.playerReducer);

  const { currentFastEvent } = useSelector(
    (state: AppStateType) => state.fastEventReducer,
  );

  const { editedEvents, playerIsSkipped, activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );
  const { homeActivePlacement, awayActivePlacement } = useSelector(
    (state: AppStateType) => state.placementReducer,
  );

  const [isHomeDisabled, setIsHomeDisabled] = useState<boolean>(false);
  const [isAwayDisabled, setIsAwayDisabled] = useState<boolean>(false);
  const [isHomeDeactivated, setIsHomeDeactivated] = useState<boolean>(false);
  const [isAwayDeactivated, setIsAwayDeactivated] = useState<boolean>(false);
  const [showSkipButton, setShowSkipButton] = useState(false);
  const [selectedHomePlayers, setSelectedHomePlayers] = useState<number[]>([]);
  const [selectedAwayPlayers, setSelectedAwayPlayers] = useState<number[]>([]);
  const [homeSubtitutions, setHomeSubtitutions] = useState([]);
  const [awaySubtitutions, setAwaySubtitutions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isSecondSubtitutionSelectActive, setIsSecondSubtitutionSelectOpen] =
    useState(false);
  const [isSubstitutionSecondSelect, setIsSubstitutionSecondSelect] =
    useState(false);

  const { setPlayerSkipped } = recorderReducerNew.actions;
  const selectedHomeColor = color_settings?.Home;
  const { HomeInvertText = false, AwayInvertText = false } = color_settings;
  const selectedAwayColor = color_settings?.Away;

  useEffect(() => {
    if (!currentFastEvent) {
      setIsHomeDeactivated(true);
      setIsAwayDeactivated(true);
      return;
    }
    const {
      player_skip,
      key,
      same_team = false,
      participants = 0,
    } = currentFastEvent;
    const currentEditedEvent = getCurrentEditedEvent(
      editedEvents,
      currentFastEvent,
    );
    if (!currentEditedEvent) {
      setSelectedHomePlayers([]);
      setSelectedAwayPlayers([]);
      return;
    }
    const isDuel = key === 'duel';

    setIsHomeDisabled(
      editedEvents.length === 0 || currentFastEvent?.participants === 0,
    );
    setIsAwayDisabled(
      editedEvents.length === 0 || currentFastEvent?.participants === 0,
    );
    if (
      !(
        currentFastEvent &&
        currentFastEvent?.participants > 0 &&
        editedEvents.length
      )
    ) {
      setShowSkipButton(false);
      setSelectedHomePlayers([]);
      setSelectedAwayPlayers([]);
      return;
    }

    //------------------------- FOR participants == 1 -----------------------------
    if (participants == 1) {
      const playerValues = currentEditedEvent.track.attributes.filter(
        (att: any) =>
          att.key === 'player' ||
          att.name.toLowerCase() === 'Player Home'.toLowerCase() ||
          att.name.toLowerCase() === 'Player Away'.toLowerCase(),
      );
      if (!playerValues.length) {
        setSelectedHomePlayers([]);
        setSelectedAwayPlayers([]);
        return;
      }
      const teamValue = currentEditedEvent?.track?.attributes?.filter(
        (att: any) => att.name === 'Team',
      )[0];
      for (const playerValue of playerValues) {
        if (playerValue?.key === 'player' && teamValue) {
          if (
            teamValue.values[0].value.toString().toLowerCase() ===
              'Home'.toLowerCase() ||
            playerValue.name?.toLowerCase() === 'Player Home'.toLowerCase()
          ) {
            setSelectedHomePlayers([Number(playerValue.values[0].value)]);
            setSelectedAwayPlayers([]);
          }
          if (
            teamValue.values[0].value.toString().toLowerCase() ===
              'Away'.toLowerCase() ||
            playerValue.name?.toLowerCase() === 'Player Away'.toLowerCase()
          ) {
            setSelectedAwayPlayers([Number(playerValue.values[0].value)]);
            setSelectedHomePlayers([]);
          }
        }
      }
    }
    //------------------------- FOR participants == 2 -----------------------------
    if (participants == 2) {
      if (isDuel) {
        const playerHomeValues = currentEditedEvent?.track.attributes.filter(
          (att: any) => att.name?.toLowerCase() === 'player home'.toLowerCase(),
        );
        const playerAwayValues = currentEditedEvent?.track.attributes.filter(
          (att: any) => att.name?.toLowerCase() === 'player away'.toLowerCase(),
        );
        if (!playerHomeValues || !playerHomeValues.length) {
          setSelectedHomePlayers([]);
        }
        if (!playerAwayValues || !playerAwayValues.length) {
          setSelectedAwayPlayers([]);
        }
        for (const playerValue of playerHomeValues) {
          setSelectedHomePlayers([Number(playerValue.values[0].value)]);
        }
        for (const playerValue of playerAwayValues) {
          setSelectedAwayPlayers([Number(playerValue.values[0].value)]);
        }
      }
      if (player_skip || (!same_team && !isDuel && !player_skip)) {
        const teamValue = currentEditedEvent?.track?.attributes?.filter(
          (att: any) => att.name === 'Team',
        )[0];
        if (player_skip) {
          const opposite = currentEditedEvent?.track?.attributes?.filter(
            (att: any) => att.key === 'player_opposite',
          )[0];
          if (opposite) {
            setShowSkipButton(false);
          } else {
            setShowSkipButton(true);
          }
        } else {
          setShowSkipButton(false);
        }
        const playerValues = currentEditedEvent.track.attributes.filter(
          (att: any) => att.key === 'player',
        );
        const playerOppositeValues =
          currentEditedEvent?.track?.attributes?.filter(
            (att: any) => att.key === 'player_opposite',
          );
        if (
          teamValue &&
          teamValue.values[0].value.toString().toLowerCase() === 'home'
        ) {
          if (!playerValues.length) {
            setSelectedHomePlayers([]);
          }
          if (!playerOppositeValues.length) {
            setSelectedAwayPlayers([]);
          }
          for (const playerValue of playerValues) {
            setSelectedHomePlayers([Number(playerValue.values[0].value)]);
          }
          for (const playerOppositeValue of playerOppositeValues) {
            setSelectedAwayPlayers([
              Number(playerOppositeValue.values[0].value),
            ]);
          }
        }
        if (
          teamValue &&
          teamValue.values[0].value.toString().toLowerCase() === 'away'
        ) {
          if (!playerValues.length) {
            setSelectedAwayPlayers([]);
          }
          if (!playerOppositeValues.length) {
            setSelectedHomePlayers([]);
          }
          for (const playerValue of playerValues) {
            setSelectedAwayPlayers([Number(playerValue.values[0].value)]);
          }
          for (const playerOppositeValue of playerOppositeValues) {
            setSelectedHomePlayers([
              Number(playerOppositeValue.values[0].value),
            ]);
          }
        }
      }
      if (same_team) {
        const teamValue = currentEditedEvent?.track?.attributes?.filter(
          (att: any) => att.key === 'team',
        )[0];
        const playerInValues = currentEditedEvent?.track?.attributes?.filter(
          (att: any) => att.key === 'player_in',
        );
        const playerOutValues = currentEditedEvent?.track?.attributes?.filter(
          (att: any) => att.key === 'player_out',
        );
        let allSelected: number[] = [];
        if (playerInValues.length) {
          allSelected = [...[Number(playerInValues[0].values[0].value)]];
        }
        if (playerOutValues.length) {
          allSelected = [
            ...allSelected,
            ...[Number(playerOutValues[0].values[0].value)],
          ];
        }
        if (teamValue) {
          if (teamValue.values[0]?.value.toString().toLowerCase() === 'home') {
            setSelectedHomePlayers(allSelected);
            setSelectedAwayPlayers([]);
          }
          if (teamValue.values[0]?.value.toString().toLowerCase() === 'away') {
            setSelectedAwayPlayers(allSelected);
            setSelectedHomePlayers([]);
          }
        }
      }
    }
    if (
      currentFastEvent.key == 'substitution' &&
      editedEvents &&
      editedEvents.length > 0 &&
      editedEvents[0].track.attributes.some((atr) => atr.key == 'player_out') &&
      !editedEvents[0].track.attributes.some((atr) => atr.key == 'player_in')
    ) {
      setIsSubstitutionSecondSelect(true);
    } else {
      setIsSubstitutionSecondSelect(false);
    }
  }, [editedEvents, currentFastEvent]);

  useEffect(() => {
    const {
      // player_skip,
      // key,
      same_team = false,
      participants = 0,
    } = currentFastEvent;
    // const isDuel = key === 'duel';
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (participants == 1) {
      if (selectedHomePlayers.length + selectedAwayPlayers.length == 1) {
        setIsHomeDeactivated(true);
        setIsAwayDeactivated(true);
      }
    }
    if (participants == 2) {
      if (!same_team) {
        if (
          selectedHomePlayers.length == 0 &&
          selectedAwayPlayers.length == 0
        ) {
          setIsAwayDisabled(false);
          setIsHomeDisabled(false);
          setIsHomeDeactivated(false);
          setIsAwayDeactivated(false);
        }
        if (selectedHomePlayers.length == 1) {
          setIsHomeDisabled(true);
          setIsHomeDeactivated(true);
        }
        if (selectedAwayPlayers.length == 1) {
          setIsAwayDisabled(true);
          setIsAwayDeactivated(true);
        }
      } else {
        setIsAwayDisabled(false);
        setIsHomeDisabled(false);
        setIsHomeDeactivated(false);
        setIsAwayDeactivated(false);
      }
    }
  }, [selectedAwayPlayers, selectedHomePlayers]);

  const toggleSkipButton = async () => {
    await dispatch(setPlayerSkipped({ isSkipped: !playerIsSkipped }));
  };
  const selectPlayer = (
    team: any,
    playerNumber: any,
    isFirstPlayer: boolean,
  ) => {
    dispatch(
      SetPlayerActionToEdit(playerNumber, team.name, playedSec, isFirstPlayer),
    );
  };

  useEffect(() => {
    const [Substitution = {}] = activeEvents.filter(
      (ae) => ae.name.toLowerCase() === 'substitution',
    );
    //@ts-expect-error
    const { tracks = [] } = Substitution;
    if (tracks.length > 0) {
      const actualTracks = tracks
        .filter((tr: any) => tr.stop <= playedSec)
        .sort((a: any, b: any) => a.stop - b.stop);
      const homeSubtitution: any = [];
      const awaySubtitution: any = [];
      actualTracks.forEach((track: any) => {
        let team;
        let playerIn;
        let playerOut;
        track.attributes.forEach((attr: any) => {
          if (attr.key == 'team') {
            team = attr.values[0]?.value?.toLowerCase();
          }
          if (attr.key == 'player_in') {
            playerIn = attr.values[0]?.value;
          }
          if (attr.key == 'player_out') {
            playerOut = attr.values[0]?.value;
          }
        });
        const subtitution = {
          in: playerIn,
          out: playerOut,
          start: track.start,
          stop: track.stop,
        };
        if (team == 'home') {
          homeSubtitution.push(subtitution);
          return;
        }
        if (team == 'away') {
          awaySubtitution.push(subtitution);
        }
      });
      setHomeSubtitutions(homeSubtitution);
      setAwaySubtitutions(awaySubtitution);
    }
  }, [activeEvents, playedSec]);

  const calcSubstitutionSecondSelect = (
    playerNumber: any,
    selectedPlayers: any,
    subtitutions: any,
  ) => {
    return (
      (selectedPlayers.length > 0 &&
        selectedPlayers.includes(playerNumber) &&
        isSubstitutionSecondSelect) ||
      (selectedPlayers.length > 0 &&
        selectedPlayers.includes(
          findLastModifiedPlayerNumber(subtitutions, playerNumber) * 1,
        ) &&
        isSubstitutionSecondSelect)
    );
  };

  const togglePlayerOtherButton = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: '#E6F4FF',
        },
      }}
    >
      <div className="playersField" style={{ fontSize: `${playerSize}px` }}>
        <div className="teamContainer">
          {homeActivePlacement.positions &&
            homeActivePlacement.positions.map((pos: any) => (
              <SelectFieldItem
                key={pos.id}
                popoverDisable={popoverDisable}
                isActive={
                  selectedHomePlayers.length > 0 &&
                  selectedHomePlayers.includes(pos.player_number)
                }
                activePlayerPos={[pos]}
                replacedPlayerNumber={findLastModifiedPlayerNumber(
                  homeSubtitutions,
                  pos.player_number,
                )}
                isSubstitutionSecondSelect={calcSubstitutionSecondSelect(
                  pos.player_number,
                  selectedHomePlayers,
                  homeSubtitutions,
                )}
                selectPlayer={selectPlayer}
                team={{ name: 'Home', color: selectedHomeColor }}
                invertedText={HomeInvertText}
                color={selectedHomeColor}
                style={{
                  top: invertedTeamDisplay
                    ? 'initial'
                    : `${Math.floor(pos.coordY * 100)}%`,
                  bottom: invertedTeamDisplay
                    ? `${Math.floor(pos.coordY * 100)}%`
                    : 'initial',
                  left: invertedTeamDisplay
                    ? 'initial'
                    : `${Math.floor(pos.coordX * 100)}%`,
                  right: invertedTeamDisplay
                    ? `${Math.floor(pos.coordX * 100)}%`
                    : 'initial',
                  backgroundColor: 'rgba(0, 0, 0, 0.165)',
                  opacity: playerOpacity || 1,
                  marginLeft: !invertedTeamDisplay ? '-1.1em' : 'initial',
                  marginRight: invertedTeamDisplay ? '-1.1em' : 'initial',
                  marginTop: !invertedTeamDisplay ? '-1.1em' : 'initial',
                  marginBottom: invertedTeamDisplay ? '-1.1em' : 'initial',
                }}
                disabled={isHomeDisabled || isSecondSubtitutionSelectActive}
                deactivated={
                  isHomeDeactivated || isSecondSubtitutionSelectActive
                }
                playersOnField={homeActivePlacement}
                setIsSecondSubtitutionSelectOpen={
                  setIsSecondSubtitutionSelectOpen
                }
              />
            ))}
          {awayActivePlacement.positions &&
            awayActivePlacement.positions.map((pos: any) => (
              <SelectFieldItem
                key={pos.id}
                popoverDisable={popoverDisable}
                isActive={
                  selectedAwayPlayers.length > 0 &&
                  selectedAwayPlayers.includes(pos.player_number)
                }
                activePlayerPos={[pos]}
                replacedPlayerNumber={findLastModifiedPlayerNumber(
                  awaySubtitutions,
                  pos.player_number,
                )}
                isSubstitutionSecondSelect={calcSubstitutionSecondSelect(
                  pos.player_number,
                  selectedAwayPlayers,
                  awaySubtitutions,
                )}
                selectPlayer={selectPlayer}
                team={{ name: 'Away', color: selectedAwayColor }}
                invertedText={AwayInvertText}
                color={selectedAwayColor}
                style={{
                  top: invertedTeamDisplay
                    ? `${Math.floor(pos.coordY * 100)}%`
                    : 'initial',
                  bottom: invertedTeamDisplay
                    ? 'initial'
                    : `${Math.floor(pos.coordY * 100)}%`,
                  right: invertedTeamDisplay
                    ? 'initial'
                    : `${Math.floor(pos.coordX * 100)}%`,
                  left: invertedTeamDisplay
                    ? `${Math.floor(pos.coordX * 100)}%`
                    : 'initial',
                  backgroundColor: 'rgba(255, 255, 255, 0.165)',
                  opacity: playerOpacity || 1,
                  marginLeft: invertedTeamDisplay ? '-1.1em' : 'initial',
                  marginRight: !invertedTeamDisplay ? '-1.1em' : 'initial',
                  marginTop: '-1.1em',
                  marginBottom: invertedTeamDisplay ? 'initial' : '-1.1em',
                }}
                disabled={isAwayDisabled || isSecondSubtitutionSelectActive}
                deactivated={
                  isAwayDeactivated || isSecondSubtitutionSelectActive
                }
                playersOnField={awayActivePlacement}
                setIsSecondSubtitutionSelectOpen={
                  setIsSecondSubtitutionSelectOpen
                }
              />
            ))}
        </div>
      </div>
      <Popover
        content={
          <div>
            <h3>Home</h3>
            <PlayersFieldSelect
              close={() => {
                setOpen(false);
              }}
              team={{ name: 'Home', color: selectedHomeColor }}
              invertedText={HomeInvertText}
              selectPlayer={selectPlayer}
            />
            <h3>Away</h3>
            <PlayersFieldSelect
              close={() => {
                setOpen(false);
              }}
              team={{ name: 'away', color: selectedAwayColor }}
              invertedText={AwayInvertText}
              selectPlayer={selectPlayer}
            />
          </div>
        }
        trigger="click"
        open={open}
        onOpenChange={togglePlayerOtherButton}
        placement="bottom"
      >
        <Button className="playerOtherSelectButton" type="text" shape="round">
          Manual
        </Button>
      </Popover>

      {showSkipButton && (
        <Button
          className={
            playerIsSkipped ? 'playerSkipButton skipped' : 'playerSkipButton'
          }
          type="text"
          shape="round"
          onClick={toggleSkipButton}
        >
          Skip
        </Button>
      )}
    </ConfigProvider>
  );
};
