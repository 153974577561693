/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';

import { Flex, Radio, RadioChangeEvent } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { HARDCODED_COLOR } from 'constants/constants';
import { SetColorSettingsAction } from 'store/actions/player.actions';
import { AppStateType } from 'store/reducers';
import './colorSelectList.css';

const ColorSelectList: FC<any> = ({ team }) => {
  const dispatch = useDispatch();
  const handleColorChange = (e: RadioChangeEvent) => {
    dispatch(SetColorSettingsAction({ key: team, value: e.target.value }));
  };
  const {
    playerSettings: { color_settings = {} },
  } = useSelector((state: AppStateType) => state.playerReducer);
  const selectedColor =
    color_settings != null && team in color_settings
      ? color_settings[team]
      : HARDCODED_COLOR[0];
  return (
    <Flex className="ColorSelectListContainer">
      <Radio.Group
        onChange={handleColorChange}
        buttonStyle={'solid'}
        value={selectedColor}
      >
        {HARDCODED_COLOR.map((color, i) => (
          <Radio.Button
            className="colorSelectListItem"
            key={i}
            value={color}
            style={{ backgroundColor: color }}
          >
            {/* <div className="bgCircle" style={{ backgroundColor: color }} /> */}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Flex>
  );
};

export default ColorSelectList;
