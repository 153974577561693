import React, { FC } from 'react';

import { Route, Routes } from 'react-router-dom';

import OperatorEditor from 'pages/OperatorEditor';

import { endpoint } from './constants/constants';
import AdminAuthPage from './pages/Admin/AdminAuth';
import LoginWindowPage from './pages/LoginWindow';
import myAxios from './utils/axios';

const PrivateRoute = (props: any) => {
  const localtoken =
    typeof window !== 'undefined' &&
    window.localStorage &&
    localStorage.getItem('authtoken');
  if (!localtoken) {
    window.location.href = '/login';
  }
  return props.children;
};

const AdminAuthRoute = (props: any) => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const taskId = params.get('taskId');
  myAxios
    .POST(`${endpoint}/api/token/refresh/`, {
      refresh: token,
    })
    .then((resp) => {
      localStorage.setItem('authtoken', resp.data.access);
      localStorage.setItem('refreshToken', token || '');
      return (window.location.href = `/?taskId=${taskId}`);
    })
    .catch(() => {
      window.location.href = '/login';
    });

  return props.children;
};

const RoutesComponent: FC = () => (
  <Routes>
    <Route
      path={'/adminAuth'}
      element={
        <AdminAuthRoute>
          <AdminAuthPage />
        </AdminAuthRoute>
      }
    />
    <Route path={'/login'} element={<LoginWindowPage />} />
    <Route
      path={'/'}
      element={
        <PrivateRoute>
          {/* <OperatorWindowPage /> */}
          <OperatorEditor />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default RoutesComponent;
