import React, { FC } from 'react';

export const ZoomerSlider: FC<any> = ({ max, zoomValue, onChangeHandler }) => {
  return (
    <input
      type={'range'}
      min={0}
      max={max}
      step={0.5}
      value={zoomValue}
      onChange={(e) => onChangeHandler(e.target.value)}
      className={'zoomerBar'}
    />
  );
};
