import React, { useEffect, useMemo } from 'react';

import { notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';

import { messagesReducer } from '../../store/reducers/messages.reducer';

const Context = React.createContext({ name: 'Default' });

export const PassesNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);
  const { hideMessageAction } = messagesReducer.actions;
  const dispatch = useDispatch();
  const { messageText } = useSelector(
    (state: AppStateType) => state.messagesReducer,
  );
  const openNotification = (msgText: string) => {
    api.warning({
      message: 'Есть пасы без атрибута точности',
      description: <div dangerouslySetInnerHTML={{ __html: msgText }} />,
      onClose: () => {
        dispatch(hideMessageAction());
      },
      duration: 0,
    });
  };

  useEffect(() => {
    if (messageText) {
      openNotification(messageText);
    }
  }, [messageText]);
  return (
    <Context.Provider value={contextValue}>{contextHolder}</Context.Provider>
  );
};
