import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  isOpen: boolean;
};
const initialState: InitialStateType = {
  isOpen: false,
};
export const protocolReducer = createSlice({
  name: 'protocolReducer',
  initialState: initialState,
  reducers: {
    toggleGameProtocol(state, action) {
      state.isOpen = action.payload;
    },
  },
});
