import React from 'react';
import './App.css';

import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';

import Routes from './routes';

const THEME = {
  components: {
    Layout: {
      bodyBg: '#fff',
      footerBg: '#fff',
      headerBg: '#fff',
      siderBg: '#fff',
    },
  },
};

function App() {
  return (
    <ConfigProvider theme={THEME}>
      <div className="App">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
