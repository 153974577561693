import React, { FC, useState } from 'react';

import { PLAYBACK_RATES } from 'constants/constants';
import faBackwardIcon from 'resources/img/faBackwardStepIcon.svg';
import faForwardIcon from 'resources/img/faForwardStepIcon.svg';
import fiveSecondsBackIcon from 'resources/img/fiveSecondsBackIcon.svg';
import fiveSecondsForwardIcon from 'resources/img/fiveSecondsForwardIcon.svg';
import secondBackIcon from 'resources/img/secondBackIcon.svg';
import secondForwardIcon from 'resources/img/secondForwardIcon.svg';
import './style.css';

const PlaybackOption: FC<any> = ({
  playbackRate,
  rate,
  changePlaybackRate,
}) => {
  return (
    <div
      className={`playback-button ${playbackRate === rate ? 'bold' : ''}`}
      onClick={() => changePlaybackRate(rate)}
    >
      x{rate}
    </div>
  );
};
interface ScrollingButtonsInterface {
  currentPosition: number | undefined;
  handleVideoScroll: (e: any) => void;
  videoEnd: number | undefined;
  toggleVideoSpeed: (value: number) => void;
  playbackRate: number;
  frameRate: number;
}

export const ScrollingButtons: FC<ScrollingButtonsInterface> = ({
  currentPosition,
  handleVideoScroll,
  videoEnd,
  toggleVideoSpeed,
  playbackRate,
  frameRate,
}) => {
  const scrollXSeconds = (scrollValue: number) => {
    if (currentPosition != undefined) {
      if (scrollValue < 0) {
        if (currentPosition >= Math.abs(scrollValue)) {
          handleVideoScroll((currentPosition + scrollValue) * 1000);
        } else {
          // console.log('TO ZERO')
          handleVideoScroll(0);
        }
      } else {
        if (videoEnd && currentPosition + scrollValue <= videoEnd) {
          handleVideoScroll((currentPosition + scrollValue) * 1000);
        } else {
          handleVideoScroll(videoEnd);
        }
      }
    }
  };
  const [showPlaybackSpeedSelector, setShowPlaybackSpeedSelector] =
    useState(false);

  const toggleSpeedSelector = () => {
    setShowPlaybackSpeedSelector(!showPlaybackSpeedSelector);
  };
  const changePlaybackRate = (value: number) => {
    setShowPlaybackSpeedSelector(false);
    toggleVideoSpeed(value);
  };

  return (
    <div className="scrolling-buttons-wrapper">
      {showPlaybackSpeedSelector && (
        <div className={'playback-selector'}>
          <div className={'playback-container'}>
            {PLAYBACK_RATES.map((rate: number) => (
              <PlaybackOption
                rate={rate}
                playbackRate={playbackRate}
                changePlaybackRate={changePlaybackRate}
              />
            ))}
          </div>
        </div>
      )}
      <div
        style={{ width: 32, textAlign: 'center' }}
        className="playback-control"
        onClick={toggleSpeedSelector}
      >
        X{playbackRate}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={faBackwardIcon}
          onClick={() => scrollXSeconds(-1 / frameRate)}
          className={'videoControlButton'}
        />
        <img
          src={faForwardIcon}
          onClick={() => scrollXSeconds(1 / frameRate)}
          className={'videoControlButton'}
        />
      </div>
      <div>
        <img
          src={secondBackIcon}
          onClick={() => scrollXSeconds(-1)}
          className={'videoControlButton'}
        />
        <img
          src={secondForwardIcon}
          onClick={() => scrollXSeconds(1)}
          className={'videoControlButton'}
        />
      </div>
      <div>
        <img
          src={fiveSecondsBackIcon}
          onClick={() => scrollXSeconds(-5)}
          className={'videoControlButton'}
        />
        <img
          src={fiveSecondsForwardIcon}
          onClick={() => scrollXSeconds(5)}
          className={'videoControlButton'}
        />
      </div>
    </div>
  );
};
