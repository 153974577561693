import { createSlice } from '@reduxjs/toolkit';

const initialState: InitialStateType = {
  homePlayers: [],
  homeMainStaff: [],
  homeActivePlacement: {},
  awayPlayers: [],
  awayMainStaff: [],
  awayActivePlacement: {},
  placements: [],
};

type InitialStateType = {
  homePlayers: any[];
  homeMainStaff: any[];
  homeActivePlacement: any;
  awayPlayers: any[];
  awayMainStaff: any[];
  awayActivePlacement: any;
  placements: any[];
};

export const gamePlayersSlice = createSlice({
  name: 'gamePlayersSlice',
  initialState: initialState,
  reducers: {
    setHomePlayers(state, action) {
      state.homePlayers = action.payload;
    },
    setHomeMainStaff(state, action) {
      state.homeMainStaff = action.payload;
    },
    setAwayPlayers(state, action) {
      state.awayPlayers = action.payload;
    },
    setAwayMainStaff(state, action) {
      state.awayMainStaff = action.payload;
    },
    setPlacements(state, action) {
      state.placements = action.payload;
    },
    setActivePlacement(state, action) {
      if (action.payload.teamType == 'home') {
        state.homeActivePlacement = action.payload.placement;
      }
      if (action.payload.teamType == 'away') {
        state.awayActivePlacement = action.payload.placement;
      }
    },
  },
});
