import { createSlice } from '@reduxjs/toolkit';

import { Placement } from 'store/raw/types';
import { State } from 'types/app';

const initialState: InitialStateType = {
  homePlacements: [],
  homeActivePlacement: {} as Placement,
  awayPlacements: [],
  awayActivePlacement: {} as Placement,
  state: State.init,
  swapPlayer: { num: 0, team: '' },
};

type InitialStateType = {
  homePlacements: Placement[];
  homeActivePlacement: Placement;
  awayPlacements: Placement[];
  awayActivePlacement: Placement;
  state: State;
  swapPlayer: { num: number; team: string };
};

export const placementSlice = createSlice({
  name: 'placementSlice',
  initialState: initialState,
  reducers: {
    setHomePlacement(state, action) {
      state.homePlacements = action.payload;
    },
    setAwayPlacement(state, action) {
      state.awayPlacements = action.payload;
    },
    addPlacement(state, action) {
      if (action.payload.teamType == 'home') {
        state.homePlacements.push(action.payload.placement);
      }
      if (action.payload.teamType == 'away') {
        state.awayPlacements.push(action.payload.placement);
      }
    },
    setActivePlacement(state, action) {
      if (action.payload.teamType == 'home') {
        state.homeActivePlacement = action.payload.placement;
      }
      if (action.payload.teamType == 'away') {
        state.awayActivePlacement = action.payload.placement;
      }
    },
    setSwapPlayer(state, action) {
      state.swapPlayer = action.payload;
    },
  },
});

//export const isSwap = (state) => state.placementSlice.swapPlayer.team !== '';
