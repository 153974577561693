/* eslint-disable sonarjs/no-duplicate-string */
import React, { FC, useEffect, useState } from 'react';

import { Breadcrumb, ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { PlayerBreadcrumbTag } from 'components/ui';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { getCurrentEditedEvent } from 'utils/functions';

const { deletePlayerActionToEdit } = recorderReducerNew.actions;

const useMakeEditedEventController = ({
  editedEvents,
  currentFastEvent,
}: {
  editedEvents: any;
  currentFastEvent: any;
}) => {
  const [items, setItems] = useState<any[]>([]);
  const currentEditedEvent = getCurrentEditedEvent(
    editedEvents,
    currentFastEvent,
  );
  const dispatch = useDispatch();
  const { setShowCoordinatesModal } = fastEventReducer.actions;
  const {
    playerSettings: { color_settings = {} },
  } = useSelector((state: AppStateType) => state.playerReducer);
  const {
    same_team = false,
    participants = 0,
    player_skip = false,
    key = '',
  } = currentFastEvent;
  const isDuel = key == 'duel';
  const replacePlayer = (player: any) => {
    if (participants < 2 || !currentFastEvent) {
      return;
    }
    // console.log('TODO add remove or replace just one', player);
    // TODO add remove or replace just one
    dispatch(
      deletePlayerActionToEdit({
        eventId: currentFastEvent?.event_id || currentFastEvent?.eventId,
        player,
      }),
    );
  };
  const setFirstItem = (finalItems: any[], allowAddNext: boolean) => {
    if (currentFastEvent && Object.keys(currentFastEvent).length) {
      finalItems.push({ title: currentFastEvent.name });
    } else {
      finalItems.push({ title: 'Выберите действие' });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      allowAddNext = false;
    }
    return { finalItems, allowAddNext };
  };
  // eslint-disable-next-line
  const constructPlayerItems = (finalItems: any[], allowAddNext: boolean, playerIsSkipped: boolean) => {

    if (!currentEditedEvent || (currentEditedEvent && participants === 0)) {
      // нет игроков в списке требуемых атрибутов
      return { allowAddNext, finalItems };
    }
    if (participants === 1) {
      const playerValue = currentEditedEvent.track.attributes.filter(
        (attr: any) => attr.key === 'player',
      )[0];
      const teamValue = currentEditedEvent.track.attributes.filter(
        (attr: any) => attr.key === 'team',
      )[0];
      if (currentEditedEvent && playerValue) {
        const capitilise =
          teamValue?.values[0]?.value == 'away' ? 'Away' : 'Home';
        finalItems.push({
          title: (
            <PlayerBreadcrumbTag
              value={playerValue?.values[0]?.value}
              color={color_settings[capitilise]}
              invertedText={color_settings[`${capitilise}InvertText`]}
            />
          ),
        });
      } else {
        finalItems.push({ title: 'Выбери игрока' });
        allowAddNext = false;
      }
      return { allowAddNext, finalItems };
    }
    if (participants === 2) {
      if (isDuel) {
        const winnerTeam = currentEditedEvent.track.attributes.filter(
          (attr: any) => attr.key === 'team',
        )[0];
        const winnerTeamName = winnerTeam?.values[0]?.value;
        if (winnerTeamName) {
          const otherTeamValue =
            winnerTeamName.toLowerCase() === 'home' ? 'Away' : 'Home';
          const homePlayer = currentEditedEvent.track.attributes.filter(
            (attr: any) =>
              attr.name.toLowerCase() === 'Player Home'.toLowerCase(),
          )[0];
          const homePlayerNumber = homePlayer?.values[0]?.value;
          const awayPlayer = currentEditedEvent.track.attributes.filter(
            (attr: any) =>
              attr.name.toLowerCase() === 'Player Away'.toLowerCase(),
          )[0];
          const awayPlayerNumber = awayPlayer?.values[0]?.value;
          const winner_player =
            winnerTeamName.toLowerCase() === 'home'
              ? homePlayerNumber
              : awayPlayerNumber;
          const looser_player =
            winnerTeamName.toLowerCase() === 'home'
              ? awayPlayerNumber
              : homePlayerNumber;
          if (winner_player) {
            const capitilise = winnerTeamName == 'away' ? 'Away' : 'Home';
            finalItems.push({
              title: (
                <PlayerBreadcrumbTag
                  value={winner_player}
                  color={color_settings[capitilise]}
                  invertedText={color_settings[`${capitilise}InvertText`]}
                  clickCallback={() =>
                    replacePlayer(
                      winnerTeamName.toLowerCase() === 'home'
                        ? homePlayer
                        : awayPlayer,
                    )
                  }
                />
              ),
            });
          } else {
            finalItems.push({ title: 'Выбери игрока 1' });
            allowAddNext = false;
          }
          if (looser_player) {
            //@ts-expect-error
            const capitilise = otherTeamValue == 'away' ? 'Away' : 'Home';
            finalItems.push({
              title: (
                <PlayerBreadcrumbTag
                  value={looser_player}
                  color={color_settings[capitilise]}
                  invertedText={color_settings[`${capitilise}InvertText`]}
                  clickCallback={() =>
                    replacePlayer(
                      winnerTeamName.toLowerCase() === 'home'
                        ? awayPlayer
                        : homePlayer,
                    )
                  }
                />
              ),
            });
          } else {
            allowAddNext = false;
            finalItems.push({ title: 'Выбери игрока 2' });
          }
        } else {
          finalItems.push({ title: 'Выбери игрока 1' });
          allowAddNext = false;
        }
        return { allowAddNext, finalItems };
      }
      if (same_team) {
        const currentTeam = currentEditedEvent.track.attributes.filter(
          (attr: any) => attr.key === 'team',
        )[0];
        const currentTeamName = currentTeam?.values[0]?.value;
        if (currentTeamName) {
          const playerIn = currentEditedEvent.track.attributes.filter(
            (attr: any) => attr.key === 'player_in',
          )[0];
          const playerInNumber = playerIn?.values[0]?.value;
          const playerOut = currentEditedEvent.track.attributes.filter(
            (attr: any) => attr.key === 'player_out',
          )[0];
          const playerOutNumber = playerOut?.values[0]?.value;
          if (playerOutNumber) {
            finalItems.push({
              title: (
                <PlayerBreadcrumbTag
                  value={playerOutNumber}
                  color={color_settings[currentTeamName]}
                  invertedText={color_settings[`${currentTeamName}InvertText`]}
                  clickCallback={() => replacePlayer(playerIn)}
                />
              ),
            });
          } else {
            allowAddNext = true;
            finalItems.push({ title: 'Выбери уходящего игрока' });
          }
          if (playerInNumber) {
            finalItems.push({
              title: (
                <PlayerBreadcrumbTag
                  value={playerInNumber}
                  color={color_settings[currentTeamName]}
                  invertedText={color_settings[`${currentTeamName}InvertText`]}
                  clickCallback={() => replacePlayer(playerOut)}
                />
              ),
            });
            allowAddNext = false;
          } else {
            allowAddNext = false;
            finalItems.push({ title: 'Выбери входящего игрока ' });
          }
        } else {
          allowAddNext = true;
          finalItems.push({ title: 'Выбери уходящего игрока' });
        }
        return { allowAddNext, finalItems };
      }

      if (player_skip || (!player_skip && !same_team && !isDuel)) {
        const winnerTeam = currentEditedEvent.track.attributes.filter(
          (attr: any) => attr.name === 'Team',
        )[0];
        const winnerTeamName = winnerTeam?.values[0]?.value;
        if (winnerTeamName) {
          const otherTeamValue =
            winnerTeamName.toLowerCase() === 'home' ? 'Away' : 'Home';
          const firstPlayer = currentEditedEvent.track.attributes.filter(
            (attr: any) => attr.key === 'player',
          )[0];
          const firstPlayerNumber = firstPlayer?.values[0]?.value;
          const secondPlayer = currentEditedEvent.track.attributes.filter(
            (attr: any) => {
              return attr.key?.toLowerCase() === 'player_opposite';
            },
          )[0];
          const secondPlayerNumber = secondPlayer?.values[0]?.value;
          if (firstPlayerNumber) {
            finalItems.push({
              title: (
                <PlayerBreadcrumbTag
                  value={firstPlayerNumber}
                  color={color_settings[winnerTeamName]}
                  invertedText={color_settings[`${winnerTeamName}InvertText`]}
                  clickCallback={() => replacePlayer(firstPlayer)}
                />
              ),
            });
          }
          if (playerIsSkipped) {
            allowAddNext = true;
          } else if (secondPlayerNumber) {
            finalItems.push({
              title: (
                <PlayerBreadcrumbTag
                  value={secondPlayerNumber}
                  color={color_settings[otherTeamValue]}
                  invertedText={color_settings[`${otherTeamValue}InvertText`]}
                  clickCallback={() => replacePlayer(secondPlayer)}
                />
              ),
            });
          } else {
            allowAddNext = false;
            finalItems.push({ title: 'Выбери игрока 2' });
          }
        } else {
          finalItems.push({ title: 'Выбери игрока 1' });
          allowAddNext = false;
        }
        return { allowAddNext, finalItems };
      }
    }
    return { allowAddNext, finalItems };
  };
  const constructCoordinateItem = (
    finalItems: any[],
    allowAddNext: boolean,
  ) => {
    if (currentFastEvent?.has_coords) {
      finalItems.push({ title: 'Укажи точку на поле' });
      dispatch(setShowCoordinatesModal(true));
    }
    return { allowAddNext, finalItems };
  };
  const { playerIsSkipped } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  useEffect(() => {
    const finalItems: any = [];
    const allowAddNext = true;
    let res = setFirstItem(finalItems, allowAddNext);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (res.allowAddNext) {
      res = constructPlayerItems(
        res.finalItems,
        res.allowAddNext,
        playerIsSkipped,
      );
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (res.allowAddNext) {
      res = constructCoordinateItem(res.finalItems, res.allowAddNext);
    }
    setItems(res.finalItems);
  }, [editedEvents, currentFastEvent, color_settings, playerIsSkipped]);
  return items;
};

export const OperatorEditorBreadcrumb: FC<any> = () => {
  const { currentFastEvent } = useSelector(
    (state: AppStateType) => state.fastEventReducer,
  );
  const { editedEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const items = useMakeEditedEventController({
    currentFastEvent,
    editedEvents,
  });
  return (
    <div>
      {items.length > 0 && (
        <ConfigProvider
          theme={{
            token: {
              lineHeight: 2,
            },
          }}
        >
          <Breadcrumb items={items} />
        </ConfigProvider>
      )}
    </div>
  );
};
