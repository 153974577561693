import React, { FC, useEffect, useState } from 'react';

import { message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { RemoveTrackActionV2 } from 'store/actions/recorder.actions';
import { SetIsPlaying } from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { simulateMouseClick } from 'utils/functions';

const HotkeysManager: FC<any> = ({
  handleVideoScroll,
  toggleVideoSpeed,
  playbackRate,
  children,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [prevSpeed, setPrevSpeed] = useState(0);
  const { playerSettings, isHotkeysManagerListens } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );
  const isPlaying = useSelector(
    (state: AppStateType) => state.videoReducer.isPlaying,
  );
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { editedEvents, playerIsSkipped } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const { removeFastEventAction, setShowCoordinatesModal } =
    fastEventReducer.actions;
  const dispatch = useDispatch();
  const { setPlayerSkipped } = recorderReducerNew.actions;
  const [shiftDown, setShiftDown] = useState(false);
  const callDefaultHotkeys = async (keyAction: any, event: any) => {
    switch (keyAction[0].label) {
      case 'Play/Пауза': {
        dispatch(SetIsPlaying(!isPlaying));
        return;
      }
      case 'Вперед': {
        if (activeTask && playedSec + 1 <= activeTask.video_length) {
          handleVideoScroll((playedSec + 1) * 1000);
        }
        return;
      }
      case 'Назад': {
        if (activeTask && playedSec - 1 >= 0) {
          handleVideoScroll((playedSec - 1) * 1000);
        }
        return;
      }
      case 'Отменить новую задачу': {
        const editedEvent = editedEvents.filter(
          (evt: any) => evt.name !== 'Possession' && evt.name !== 'Period',
        );
        if (editedEvent.length > 0) {
          // если событие только записывается
          if (editedEvent[0]?.track?.id === -1) {
            await dispatch(
              RemoveTrackActionV2(editedEvent[0].track.id, editedEvent[0].id),
            );
            await dispatch(setShowCoordinatesModal(false));
            // если редактируем уже записанное событие
          } else {
            // console.log('dismiss edit');
            await dispatch(removeFastEventAction());
            await dispatch(setShowCoordinatesModal(false));
          }
        }
        if (playerIsSkipped) {
          dispatch(setPlayerSkipped(false));
        }
        // Escape отменяет фокус окну, это пофиксит
        document.getElementById('HotkeysManager')?.focus();
        return;
      }
      case 'Скорость': {
        if (shiftDown && !event.shiftKey) {
          setShiftDown(false);
          toggleVideoSpeed(prevSpeed);
        }
        return;
      }
      default: {
        console.log(keyAction[0].label);
        throw new Error();
      }
    }
  };
  const handleKeyUp = (event: any) => {
    if (!isHotkeysManagerListens) {
      return;
    }
    event.persist();
    if (
      playerSettings &&
      playerSettings.hotkeys &&
      playerSettings.hotkeys.length > 0
    ) {
      const keyAction = playerSettings.hotkeys.filter(
        (hk) => hk.key != null && hk.key != '' && hk.key == event.keyCode,
      );
      // TODO disabled for hotfix
      // if (keyAction.length > 1) {
      //   messageApi.open({
      //     type: 'error',
      //     content: '2 or more keys binded',
      //   });
      //   return;
      // }
      try {
        if (keyAction[0].block_id === 0) {
          callDefaultHotkeys(keyAction, event);
        } else {
          keyAction.forEach((ka) => {
            const element = document.getElementById(ka.id);
            if (element != null) {
              simulateMouseClick(element);
            }
          });
        }
      } catch {
        messageApi.open({
          type: 'error',
          content: 'cant find button',
        });
      }
    }
  };
  const handleKeyDown = (event: any) => {
    if (!isHotkeysManagerListens) {
      return;
    }
    event.persist();
    if (
      playerSettings &&
      playerSettings.hotkeys &&
      playerSettings.hotkeys.length > 0
    ) {
      const keyAction = playerSettings.hotkeys.filter(
        (hk) => hk.key != null && hk.key != '' && hk.key == event.keyCode,
      );
      try {
        if (
          keyAction[0].block_id === 0 &&
          keyAction[0].label === 'Скорость' &&
          ((!shiftDown && event.shiftKey) || (!shiftDown && !event.shiftKey))
        ) {
          setShiftDown(true);
          setPrevSpeed(() => playbackRate);
          toggleVideoSpeed(2);
        }
      } catch {
        return;
      }
    }
  };
  useEffect(() => {
    const evList: any = window.addEventListener('focus', () => {
      document.getElementById('HotkeysManager')?.focus();
    });
    return window.removeEventListener('focus', evList);
  }, []);
  return (
    <div
      id="HotkeysManager"
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      {contextHolder}
      {children}
    </div>
  );
};

export default HotkeysManager;
