import React, { FC, useEffect, useState } from 'react';

import { SettingOutlined } from '@ant-design/icons';
import { Button, Checkbox, ConfigProvider, Drawer, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  OperatorSettingsColor,
  OperatorSettingsHotkeys,
} from 'components/OperatorSettings';
import { HotkeysFooter } from 'components/OperatorSettings/OperatorSettingsHotkeys/HotkeysFooter';
import { SetHotkeysDisplay } from 'store/actions/player.actions';
import { AppStateType } from 'store/reducers';
import { playerReducer } from 'store/reducers/player.reducer';
import { getGrouppedKeys } from 'utils/functions';
import './style.css';

export const OperatorSettings: FC<any> = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [hotkeysGroupped, setHotkeysGroupped] = useState<any>(null);
  const { playerSettings, hotkeysForm, hotkeysIsDisplayed } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { setHotkeysFormAction } = playerReducer.actions;

  const handleShowHotkeys = () => {
    dispatch(SetHotkeysDisplay(!hotkeysIsDisplayed));
  };
  const handleSetHotkeys = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(setHotkeysFormAction(playerSettings?.hotkeys));
  }, [playerSettings?.hotkeys]);
  useEffect(() => {
    if (hotkeysForm && hotkeysForm?.length > 0) {
      const grouppedObjects = getGrouppedKeys(hotkeysForm);
      setHotkeysGroupped(grouppedObjects);
    }
  }, [hotkeysForm]);
  return (
    <>
      <Popconfirm
        placement="bottom"
        title={<></>}
        description={<OperatorSettingsColor />}
        icon={<></>}
        okText={<Checkbox checked={hotkeysIsDisplayed}>Show hotkeys</Checkbox>}
        okButtonProps={{ type: 'text' }}
        onConfirm={handleShowHotkeys}
        cancelText="Установить горячие клавиши"
        cancelButtonProps={{ type: 'default' }}
        onCancel={handleSetHotkeys}
        rootClassName="operatorSettingsPopconfirm"
      >
        <Button type="text">
          <SettingOutlined />
        </Button>
      </Popconfirm>
      <ConfigProvider
        theme={{
          components: {
            Drawer: {
              footerPaddingBlock: 16,
              footerPaddingInline: 24,
            },
          },
        }}
      >
        <Drawer
          title={<h3>Горячие клавиши</h3>}
          onClose={onClose}
          open={open}
          footer={<HotkeysFooter onClose={onClose} />}
        >
          <OperatorSettingsHotkeys hotkeysGroupped={hotkeysGroupped} />
        </Drawer>
      </ConfigProvider>
    </>
  );
};
