export const REMOVE_TRACK = 'REMOVE_TRACK';
export const OBSERVE_RECORDING = 'OBSERVE_RECORDING';
export const COPY_TRACK_EVENT = 'COPY_TRACK_EVENT';
export const UPDATE_EDITED_EVENT = 'UPDATE_EDITED_EVENT';
export const UPDATE_ALL_ACTIVE_EVENTS = 'UPDATE_ALL_ACTIVE_EVENTS';
export const SET_PLAYER_SETTINGS = 'SET_PLAYER_SETTINGS';
export const SET_PLAYED_FRAME = 'SET_PLAYED_FRAME';
export const EVENT_WINDOW_MODE = 'EVENT_WINDOW_MODE';
export const SET_IS_PLAYING = 'SET_IS_PLAYING';

export const ADD_NEW_EDITED_EVENT = 'ADD_NEW_EDITED_EVENT';
export const REMOVE_EDITED_EVENT = 'REMOVE_EDITED_EVENT';

export const ADD_USER_POINT = 'ADD_USER_POINT';
export const REMOVE_USER_POINT = 'REMOVE_USER_POINT';
export const GET_USER_POINTS = 'GET_USER_POINTS';

export const LOGIN_ACTION = 'LOGIN_ACTION';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const LOGIN_ACTION_SUCCESS = 'LOGIN_ACTION_SUCCESS';
export const LOGIN_ACTION_FAILURE = 'LOGIN_ACTION_FAILURE';

export const GET_ACTIVE_TASK_REQUEST = 'GET_ACTIVE_TASK_REQUEST';
export const GET_ACTIVE_TASK_REQUEST_SUCCESS =
  'GET_ACTIVE_TASK_REQUEST_SUCCESS';
export const GET_ACTIVE_TASK_REQUEST_FAILURE =
  'GET_ACTIVE_TASK_REQUEST_FAILURE';

export const START_TASK_REQUEST = 'START_TASK_REQUEST';
export const START_TASK_REQUEST_SUCCESS = 'START_TASK_REQUEST_SUCCESS';
export const START_TASK_REQUEST_FAILURE = 'START_TASK_REQUEST_FAILURE';

export const VIDEO_ERROR = 'VIDEO_ERROR';

export const SET_EVENT_COORDINATES_ACTION = 'SET_EVENT_COORDINATES_ACTION';
