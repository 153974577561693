import React, { FC, useState } from 'react';

import closeMessage from 'resources/img/closeMessage.svg';
import messagesIcon from 'resources/img/messagesIcon.svg';
import messagesIconContains from 'resources/img/messagesIconContains.svg';

import './style.css';

interface MessagesInterface {
  hasMessages?: boolean;
  messages: Array<string>;
}
const Messages: FC<MessagesInterface> = ({ hasMessages = false, messages }) => {
  const logo = hasMessages ? messagesIconContains : messagesIcon;
  const [showMessages, setShowMessages] = useState(false);
  const toggleShowMessages = () => {
    setShowMessages(!showMessages);
  };

  return (
    <div className={'messagesIcon'}>
      <img
        onClick={() => toggleShowMessages()}
        src={showMessages ? closeMessage : logo}
      />
      {showMessages &&
        messages.map((message: string, index: number) => (
          <div className={'headerMessageWrapper'} key={index}>
            <div className={'headerMessageContent'}>{message}</div>
          </div>
        ))}
    </div>
  );
};
export default Messages;
