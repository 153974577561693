/* eslint-disable no-undef */
import React from 'react';

import { configureStore } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import FPSStats from 'react-fps-stats';
import { Provider } from 'react-redux';

import App from './App';
import './index.css';
import mainReducer from './store/reducers';
import { setupResponseInterceptor } from './utils/axios';

const store = configureStore({
  reducer: mainReducer,
  devTools: {
    actionsBlacklist: [
      '/setPlayedSec',
      'setCurrentPeriod',
      'setNextPeriod',
      'setActivePlacement',
      'setPoorPerformancePlayedSec',
    ],
  },
});
setupResponseInterceptor(store);
// eslint-disable-next-line import/no-named-as-default-member
ReactDOM.render(
  <Provider store={store}>
    <App />
    {window.debugFPS && <FPSStats graphWidth={200} left="50vw" />}
  </Provider>,
  // eslint-disable-next-line no-undef
  document.getElementById('root'),
);
