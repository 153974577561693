import { createSlice } from '@reduxjs/toolkit';

import { ActiveTaskType } from 'types';

const initialState: InitialStateType = {
  isLoading: false,
  error: null,
  activeTask: null,
  viewTimeline: 'default',
};

type InitialStateType = {
  isLoading: boolean;
  error: any;
  activeTask: ActiveTaskType | null;
  viewTimeline: 'default' | 'byPlayers';
};

export const taskReducer = createSlice({
  name: 'taskReducer',
  initialState: initialState,
  reducers: {
    addPlayerNumber(state, action) {
      const lineUp = state.activeTask?.teams_lineup.filter(
        (lnp: any) => lnp.team === action.payload.team.toLowerCase(),
      )[0];
      lineUp?.players.push(action.payload.number);
      lineUp?.players.sort((a: number, b: number) => {
        return a - b;
      });
    },
    videoViewErrorAction(state, action) {
      state.error = action.payload;
    },
    startTastErrorAction(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    startTaskSuccessAction(state) {
      state.isLoading = false;
      state.error = null;
    },
    startTaskRequestAction(state) {
      state.isLoading = true;
    },
    getActiveTaskErrorAction(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getActiveTaskSuccessAction(state, action) {
      state.isLoading = false;
      state.activeTask = action.payload;
      state.error = null;
    },
    getActiveTaskRequestAction(state) {
      state.isLoading = true;
      state.error = null;
    },
    setViewTimeline(state, action) {
      state.viewTimeline = action.payload;
    },
  },
});
