import { createSlice } from '@reduxjs/toolkit';

import { ActiveEventType } from 'types';

const initialState: InitialStateType = {
  possessionHomeFastEvent: undefined,
  possessionAwayFastEvent: undefined,
  homeTeamName: 'Home',
  awayTeamName: 'Away',
  event: {
    id: 0,
    name: '',
    tracks: [],
    isVisible: false,
  },
  activePossession: undefined,
  possessions: [],
};

type InitialStateType = {
  possessionHomeFastEvent: any;
  possessionAwayFastEvent: any;
  homeTeamName: string;
  awayTeamName: string;
  activePossession: any;
  event: ActiveEventType;
  possessions: any[];
};

export const possessionSlice = createSlice({
  name: 'possessionSlice',
  initialState: initialState,
  reducers: {
    setPossessionHomeFastEvent(state, action) {
      state.possessionHomeFastEvent = action.payload;
    },
    setPossessionAwayFastEvent(state, action) {
      state.possessionAwayFastEvent = action.payload;
    },
    setHomeTeamName(state, action) {
      state.homeTeamName = action.payload;
    },
    setAwayTeamName(state, action) {
      state.awayTeamName = action.payload;
    },
    setActivePossession(state, action) {
      state.activePossession = action.payload;
    },
    setPossessions(state, action) {
      state.possessions = action.payload;
    },
    setEvent(state, action) {
      state.event = action.payload;
    },
    updateEvent(state, action) {
      state.event = { ...state.event, ...action.payload };
    },
  },
});
