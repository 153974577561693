import React, { FC, useEffect } from 'react';

import { Alert, ConfigProvider, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import { alertReducer } from 'store/reducers/alerts.reducer';

const CustomAlert: FC = () => {
  const onClose = () => {};
  const [messageApi, contextHolder] = message.useMessage();

  const { alertText, type, show } = useSelector(
    (state: AppStateType) => state.alertReducer,
  );
  const { hideNotificationAction } = alertReducer.actions;
  const dispatch = useDispatch();
  const showAlert = () => {
    messageApi.open({
      type: 'error',
      icon: <></>,
      duration: 10,
      style: {
        padding: 0,
      },
      onClose() {
        dispatch(hideNotificationAction());
      },
      content: <Alert message={alertText} type={type} onClose={onClose} />,
    });
  };
  useEffect(() => {
    if (show) {
      showAlert();
    }
  }, [show]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Message: {
            contentPadding: 0,
          },
        },
      }}
    >
      {contextHolder}
    </ConfigProvider>
  );
};
export default CustomAlert;
