import React, { FC, ForwardedRef, forwardRef } from 'react';

import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';

import EyeHiddenIcon from 'resources/img/eyeHiddenIcon.svg';
import EyeVisibleIcon from 'resources/img/eyeVisibleIcon.svg';
import SortingHandleIcon from 'resources/img/sortingHandleDotsIcon.svg';
import { ActiveEventType } from 'types';

interface Interface {
  id: number;
  attribute: ActiveEventType;
  toggleElementVisible: (id: number) => void;
}
const Item: FC<any> = forwardRef<ForwardedRef<any>, any>((props, ref) => {
  return (
    <div {...props} ref={ref}>
      {props.children}
    </div>
  );
});
export const SortableAttributeItem: FC<Interface> = ({
  id,
  attribute,
  toggleElementVisible,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id, disabled: false });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Item
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className={'enabledClickable sortableAttribute'}
    >
      <div>
        <img src={SortingHandleIcon} alt={'Reorder handle'} />
        <span>{attribute.label ? attribute.label : attribute.name}</span>
      </div>
      <img
        onClick={() => toggleElementVisible(id)}
        src={attribute.isVisible ? EyeVisibleIcon : EyeHiddenIcon}
        alt={'Toggle show|hide'}
      />
    </Item>
  );
};
