import { ThunkAction } from 'redux-thunk';

import {
  addNewValue,
  addToMainGamePlayers,
  getGamePlayers,
  removeFromMainGamePlayers,
} from 'api';
import { AppStateType } from 'store/reducers';
import { taskReducer } from 'store/reducers/task.reducer';
import { gamePlayersSlice } from 'store/slices/gamePlayersSlice';
import { checkNewPlayerNumberIsUnique } from 'utils/functions';

const { setHomePlayers, setHomeMainStaff, setAwayPlayers, setAwayMainStaff } =
  gamePlayersSlice.actions;

export const GetPlayers = (
  taskId: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      const response = await getGamePlayers(taskId);
      if (
        response.length > 0 &&
        response[0].team_type.toLowerCase() == 'home' &&
        response[1].team_type.toLowerCase() == 'away'
      ) {
        dispatch(setHomePlayers(response[0].extra_lineup));
        dispatch(setHomeMainStaff(response[0].game_lineup));
        dispatch(setAwayPlayers(response[1].extra_lineup));
        dispatch(setAwayMainStaff(response[1].game_lineup));
      } else {
        console.error('wrong serwer answer');
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const AddNewPlayer = (
  playerNumber: number,
  teamType: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    const { addPlayerNumber } = taskReducer.actions;
    try {
      const activeTask = getState().taskReducer.activeTask;
      if (!activeTask) {
        return;
      }
      const newValue = {
        value: playerNumber,
        task_id: activeTask?.id,
        team: teamType,
      };
      let playerAttributeId;
      let eventId;
      if (activeTask) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (const fastEvent of activeTask?.task_fast_events) {
          if (playerAttributeId) {
            break;
          }
          for (const attribute of fastEvent.additional_values) {
            if (attribute.key.includes('player')) {
              playerAttributeId = attribute.attribute_id;
              eventId = fastEvent.event_id;
              break;
            }
          }
        }
      }
      if (
        playerAttributeId !== undefined &&
        eventId &&
        checkNewPlayerNumberIsUnique(
          playerNumber,
          teamType,
          activeTask.teams_lineup,
        )
      ) {
        await addNewValue(eventId, playerAttributeId, newValue);
        dispatch(GetPlayers(activeTask?.id));
        dispatch(
          addPlayerNumber({
            team: teamType,
            number: playerNumber,
          }),
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const AddToMainPlayers = (
  player: any,
  teamName: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const activeTask = getState().taskReducer.activeTask;
      if (!activeTask) {
        return;
      }
      const teamId =
        teamName == 'home' ? activeTask.home_team_id : activeTask.away_team_id;
      await addToMainGamePlayers(activeTask.id, teamId, player.id);
      dispatch(GetPlayers(activeTask?.id));
    } catch (err) {
      console.log(err);
    }
  };
};

export const RemoveFromMainPlayers = (
  player: any,
  teamName: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const activeTask = getState().taskReducer.activeTask;
      if (!activeTask) {
        return;
      }
      const teamId =
        teamName == 'home' ? activeTask.home_team_id : activeTask.away_team_id;
      await removeFromMainGamePlayers(activeTask.id, teamId, player.id);
      dispatch(GetPlayers(activeTask?.id));
    } catch (err) {
      console.log(err);
    }
  };
};
