import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

import { createCommonHeader } from './_functions';

export async function getPlayerSettings() {
  const taskId = new URLSearchParams(window.location.search).get('taskId');
  let url = `${endpoint}/api/v1/settings/`;
  if (taskId) {
    url = `${url}?taskId=${taskId}`;
  }
  const headers = createCommonHeader();
  try {
    const response = await myAxios.GET(url, null, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export const updateSettings = async (payload: any) => {
  const url = `${endpoint}/api/v1/settings/`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.PATCH(url, payload, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
};
