import React, { FC, useLayoutEffect, useState } from 'react';

import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { EventButton } from 'components/ui';
import { KEYKODES_TO_CHAR } from 'constants/constants';
import { createFromFastEventAction } from 'store/actions/recorder.actions';
import { SetIsPlaying } from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import {
  ActiveEventType,
  FastEventType,
  HotkeyType,
  TaskEventsType,
  TrackType,
} from 'types';
import { getCurrentEditedEvent } from 'utils/functions';

interface Interface {
  disabled: boolean;
  item: FastEventType;
  showHotKey: boolean;
  order?: number;
  push?: number;
}

const EventsListItem: FC<Interface> = ({
  disabled,
  item,
  showHotKey,
  order,
}) => {
  const dispatch = useDispatch();
  const isPlaying = useSelector(
    (state: AppStateType) => state.videoReducer.isPlaying,
  );

  const activeEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.activeEvents,
  );
  const activeTask = useSelector(
    (state: AppStateType) => state.taskReducer.activeTask,
  );

  const editedEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.editedEvents,
  );
  const currentFastEvent = useSelector(
    (state: AppStateType) => state.fastEventReducer.currentFastEvent,
  );

  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );

  const checkEventIsDisabled = (
    evt: FastEventType,
    isPlayingState: boolean,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): boolean => {
    // блокировка начала записи события, если в текущей позиции уже есть записанное событие
    // или если это событие с уникальными атрибутами и все атрибуты уже есть в треках
    // позволяет начинать новые события в один и тот же момент или несколько событий 0 длительности
    if (evt?.unique_attributes) {
      const matchedActiveEvents = activeEvents.filter(
        (activeEvt: ActiveEventType) => activeEvt.id === evt.event_id,
      );
      // тут проверка на кол-во размеченных треков для блокировки события
      if (matchedActiveEvents.length > 0) {
        const baseTask = activeTask?.task_events.filter(
          (tsk: TaskEventsType) => tsk.id === evt.event_id,
        )[0];
        const tracks: TrackType[] = matchedActiveEvents[0].tracks;
        if (baseTask) {
          if (baseTask.event_attributes[0].values.length === tracks.length) {
            if (
              editedEvents.length > 0 &&
              editedEvents[0].track.isRecording &&
              evt.event_id === editedEvents[0].id
            ) {
              return false;
            }
            return !!(
              editedEvents.length > 0 && editedEvents[0].id === evt.event_id
            );
          } else {
            if (
              editedEvents.length > 0 &&
              editedEvents[0].id === evt.event_id
            ) {
              return false;
            }
          }
          return false;
        }
      }
    }
    const currentlyRecordedEvt = getCurrentEditedEvent(editedEvents, evt);
    const matchedActiveEvent = activeEvents.filter(
      (activeEvt: ActiveEventType) => activeEvt.id === evt.event_id,
    )[0];
    if (currentlyRecordedEvt) {
      // если есть редактируемое или записываемое событие
      const currentlyRecordedTrack = currentlyRecordedEvt.track;
      const endElementCheckedStop = isPlayingState
        ? currentlyRecordedTrack.stop
        : playedSec;
      const tracks = matchedActiveEvent?.tracks.filter((track: any) => {
        if (
          !track.isRecording &&
          typeof endElementCheckedStop !== 'undefined'
        ) {
          const case3 =
            currentlyRecordedTrack.start === track.start &&
            endElementCheckedStop === track.start; // запись началась до начала трека
          if (case3) {
            return false;
          }
          const case1 =
            //@ts-ignore
            currentlyRecordedTrack.start >= track.stop &&
            //@ts-ignore
            endElementCheckedStop >= track.stop; // запись началась после рассматриваемого
          const case2 =
            //@ts-ignore
            currentlyRecordedTrack.start <= track.start &&
            //@ts-ignore
            endElementCheckedStop <= track.start; // запись началась до начала трека
          return !(case1 || case2);
        }
        return false;
      });
      return tracks?.length > 0;
    } else {
      // если нет записываемого события
      if (matchedActiveEvent) {
        // проверяем, что текущее положение проигрывателя попадает в какой-то из треков
        return (
          matchedActiveEvent.tracks.filter((track: any) => {
            return track.start <= playedSec && playedSec <= track.stop;
          }).length > 0
        ); // если найден хотя бы один трек, кнопка события блокируется
      }
    }
    return false;
  };

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(
    checkEventIsDisabled(item, isPlaying),
  );
  const { addFastEventAction, removeFastEventAction, setShowCoordinatesModal } =
    fastEventReducer.actions;

  const handleFastEventClick = async () => {
    if (!isActive) {
      dispatch(SetIsPlaying(false));
      dispatch(createFromFastEventAction(item.id));
      dispatch(setShowCoordinatesModal(true));
      dispatch(addFastEventAction(item));
    } else {
      dispatch(removeFastEventAction());
      dispatch(setShowCoordinatesModal(false));
    }
  };

  useLayoutEffect(() => {
    const hasCollision = checkEventIsDisabled(item, isPlaying);
    if (currentFastEvent?.id !== undefined) {
      if (currentFastEvent?.id === item.id) {
        setIsActive(true);
        // eslint-disable-next-line sonarjs/no-redundant-boolean
        setIsDisabled(false && hasCollision);
      } else {
        setIsActive(false);
        // eslint-disable-next-line sonarjs/no-redundant-boolean
        setIsDisabled(true || hasCollision);
      }
    } else {
      setIsActive(false);
      setIsDisabled(hasCollision);
    }
  }, [currentFastEvent, editedEvents, playedSec]);
  const { hotkeysForm } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const getHotkey = () => {
    const hotKey = hotkeysForm?.filter(
      (hk: HotkeyType) => hk.id === item.id,
    )[0];
    // eslint-disable-next-line prettier/prettier
    return hotKey?.key == null ? '' : KEYKODES_TO_CHAR[hotKey.key] || 'unsup char';
  };
  return (
    <Col span={8} order={order}>
      <EventButton
        id={item.id}
        disabled={disabled || isDisabled} //  TODO тут добавить проверку, что нет уже созданного события этого типа в текущий момент
        handleClick={handleFastEventClick}
        content={item.name}
        hotkey={showHotKey ? getHotkey() : undefined}
        active={isActive}
        type={'fastEvent'}
      />
    </Col>
  );
};

export default EventsListItem;
