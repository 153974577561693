import React, { FC } from 'react';

import { Button } from 'antd';
import { useDispatch } from 'react-redux';

import { LogoutAction } from 'store/actions/auth.actions';

const Logout: FC = () => {
  const dispatch = useDispatch();
  const logoutButtonHandler = () => {
    dispatch(LogoutAction());
  };
  return (
    <Button type="primary" onClick={() => logoutButtonHandler()}>
      Выйти
    </Button>
  );
};
export default Logout;
