import React, { FC, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ControlsContainer } from 'components/OperatorEditor/OperatorEditorVideoTimeline/ControllsContainer/index.jsx';
import { RecordedEvents } from 'components/OperatorEditor/OperatorEditorVideoTimeline/RecordedEvents';
import { TableEvents } from 'components/OperatorEditor/OperatorEditorVideoTimeline/TableEvents/TableEvents';
import { BottomScroller } from 'components/ui';
import { SetIsPlaying } from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';

export const OperatorEditorVideoTimeline: FC<any> = ({
  zoomValue,
  setZoomValue,
  toggleVideoSpeed,
  frameRate,
  playbackRate,
  keyDownHandler,
  setSelectedEventId,
  handleVideoScroll,
}) => {
  const isPlaying = useSelector(
    (state: AppStateType) => state.videoReducer.isPlaying,
  ); // Редактируемые/записываемые в настоящий момент события
  const { activeTask, viewTimeline } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const poorPerformancePlayedSec = useSelector(
    (state: AppStateType) => state.videoReducer.poorPerformancePlayedSec,
  );
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );
  const dispatch = useDispatch();
  const scrollerRef = useRef<any>();
  const progressRef = useRef<any>();
  const [horizontalScroll, setHorizontalScroll] = useState(0);
  const handleScroll = (value: any) => {
    setHorizontalScroll(() => {
      return value;
    });
  };
  const togglePlay = () => {
    dispatch(SetIsPlaying(!isPlaying));
  };
  useEffect(() => {
    const progressWidth = scrollerRef.current?.clientWidth;
    let oldScreenPosition = 0;
    let newScreenPosition = 0;
    if (!!poorPerformancePlayedSec && !!activeTask?.video_length) {
      oldScreenPosition =
        (poorPerformancePlayedSec / activeTask?.video_length) * progressWidth;
    }
    const newWidth = progressWidth * (1 + zoomValue / 100);
    if (!!poorPerformancePlayedSec && !!activeTask?.video_length) {
      const newProgressBarPosition =
        (poorPerformancePlayedSec / activeTask?.video_length) * newWidth;
      newScreenPosition = newProgressBarPosition - oldScreenPosition;
    }
    scrollerRef.current.scrollLeft = newScreenPosition;
    setHorizontalScroll(newScreenPosition);
  }, [zoomValue]);
  return (
    <>
      <ControlsContainer
        //  Контролеры управления видео проигрывателем
        isPlaying={isPlaying}
        handlePlayClick={togglePlay}
        videoStart={0}
        videoEnd={activeTask?.video_length || 0} // TODO сюда прокидывать общую длительность видео в секундах из ответа АПИ
        currentPosition={poorPerformancePlayedSec}
        handleVideoScroll={handleVideoScroll}
        zoomValue={zoomValue}
        setZoomValue={setZoomValue}
        toggleVideoSpeed={toggleVideoSpeed}
        horizontalScroll={horizontalScroll}
        playbackRate={playbackRate}
        frameRate={frameRate}
        progressRef={progressRef}
      />
      {viewTimeline == 'default' && (
        <RecordedEvents
          //  Раздел с треками событий
          videoEnd={activeTask?.video_length || 0}
          zoomValue={zoomValue}
          setSelectedEventId={setSelectedEventId}
          handleVideoScroll={handleVideoScroll}
          horizontalScroll={horizontalScroll}
          currentPosition={playedSec}
          keyDownHandler={keyDownHandler}
        />
      )}
      {viewTimeline == 'byPlayers' && (
        <TableEvents handleVideoScroll={handleVideoScroll} />
      )}
      <BottomScroller
        scrollerRef={scrollerRef}
        onScroll={handleScroll}
        width={100 * (1 + zoomValue / 100)}
      />
    </>
  );
};
