import React, { FC } from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import { FastEventType } from 'types';

import EventsListItem from './EventsListItem';

const EventsList: FC<any> = ({ showHotKey }) => {
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const currentPeriod = useSelector(
    (state: AppStateType) => state.periodReducer.currentPeriod,
  );
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const renderButtons = () => {
    const grid = [];
    const filtered = activeTask?.task_fast_events
      ?.filter(
        (fe: FastEventType) =>
          fe.name !== 'Possession home' && fe.name !== 'Possession away',
      )
      .sort((a, b) => a.ordering - b.ordering);
    const buttonsArray: number[] | undefined = filtered?.map(
      (el: any) => el.ordering,
    );
    let isAvailable = false;
    if (currentPeriod.id) {
      isAvailable = true;
    }
    if (filtered && buttonsArray) {
      const maxIndex = Math.max(...buttonsArray);
      for (let i = 1; i <= maxIndex; i++) {
        if (buttonsArray.includes(i)) {
          const index = buttonsArray.indexOf(i);
          grid.push(
            <EventsListItem
              disabled={!isAvailable}
              item={filtered[index]}
              showHotKey={showHotKey}
              key={i}
            />,
          );
        } else {
          grid.push(<Col span={8} style={{ height: 60 }} key={i}></Col>);
        }
      }
      return grid;
    }
  };
  return <Row gutter={[8, 8]}>{renderButtons()}</Row>;
};

export default EventsList;
