import { createSlice } from '@reduxjs/toolkit';

import { State } from 'types/app';

import { Settings } from '../types';

const initialState: InitialStateType = {
  settings: [],
  state: State.init,
};

type InitialStateType = {
  settings: Settings | [];
  state: State;
};

export const rawSettingsSlice = createSlice({
  name: 'rawSettingsSlice',
  initialState: initialState,
  reducers: {
    setSettings(state, action) {
      state.settings = action.payload;
    },
  },
});
