import React, { FC } from 'react';

import {
  Checkbox,
  CheckboxProps,
  Col,
  Divider,
  Row,
  Select,
  Space,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { FIELD_IMAGES } from 'constants/constants';
import {
  SetColorSettingsAction,
  SetUrlToBackgroundImage,
} from 'store/actions/player.actions';
import { AppStateType } from 'store/reducers';

import OperatorSettingsLoacalVideo from '../OperatorSettingsLoacalVideo';
import { OperatorSettingsSchemaPlacement } from '../OperatorSettingsSchemaPlacement';
import { PeriodsAddControl } from '../PeriodsAddControl/PeriodsAddControl';
// eslint-disable-next-line import/order
import ColorSelectList from './ColorSelectList';
import './operatorSettingsColor.css';

export const OperatorSettingsColor: FC<any> = () => {
  const dispatch = useDispatch();
  const { playerSettings } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const handleHomeWhiteText: CheckboxProps['onChange'] = (e) => {
    dispatch(
      SetColorSettingsAction({
        key: 'HomeInvertText',
        value: e.target.checked,
      }),
    );
  };
  const handleAwayWhiteText: CheckboxProps['onChange'] = (e) => {
    dispatch(
      SetColorSettingsAction({
        key: 'AwayInvertText',
        value: e.target.checked,
      }),
    );
  };
  const handleFieldImageChange = (val: string) => {
    dispatch(SetUrlToBackgroundImage(val));
  };

  return (
    <>
      <Row gutter={16}>
        <Col>
          <div>Команда-хозяйка</div>
          <OperatorSettingsSchemaPlacement teamType="home" />
          <ColorSelectList team="Home" />
          <Checkbox
            checked={
              playerSettings.color_settings?.HomeInvertText
                ? playerSettings.color_settings?.HomeInvertText
                : false
            }
            onChange={handleHomeWhiteText}
          >
            Белый текст
          </Checkbox>
        </Col>
        <Col>
          <div>Команда-гость</div>
          <OperatorSettingsSchemaPlacement teamType="away" />
          <ColorSelectList team="Away" />
          <Checkbox
            checked={
              playerSettings.color_settings?.AwayInvertText
                ? playerSettings.color_settings?.AwayInvertText
                : false
            }
            onChange={handleAwayWhiteText}
          >
            Белый текст
          </Checkbox>
        </Col>
      </Row>

      <Divider className="operatorSettingsColorDevider" />
      <PeriodsAddControl />
      <Divider className="operatorSettingsColorDevider" />
      <OperatorSettingsLoacalVideo />
      <Divider className="operatorSettingsColorDevider" />
      <Space wrap>
        <Select
          defaultValue={'no_stripes.png'}
          style={{ width: 120 }}
          onChange={handleFieldImageChange}
          options={activeTask ? FIELD_IMAGES[activeTask?.sport.id] : []}
        />
      </Space>
      <Divider className="operatorSettingsColorDevider" />
    </>
  );
};
