import React, { FC, useEffect, useRef, useState } from 'react';

import { Col, Row, Spin, message } from 'antd';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';

import CustomAlert from 'components/Alert';
import GameProtocolSidePanel from 'components/GameProtocolSidePanel';
import HotkeysManager from 'components/HotkeysManager';
import {
  OperatorEditorFieldCoordinatesModal,
  OperatorEditorHeader,
  OperatorEditorLeftMenu,
  OperatorEditorVideoPlayer,
  OperatorEditorVideoTimeline,
} from 'components/OperatorEditor';
import { PassesNotification } from 'components/PassesNotification';
import {
  TOTAL_COL_BY_ANT,
  VIDEO_WIDTH_COL_SPAN,
  endpoint,
} from 'constants/constants';
import DefaultLayout from 'layout/DefaultLatout';
// import { usePlayerHandler } from 'pages/OperatorWindow/components/Player/hooks';
import { AppInit } from 'store/actions/app.actions';
import { GetPlayerSettingsAction } from 'store/actions/player.actions';
import { GetCurrentEventsAction } from 'store/actions/recorder.actions';
import { GetActiveTaskAction } from 'store/actions/task.actions';
import { AppStateType } from 'store/reducers';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { State } from 'types/app';

import './OperatorEditor.css';

const OperatorEditor: FC<any> = () => {
  const refPlayer = useRef<ReactPlayer>(null); // Ссылка на проигрыватель
  const [zoomValue, setZoomValue] = useState(0); // Хранит состояние зума треков
  const [playbackRate, setPlaybackRate] = useState(1); // Хранит скорость воспроизведения видео
  const toggleVideoSpeed = (value: number) => {
    // Переключаем скорость воспроизведения видео
    setPlaybackRate(value);
  };
  const [frameRate, setFrameRate] = useState(0);
  const dispatch = useDispatch();
  const [videoLink, setVideoLink] = useState('');
  const { state } = useSelector((st: AppStateType) => st.appReducer);
  const playedSec = useSelector(
    (st: AppStateType) => st.videoReducer.playedSec,
  );
  useEffect(() => {
    // TODO replase by v2 Оптимизация
    // LOAD TASK
    dispatch(GetPlayerSettingsAction());
    const actionResult = dispatch(GetActiveTaskAction());
    Promise.resolve(actionResult).then((res: any) => {
      dispatch(GetCurrentEventsAction(res?.id));
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      videoLink === '' && res?.video_file !== null
        ? setVideoLink(`${endpoint}/api/v1/task/${res.id}/video/`)
        : null;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      frameRate === 0 && res.video_framerate
        ? setFrameRate(res.video_framerate)
        : null;
    });
    // TODO v2 Оптимизация
    dispatch(AppInit());
  }, []);

  const editedEvents = useSelector(
    (st: AppStateType) => st.recorderReducerNew.editedEvents,
  ); // Редактируемые/записываемые в настоящий момент события
  const errors = useSelector(
    (st: AppStateType) => st.recorderReducerNew.errors,
  );
  const { observeRecordingAction } = recorderReducerNew.actions;
  const handleVideoScroll = (millisecond: number) => {
    // Перемотка видео на нужную мили секунду
    const seconds = Number(millisecond / 1000);
    refPlayer?.current?.seekTo(seconds, 'seconds');
    for (const evt of editedEvents) {
      if (evt.track.isRecording && (playedSec || seconds >= 0)) {
        dispatch(
          observeRecordingAction({
            eventId: evt.id,
            trackId: evt.track.id,
            stop: seconds,
            isRecording: evt.track.isRecording,
          }),
        );
      }
    }
  };
  useEffect(() => {
    for (const error of errors) {
      message.error(error);
    }
  }, [errors]);
  if (state == State.pending || state == State.init) {
    return (
      <Spin
        size="large"
        tip="Загрузка задачи. Task loading..."
        fullscreen
      ></Spin>
    );
  }
  return (
    <HotkeysManager
      handleVideoScroll={handleVideoScroll}
      toggleVideoSpeed={toggleVideoSpeed}
      playbackRate={playbackRate}
      refPlayer={refPlayer}
    >
      <DefaultLayout header={<OperatorEditorHeader />}>
        <Row className="content-row">
          <Col
            span={TOTAL_COL_BY_ANT - VIDEO_WIDTH_COL_SPAN}
            style={{ marginTop: '-39px' }}
          >
            <OperatorEditorLeftMenu />
          </Col>
          <Col span={VIDEO_WIDTH_COL_SPAN} className="video-column">
            <OperatorEditorVideoPlayer
              refPlayer={refPlayer}
              playbackRate={playbackRate}
              videoLink={videoLink}
            />
            <OperatorEditorVideoTimeline
              playbackRate={playbackRate}
              zoomValue={zoomValue}
              setZoomValue={setZoomValue}
              toggleVideoSpeed={toggleVideoSpeed}
              frameRate={frameRate}
              handleVideoScroll={handleVideoScroll}
            />
          </Col>
        </Row>
        <OperatorEditorFieldCoordinatesModal />
        <CustomAlert />
        <PassesNotification />
        <GameProtocolSidePanel />
      </DefaultLayout>
    </HotkeysManager>
  );
};
export default OperatorEditor;
