import React, { FC, useEffect, useState } from 'react';

import { Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { EventButton } from 'components/ui';
import { KEYKODES_TO_CHAR } from 'constants/constants';
import { AppStateType } from 'store/reducers';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { HotkeyType } from 'types';

const EventsOptionsListItem: FC<any> = ({
  item,
  showHotKey,
  duplicates,
  duplicateNames,
}) => {
  const { editedEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const { currentFastEvent } = useSelector(
    (state: AppStateType) => state.fastEventReducer,
  );
  const [isActive, setIsActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const dispatch = useDispatch();
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const editedEvent = editedEvents?.filter(
      (ev: any) => ev.id === currentFastEvent?.event_id,
    )[0];
    const feAtt = currentFastEvent?.default_values?.filter(
      (att: any) => att.attribute_id === item.attribute_id,
    )[0];
    let matchingFastAttribute;
    let matchingTrackAttribute;
    let matchingTrackValues;
    if (duplicateNames.includes(`${item.name} ${item.value}`)) {
      let actualAttribute: any;
      const actualDublicatesAttributes = duplicates.filter(
        (duplFilter: any) =>
          duplFilter.value === item.value && duplFilter.name === item.name,
      );
      for (const duplFilter of actualDublicatesAttributes) {
        if (actualAttribute !== undefined) {
          break;
        }
        for (const faVal of currentFastEvent?.additional_values?.filter(
          (av: any) =>
            av.key !== 'player' &&
            av.key !== 'team' &&
            av.key !== 'player_home' &&
            av.key !== 'player_away',
        ) || []) {
          if (
            faVal.id === duplFilter.id &&
            faVal.attribute_id === duplFilter.attribute_id
          ) {
            actualAttribute = duplFilter;
            break;
          }
        }
      }
      if (actualAttribute) {
        matchingFastAttribute = currentFastEvent?.additional_values?.filter(
          (att: any) => att.attribute_id === actualAttribute.attribute_id,
        );
        matchingTrackAttribute = editedEvent?.track.attributes?.filter(
          (att: any) => att.id === actualAttribute.attribute_id,
        )[0];
        if (matchingTrackAttribute) {
          matchingTrackValues = matchingTrackAttribute.values.filter(
            (val: any) => val.value === actualAttribute.value,
          );
        }
      }
    } else {
      matchingFastAttribute = currentFastEvent?.additional_values?.filter(
        (att: any) => att.attribute_id === item.attribute_id,
      );
      matchingTrackAttribute = editedEvent?.track.attributes?.filter(
        (att: any) => att.id === item.attribute_id,
      )[0];
      if (matchingTrackAttribute) {
        matchingTrackValues = matchingTrackAttribute.values.filter(
          (val: any) => val.value === item.value,
        );
      }
    }
    if (matchingTrackAttribute !== undefined) {
      // console.log('mat', matchingTrackAttribute, item);
      // const matchingValue = matchingTrackAttribute?.values.filter(
      //   (val: any) => {
      //     return val?.value === item?.value;
      //   },
      // );
      setIsActive(
        ((matchingTrackValues && matchingTrackValues?.length) || 0) > 0,
      );
    } else {
      setIsActive(false);
    }
    setIsBlocked(feAtt && feAtt?.is_default);
    setIsDisabled(
      ((matchingTrackAttribute === undefined ||
        matchingTrackAttribute === null) &&
        (matchingFastAttribute?.length === 0 ||
          matchingFastAttribute === undefined)) ||
        !currentFastEvent,
    );
  }, [currentFastEvent, editedEvents]);
  const { addAdditionalAttributeToTrack, removeAdditionalAttributeFromTrack } =
    recorderReducerNew.actions;
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleClick = async () => {
    if (!currentFastEvent) {
      return;
    }
    if (!isBlocked && !isDisabled) {
      let newAttribute: any = {};
      if (duplicateNames.includes(`${item.name} ${item.value}`)) {
        let actualAttribute;
        for (const duplFilter of duplicates.filter(
          (dup: any) =>
            `${dup.name} ${dup.value}` === `${item.name} ${item.value}`,
        )) {
          if (actualAttribute !== undefined) {
            break;
          }
          for (const faVal of currentFastEvent.additional_values?.filter(
            (av: any) =>
              av.key !== 'player' &&
              av.key !== 'team' &&
              av.key !== 'player_home' &&
              av.key !== 'player_away',
          ) || []) {
            // console.log('faVal', faVal, duplFilter, faVal.id === duplFilter.id && faVal.attribute_id === duplFilter.attribute_id);
            if (
              faVal.id === duplFilter.id &&
              faVal.attribute_id === duplFilter.attribute_id
            ) {
              actualAttribute = duplFilter;
              break;
            }
          }
        }
        newAttribute = {
          id: actualAttribute.attribute_id,
          name: actualAttribute.name,
          type: actualAttribute.type,
          values: [
            {
              id: actualAttribute.id,
              value: actualAttribute.value,
              id_default: false,
            },
          ],
        };
      } else {
        newAttribute = {
          id: item.attribute_id,
          name: item.name,
          type: item.type,
          values: [
            {
              id: item.id,
              value: item.value,
              id_default: false,
            },
          ],
        };
      }
      if (isActive) {
        const existingValue = editedEvents
          .filter((evt: any) => evt.id === currentFastEvent.event_id)[0]
          ?.track.attributes.filter((att: any) => att.id === newAttribute.id)[0]
          .values.filter((val: any) => val.id === newAttribute.values[0].id);
        if (existingValue?.length) {
          await dispatch(
            removeAdditionalAttributeFromTrack({
              eventId: currentFastEvent?.event_id,
              attributeId: newAttribute?.id,
              valueId: newAttribute.values[0].id,
            }),
          );
        }
      } else {
        await dispatch(
          addAdditionalAttributeToTrack({
            attribute: newAttribute,
            eventId: currentFastEvent.event_id,
          }),
        );
      }
    }
  };
  const { playerSettings } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const getHotkey = () => {
    const hotKey = playerSettings.hotkeys?.filter(
      (hk: HotkeyType) => hk.id === `${item.id}-${item.attribute_id}`,
    )[0];
    if (hotKey?.key !== null) {
      return KEYKODES_TO_CHAR[hotKey.key] || 'unsup char';
    }
  };
  return (
    <Col span={24}>
      <EventButton
        id={`${item.id}-${item.attribute_id}`}
        handleClick={handleClick}
        content={item.label ? item.label : `${item.name} ${item.value}`}
        hotkey={showHotKey ? getHotkey() : undefined}
        disabled={isDisabled}
        active={isActive}
        isBlocked={isBlocked}
        type={'additionalProperty'}
      />
    </Col>
  );
};

export default EventsOptionsListItem;
