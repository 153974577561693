import { ThunkAction } from 'redux-thunk';

import { getPlayerSettings } from 'api';
import { rawSettingsSlice } from 'store/raw/slices/rawSettingsSlice';
import { AppStateType } from 'store/reducers';

const { setSettings } = rawSettingsSlice.actions;

export const GetSettings = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => {
  return async (dispatch) => {
    try {
      const response = await getPlayerSettings();
      dispatch(setSettings(response));
    } catch (err) {
      console.log(err);
    }
  };
};
