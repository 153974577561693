import { createSlice } from '@reduxjs/toolkit';

const initialState: InitialStateType = {
  isPlaying: false,
  videoFile: '',
  videoFramerate: 0,
  videoLength: 0,
  playedSec: 0,
  poorPerformancePlayedSec: 0, // warning too fast updates
  localVideoObjectURL: '',
};

type InitialStateType = {
  isPlaying: boolean;
  videoFile: string;
  videoFramerate: number;
  videoLength: number;
  playedSec: number;
  poorPerformancePlayedSec: number; // warning too fast updates
  localVideoObjectURL: string;
};

export const videoSlice = createSlice({
  name: 'videoSlice',
  initialState: initialState,
  reducers: {
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    setVideoFile(state, action) {
      state.videoFile = action.payload;
    },
    setVideoFramerate(state, action) {
      state.videoFramerate = action.payload;
    },
    setVideoLength(state, action) {
      state.videoLength = action.payload;
    },
    setPlayedSec(state, action) {
      state.playedSec = action.payload;
    },
    setPoorPerformancePlayedSec(state, action) {
      state.poorPerformancePlayedSec = action.payload;
    },
    setLocalVideoObjectURL(state, action) {
      state.localVideoObjectURL = action.payload;
    },
  },
});
