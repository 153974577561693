import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  user: any;
  token: string | null;
  redirectTo: string | null;
  isLoading: boolean;
  error: string | null;
};
const initialState: InitialStateType = {
  user: null,
  token: null,
  redirectTo: null,
  isLoading: false,
  error: null,
};

export const authReducer = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    loginRequestAction(state) {
      state.isLoading = true;
    },
    loginErrorAction(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    loginSuccessAction(state, action) {
      state.isLoading = false;
      state.error = null;
      state.token = action.payload.token;
      state.redirectTo = action.payload.redirectTo;
    },
    logoutAction(state) {
      state.token = null;
      state.redirectTo = null;
    },
  },
});
