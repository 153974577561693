import React, { FC, useEffect } from 'react';

import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import './style.scss.css';

interface ScrollerInterface {
  handleDrag: (e: any, data: any) => void;
  getCurrentProgressPosition: (ratio?: boolean) => number;
  getRightBound: () => number;
  draggedRef: any;
  toggleUserPointAdder: (e: any) => void;
  clickRef: any;
  getSingleSecondLength: any;
  zoomValue: number;
}

export const VideoScroller: FC<ScrollerInterface> = ({
  handleDrag,
  getCurrentProgressPosition,
  getRightBound,
  draggedRef,
  toggleUserPointAdder,
  clickRef,
  getSingleSecondLength,
  // zoomValue,
}) => {
  const { eventWindowMode } = useSelector(
    (state: AppStateType) => state.recorderReducer,
  );
  // const { activeTask } = useSelector(
  //   (state: AppStateType) => state.taskReducer,
  // );
  useEffect(() => {}, [eventWindowMode]);
  // const offset = activeTask?.max_zoom
  //   ? 7 * (zoomValue / activeTask?.max_zoom) + 7
  //   : 7;
  // console.log('offset', offset)
  return (
    <Draggable
      axis={'x'}
      // positionOffset={{x: -7, y: 0}}
      onDrag={handleDrag}
      position={{ x: getCurrentProgressPosition(), y: 0 }}
      bounds={{ right: getRightBound(), left: 0 }}
    >
      <div
        ref={draggedRef}
        style={{
          left: `calc(${getCurrentProgressPosition()}-7)px`,
        }}
        onClick={(e) => {
          if (e.detail === 2) {
            toggleUserPointAdder(e);
          }
          clickRef.current = false;
        }}
        className={'scroller'}
      >
        <div>
          {eventWindowMode && (
            <div
              className="positionLine orange"
              style={{ left: `${-getSingleSecondLength() + 7}px` }}
            />
          )}
          {/* {<div className={'positionLine'} />} */}
          {eventWindowMode && (
            <div
              className="positionLine orange"
              style={{ left: `${getSingleSecondLength() + 7}px` }}
            />
          )}
        </div>
      </div>
    </Draggable>
  );
};
