import React, { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

// import { SetSelectedEvent } from 'store/actions/events.actions';
import {
  RemoveEditedEventAction,
  UnselectTrackFoEditAction,
} from 'store/actions/recorder.actions';
import { SetIsPlaying } from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { ActiveEventType, FastEventType, TrackType } from 'types';

interface SegmentInterface {
  segment: any;
  parentWidth: any;
  activeEvents: any;
  activeTask: any;
  handleVideoScroll: (ms: number) => void;
}

export const Segment: FC<SegmentInterface> = ({
  segment,
  parentWidth,
  activeEvents,
  activeTask,
  handleVideoScroll,
}) => {
  const dispatch = useDispatch();
  const { selectTrackForEdit } = recorderReducerNew.actions;
  const { setShowCoordinatesModal } = fastEventReducer.actions;
  const editedEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.editedEvents,
  );
  const { setPlayerSkipped } = recorderReducerNew.actions;
  // const activeTask = useSelector(
  //   (state: AppStateType) => state.taskReducer.activeTask,
  // );
  const { addFastEventAction, removeFastEventAction } =
    fastEventReducer.actions;
  // const activeEvents = useSelector(
  //   (state: AppStateType) => state.recorderReducerNew.activeEvents,
  // );
  const videoLength = useSelector(
    (state: AppStateType) => state.videoReducer.videoLength,
  );
  const detectFastEventFromEvent = async (
    eventId: number,
    trackId: string | number,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (activeTask) {
      const fast_events: FastEventType[] = activeTask.task_fast_events.filter(
        (fe: FastEventType) => fe.event_id === eventId,
      );
      if (fast_events.length === 1) {
        dispatch(addFastEventAction(fast_events[0]));
        await dispatch(setShowCoordinatesModal(true));
      } else {
        const track: TrackType = activeEvents
          .filter((evt: ActiveEventType) => evt.id === eventId)[0]
          .tracks.filter((tr: TrackType) => tr.id === trackId)[0];
        const fast_event: FastEventType[] = activeTask.task_fast_events.filter(
          (fe: FastEventType) => fe.id === track.fast_event,
        )[0];
        await dispatch(setShowCoordinatesModal(true));
        return dispatch(addFastEventAction(fast_event));
      }
    }
  };
  const getStart = () => {
    return parentWidth * (segment.start / videoLength);
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const showEditModals = async (e: any) => {
    e.preventDefault();
    handleVideoScroll(segment.start * 1000);
    if (!segment.isRecorded && segment.id !== null) {
      const currentEditedEvents: any = {};
      currentEditedEvents[segment.eventId] = segment.id;
      if (editedEvents.length > 0) {
        const createdFastEvent = {
          ...editedEvents[0].track,
          event_id: editedEvents[0].id,
          eventId: editedEvents[0].id,
          event_name: editedEvents[0].name,
          eventName: editedEvents[0].name,
        };
        dispatch(removeFastEventAction());
        dispatch(UnselectTrackFoEditAction(editedEvents[0].track.id));
        dispatch(RemoveEditedEventAction(createdFastEvent, undefined));
        dispatch(setPlayerSkipped(false));
      }

      await detectFastEventFromEvent(segment.eventId, segment.id);
      await dispatch(
        selectTrackForEdit({ eventId: segment.eventId, trackId: segment.id }),
      );
      // TODO
      //await dispatch(SetSelectedEvent(segment));
      dispatch(SetIsPlaying(false));
    }
  };

  const getSegmentStyle = () => {
    const width = parentWidth * ((segment.stop - segment.start) / videoLength);
    const style: any = {
      width: width,
    };
    if (width === 0) {
      style.position = 'relative';
      style.left = -4;
    }
    return style;
  };
  return (
    <>
      <div
        id={segment.id}
        data-id={segment.id}
        className="segment newSegmentTrack"
        style={{
          left: getStart(),
          display: 'flex',
          position: 'absolute',
        }}
        onClick={(e) => showEditModals(e)}
      >
        <div style={getSegmentStyle()} data-id={segment.id}>
          {segment.isZeroLength || segment.stop - segment.start < 0.1 ? (
            <div className="rhombus" data-id={segment.id}></div>
          ) : null}
        </div>
      </div>
    </>
  );
};
