import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  currentFastEvent: any;
  showCoordinatesModal: boolean;
};
const initialState: InitialStateType = {
  currentFastEvent: {},
  showCoordinatesModal: false,
};
export const fastEventReducer = createSlice({
  name: 'fastEventReducer',
  initialState: initialState,
  reducers: {
    addFastEventAction(state, action) {
      state.currentFastEvent = action.payload;
    },
    removeFastEventAction(state) {
      state.currentFastEvent = {};
    },
    setShowCoordinatesModal(state, action) {
      state.showCoordinatesModal = action.payload;
    },
  },
});
