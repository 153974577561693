import { ThunkAction } from 'redux-thunk';

import { getCurrentEvents } from 'api';
import { rawActualEventsSlice } from 'store/raw/slices/rawActualEventsSlice';
import { AppStateType } from 'store/reducers';

const { setActualEvents } = rawActualEventsSlice.actions;

export const GetActualEvents = (
  taskId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      const response = await getCurrentEvents(taskId);
      dispatch(setActualEvents(response));
    } catch (err) {
      console.log(err);
    }
  };
};
