import { createSlice } from '@reduxjs/toolkit';

import { State } from 'types/app';

const initialState: InitialStateType = {
  state: State.init,
  selectedEvent: {},
  events: [],
};

type InitialStateType = {
  state: State;
  selectedEvent: any; // TODO wright types
  events: [any] | []; // TODO wright types
};

export const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState: initialState,
  reducers: {
    setEventsState(state, action) {
      state.state = action.payload;
    },
    setEvents(state, action) {
      state.events = action.payload;
    },
    setSelectedEvent(state, action) {
      state.selectedEvent = action.payload;
    },
  },
});
