import React, { FC, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';

import { PossessionSegment } from './PossessionSegment';

interface PossessionRowInterface {
  handleVideoScroll: (ms: number) => void;
  horizontalScroll: any;
  zoomValue: any;
}

export const PossessionRow: FC<PossessionRowInterface> = ({
  handleVideoScroll,
  horizontalScroll,
  zoomValue,
}) => {
  const ref = useRef<any>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.style.left = `-${horizontalScroll}px`;
  }, [horizontalScroll]);
  const { event, possessions, activePossession } = useSelector(
    (state: AppStateType) => state.possessionReducer,
  );
  const videoLength = useSelector(
    (state: AppStateType) => state.taskReducer.activeTask?.video_length,
  );
  if (!videoLength) {
    return <></>;
  }
  const sortedPossessions = [...possessions].sort((a, b) => {
    if (a.stop > b.stop) {
      return 1;
    }
    if (a.stop < b.stop) {
      return -1;
    }
    return 0;
  });
  return (
    <div className={'eventRecorderHolder'}>
      <div className={'eventTrackName'}>
        {event.label ? event.label : event.name}
      </div>
      <div className="event-track-container">
        <div
          ref={ref}
          className="segments-container"
          style={{
            width: `${100 * (1 + zoomValue / 100)}%`,
          }}
        >
          {sortedPossessions.map((track: any, index) => {
            return (
              <PossessionSegment
                event={event}
                key={`${track.id}`}
                parent={ref}
                videoLength={videoLength}
                track={track}
                handleVideoScroll={handleVideoScroll}
                isActive={track.id == activePossession?.track.id}
                prevTrack={sortedPossessions[index - 1]}
                nextTrack={sortedPossessions[index + 1]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
