import React, { FC, useState } from 'react';

import { useSelector } from 'react-redux';

import { ScrollingButtons } from 'components/OperatorEditor/OperatorEditorVideoTimeline/ControllsContainer/ScrollingButtons';
import EventsViewSwitcher from 'components/OperatorEditor/OperatorEditorVideoTimeline/EventsViewSwitcher';
import { AttributesReorderModal } from 'components/OperatorEditor/ReorderModal';
import { ZoomerSlider } from 'components/ui';
import pauseIcon from 'resources/img/pauseIcon.svg';
import playIcon from 'resources/img/playIcon.svg';
import TwoArrowsVerticalIcon from 'resources/img/twoArrowsVerticalIcon.svg';
import { AppStateType } from 'store/reducers';
import { formattedTime } from 'utils/functions';
import './VideoControlls.css';

interface VideoControlsInterface {
  currentPosition: number | undefined;
  handleVideoScroll: (e: any) => void;
  videoEnd: number | undefined;
  toggleVideoSpeed: (value: number) => void;
  handlePlayClick: () => void;
  zoomValue: number;
  setZoomValue: (n: number) => void;
  playbackRate: number;
  frameRate: number;
}

export const VideoControls: FC<VideoControlsInterface> = ({
  currentPosition,
  handleVideoScroll,
  videoEnd,
  toggleVideoSpeed,
  handlePlayClick,
  zoomValue,
  setZoomValue,
  playbackRate,
  frameRate,
}) => {
  const isPlaying = useSelector(
    (state: AppStateType) => state.videoReducer.isPlaying,
  );
  const ICON: any = {
    1: pauseIcon,
    0: playIcon,
  };

  const handleZoom = (newValue: string) => {
    setZoomValue(Number(newValue));
  };
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const [reorderModalIsOpen, setReorderModalIsOpen] = useState<boolean>(false);
  return (
    <div className="video-player-buttons-wrapper">
      <ScrollingButtons
        currentPosition={currentPosition}
        handleVideoScroll={handleVideoScroll}
        videoEnd={videoEnd}
        toggleVideoSpeed={toggleVideoSpeed}
        playbackRate={playbackRate}
        frameRate={frameRate}
      />
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            src={ICON[Number(isPlaying)]}
            className={'videoControlButton'}
            alt={'play button'}
            onClick={() => handlePlayClick()}
          />
          <div style={{ display: 'flex' }}>
            <div style={{ width: 95 }}>
              {currentPosition !== undefined && formattedTime(currentPosition)}
            </div>
            &nbsp;/&nbsp;
            <span style={{ color: '#777777', fontSize: '14px' }}>
              {formattedTime(videoEnd)}
            </span>
          </div>
        </div>
      </div>

      <AttributesReorderModal
        setIsModalOpen={setReorderModalIsOpen}
        isModalOpen={reorderModalIsOpen}
      />
      <div
        style={{
          paddingRight: '2rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: '1.5rem' }}>
          <img
            src={TwoArrowsVerticalIcon}
            onClick={() => setReorderModalIsOpen(true)}
            className={'videoControlButton'}
          />
        </div>
        <ZoomerSlider
          max={activeTask?.max_zoom || 100}
          zoomValue={zoomValue}
          onChangeHandler={handleZoom}
        />
        <EventsViewSwitcher />
      </div>
    </div>
  );
};
