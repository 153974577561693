import React, { FC } from 'react';

import { Divider } from 'antd';

import { HotkeysList } from './HotkeysList';

export const OperatorSettingsHotkeys: FC<any> = ({ hotkeysGroupped }) => {
  return (
    <>
      {hotkeysGroupped &&
        Object.keys(hotkeysGroupped)?.map((id: any) => (
          <div key={id}>
            <HotkeysList group={hotkeysGroupped[id]} />
            <Divider />
          </div>
        ))}
    </>
  );
};
