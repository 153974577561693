import React, { FC } from 'react';

import { Button, ConfigProvider } from 'antd';

import './style.css';

interface EventButtonInterface {
  handleClick: any;
  content: string;
  id: string;
  hotkey?: string;
  disabled?: boolean;
  active?: boolean;
  isBlocked?: boolean;
  type: string;
}

export const EventButton: FC<EventButtonInterface> = ({
  handleClick,
  content,
  id, // required for HotkeyManager
  hotkey = undefined,
  disabled = false,
  active = false,
  isBlocked = false,
  type = 'fastEvent',
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: active ? '#000' : '#F5F5F5',
            defaultColor: active ? '#fff' : '#000',
            contentFontSizeLG: 14,
            paddingBlockLG: type === 'fastEvent' ? 16 : 8,
            controlHeightLG: type === 'fastEvent' ? 60 : 40,
            colorBgContainerDisabled: '#fff',
          },
        },
      }}
    >
      <Button
        id={id}
        disabled={disabled}
        block
        size="large"
        onClick={handleClick}
        className={`${isBlocked ? 'blocked' : ''}`}
        style={{
          whiteSpace: 'wrap',
          padding: '0px 15px',
        }}
      >
        {content}
        {hotkey && <div className="hotkey">{hotkey}</div>}
      </Button>
    </ConfigProvider>
  );
};

export default EventButton;
