import { useDispatch } from 'react-redux';
import { ThunkAction } from 'redux-thunk';

import { login } from 'api';
import { AppStateType } from 'store/reducers';
import { authReducer } from 'store/reducers/auth.reducer';

const {
  loginRequestAction,
  loginSuccessAction,
  loginErrorAction,
  logoutAction,
} = authReducer.actions;

export const LogoutAction = () => {
  localStorage.removeItem('authtoken');
  localStorage.removeItem('redirectTo');
  localStorage.removeItem('refreshToken');
  const dispatch = useDispatch();
  dispatch(logoutAction());
};

export const LoginAction = (
  email: string,
  password: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      dispatch(loginRequestAction());
      const response = await login(email, password);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      typeof window !== 'undefined' &&
        window.localStorage &&
        localStorage.setItem('authtoken', response.access);
      localStorage.setItem('redirectTo', response.redirect_to);
      localStorage.setItem('refreshToken', response.refresh);
      dispatch(
        loginSuccessAction({
          token: response.access,
          redirectTo: response.redirect_to,
        }),
      );
    } catch (err) {
      dispatch(loginErrorAction(err));
      console.log(err);
    }
  };
};
