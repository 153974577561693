import React, { FC } from 'react';

// import { Resizable } from 're-resizable';
import { useDispatch, useSelector } from 'react-redux';

import PlayerWrapper from 'components/OperatorEditor/OperatorEditorVideoPlayer/PlayerWrapper/PlayerWrapper';
import { VideoErrorAction } from 'store/actions/task.actions';
import { AppStateType } from 'store/reducers';

export const OperatorEditorVideoPlayer: FC<any> = ({
  refPlayer,
  playbackRate,
  videoLink,
  keyDownHandler,
}) => {
  const playedFrame = useSelector(
    (state: AppStateType) => state.recorderReducer.playedFrame,
  ); // Редактируемые/записываемые в настоящий момент события
  const isPlaying = useSelector(
    (state: AppStateType) => state.videoReducer.isPlaying,
  ); // Редактируемые/записываемые в настоящий момент события
  const dispatch = useDispatch();

  const handleError = (error: any) => {
    if (error?.message?.includes('The play() request was interrupted')) {
      dispatch(VideoErrorAction('Видео загружается, пожалуйста, подождите'));
    } else {
      dispatch(VideoErrorAction('Ошибка получения видео'));
    }
  };
  const initialHeight = 792;

  // const [resizableHeight, setResizableHeight] = useState<number>(initialHeight);

  // const resizableWidth = (window.innerWidth / 24) * videoWidthColSpan;
  return (
    // <Resizable
    //   defaultSize={{
    //     width: resizableWidth,
    //     height: resizableHeight,
    //   }}
    //   onResize={(e, direction, ref) => {
    //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    //     direction === 'bottom' && setResizableHeight(() => ref.clientHeight);
    //   }}
    //   minHeight={792}
    // >
    <PlayerWrapper
      keyDownHandler={keyDownHandler}
      refPlayer={refPlayer}
      resizableHeight={initialHeight}
      url={videoLink}
      playbackRate={playbackRate}
      isPlaying={isPlaying}
      handleError={handleError}
      playedFrame={playedFrame}
    />
    // </Resizable>
  );
};
