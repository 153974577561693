import { createSlice } from '@reduxjs/toolkit';

import { HARDCODED_COLOR } from 'constants/constants';
import { HotkeyType, PlayerSettingsType } from 'types';

type PlayerInitialStateType = {
  playerSettings: PlayerSettingsType;
  playerSettingsError: null | string;
  hotkeysForm: Array<HotkeyType>;
  invertedTeamDisplay: boolean;
  hotkeyFormSaving: boolean;
  hotkeysIsDisplayed: boolean;
  isHotkeysManagerListens: boolean;
  urlToBackgroundImage: string;
};
const playerInitialState: PlayerInitialStateType = {
  playerSettings: {
    play_with_lead: [],
    hotkeys: [],
    color_settings: {
      Home: HARDCODED_COLOR[0],
      HomeInvertText: false, // Нейминг не трогать, он важен
      Away: HARDCODED_COLOR[1],
      AwayInvertText: false, // Нейминг не трогать, он важен
    },
  },
  playerSettingsError: null,
  hotkeysForm: [],
  hotkeyFormSaving: false,
  invertedTeamDisplay: false,
  hotkeysIsDisplayed: true, // TODO false after develop
  isHotkeysManagerListens: true,
  urlToBackgroundImage: '',
};
export const playerReducer = createSlice({
  name: 'playerReducer',
  initialState: playerInitialState,
  reducers: {
    setHotkeysFormAction(state, action) {
      state.hotkeysForm = action.payload;
    },
    setHotkeyError(state, action) {
      const hk = state.hotkeysForm?.filter(
        (hKey: HotkeyType) => hKey.id === action.payload.id,
      )[0];
      if (hk) {
        hk.error = action.payload.error;
      }
    },
    clearErrors(state) {
      if (state.hotkeysForm) {
        for (const hk of state.hotkeysForm) {
          if (hk.error) {
            hk.error = null;
          }
        }
      }
    },
    updateHotkeyForm(state, action) {
      const { id, keyCode, label } = action.payload;
      const hotKey = state.hotkeysForm?.filter(
        (hk: HotkeyType) => hk.id === id,
      )[0];
      const hotKeys = state.hotkeysForm?.filter(
        (hk: HotkeyType) => hk.label === label,
      );
      if (hotKey) {
        hotKey.key = keyCode == null ? null : '' + keyCode;
      }
      // TODO bad fix =(
      if (hotKeys.length > 1) {
        hotKeys.forEach((hk) => {
          hk.key = keyCode == null ? null : '' + keyCode;
        });
      }
    },
    updateHotkeysFormRepeatErrors(state, action) {
      const repeatedHotkeys = action.payload;
      state.hotkeysForm?.map((hk) => {
        const isRepeated =
          repeatedHotkeys.filter((rhk: any) => rhk.id == hk.id).length > 0;
        if (isRepeated) {
          hk.error = 'duplicate';
        } else {
          delete hk.error;
        }
        return hk;
      });
    },
    setHotkeyFormSaving(state, action) {
      state.hotkeyFormSaving = action.payload;
    },
    setPlayerSettingsAction(state, action) {
      const newSettings = action.payload;
      if (action.payload.color_settings == null) {
        action.payload.color_settings =
          playerInitialState.playerSettings.color_settings;
      }
      state.playerSettings = newSettings;
      state.playerSettingsError = null;
    },

    setColorSettings(state, action) {
      const newColors = { ...state.playerSettings.color_settings };
      newColors[action.payload.key] = action.payload.value;
      state.playerSettings.color_settings = newColors;
      state.playerSettingsError = null;
    },
    setHotkeysSettingsAction(state, action) {
      if (
        state.playerSettings !== null &&
        state.playerSettings.hotkeys.length
      ) {
        state.playerSettings.hotkeys = action.payload;
      }
      state.playerSettingsError = null;
    },
    setPlayerSettingsError(state, action) {
      state.playerSettingsError = action.payload;
    },
    setInverseTeamDirection(state, action) {
      state.invertedTeamDisplay = action.payload;
    },
    setHotkeysDisplay(state, action) {
      state.hotkeysIsDisplayed = action.payload;
    },
    setIsHotkeysManagerListens(state, action) {
      state.isHotkeysManagerListens = action.payload;
    },
    setUrlToBackgroundImage(state, action) {
      state.urlToBackgroundImage = action.payload;
    },
  },
});
