import React, { FC } from 'react';

import { ConfigProvider, Tag } from 'antd';

export const PlayerBreadcrumbTag: FC<any> = ({
  value,
  color,
  invertedText,
  clickCallback,
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadiusSM: 30,
          lineHeight: 2,
        },
      }}
    >
      <Tag
        style={{ padding: '1.5px 5px', color: invertedText ? '#fff' : '#000' }}
        className="enabledClickable"
        color={color}
        onClick={clickCallback}
      >
        {value}
      </Tag>
    </ConfigProvider>
  );
};
