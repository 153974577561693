import React, { FC, useEffect, useRef } from 'react';

import './style.scss.css';

import { TOTAL_COL_BY_ANT, VIDEO_WIDTH_COL_SPAN } from 'constants/constants';

import { ScrollerV2 } from './ScrollerV2';
import { VideoControls } from './VideoControls';

interface ControlsInterface {
  isPlaying: boolean;
  handlePlayClick: () => void;
  videoStart: number;
  videoEnd: number | undefined;
  currentPosition: number | undefined;
  handleVideoScroll: (e: any) => void;
  zoomValue: number;
  setZoomValue: (n: number) => void;
  toggleVideoSpeed: (value: number) => void;
  horizontalScroll: any;
  playbackRate: number;
  frameRate: number;
  progressRef: any;
}

export const ControlsContainer: FC<ControlsInterface> = ({
  // isPlaying,
  handlePlayClick,
  // videoStart,
  videoEnd,
  currentPosition,
  handleVideoScroll,
  zoomValue,
  setZoomValue,
  toggleVideoSpeed,
  horizontalScroll,
  playbackRate,
  frameRate,
}) => {
  const progressContainerRef = useRef<any>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleDrag = (e: any, data: any) => {
    // Обработка drag ползунка видео
    const baseParent = e.target.parentNode;
    const parent =
      e.target.id === 'viewedProgressBar' ? baseParent.parentNode : baseParent;
    // TODO  переделать слайдер
    const x =
      e.clientX -
      horizontalScroll -
      (window.innerWidth / 24) * (TOTAL_COL_BY_ANT - VIDEO_WIDTH_COL_SPAN);
    const parentWidth = parent.clientWidth;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setNewVideoValue(x, parentWidth);
  };
  const setNewVideoValue = (x: number, parentWidth: number) => {
    // Расчет и подача команды на перемотку видео
    if (videoEnd) {
      const ratio = (x / parentWidth) * videoEnd || 0;
      const newValue = ratio * 1000;
      handleVideoScroll(newValue);
    }
  };

  useEffect(() => {
    // console.log(horizontalScroll)
    progressContainerRef.current.style.left = `-${horizontalScroll}px`;
  }, [horizontalScroll]);

  const getStyle = () => {
    const style: any = {}; //{marginLeft: "1rem", marginRight: "1rem"}
    if (zoomValue > -10) {
      // style.width = `${100 * (1 + zoomValue / 100)}%`;
      const segments = document.getElementsByClassName('segments-container')[0];
      if (segments) {
        style.width =
          document.getElementsByClassName('segments-container')[0]?.clientWidth;
      } else {
        style.width = `${100 * (1 + zoomValue / 100)}%`;
      }
    }
    return style;
  };

  return (
    <>
      <div
        className="video-player-controls-wrapper"
        style={{
          position: 'relative',
          backgroundColor: 'white',
          marginLeft: '1rem',
          marginRight: '1rem',
        }}
      >
        <div
          style={{
            position: 'relative',
          }}
          ref={progressContainerRef}
        >
          <div style={getStyle()}>
            <div style={{ position: 'relative', marginTop: 20 }}>
              <ScrollerV2
                // TODO Dead code? handleDrag={handleDrag}
                zoomValue={zoomValue}
                videoLength={videoEnd}
                value={currentPosition}
                handleVideoScroll={handleVideoScroll}
              />
            </div>
          </div>
        </div>
      </div>
      <VideoControls
        currentPosition={currentPosition}
        handleVideoScroll={handleVideoScroll}
        videoEnd={videoEnd}
        toggleVideoSpeed={toggleVideoSpeed}
        handlePlayClick={handlePlayClick}
        zoomValue={zoomValue}
        setZoomValue={setZoomValue}
        playbackRate={playbackRate}
        frameRate={frameRate}
      />
    </>
  );
};
