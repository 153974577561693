import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import CloseCrossIcon from 'resources/img/faCloseCross.svg';
import { AppStateType } from 'store/reducers';
import { protocolReducer } from 'store/reducers/protocol.reducer';
import { getScore } from 'utils/functions';
import './index.css';

const Title = () => {
  const { isOpen } = useSelector(
    (state: AppStateType) => state.protocolReducer,
  );
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const dispatch = useDispatch();
  const { toggleGameProtocol } = protocolReducer.actions;
  const onClose = () => {
    dispatch(toggleGameProtocol(!isOpen));
  };
  const score = getScore(activeEvents);
  return (
    <div className="game-protocol-title">
      <img
        src={CloseCrossIcon}
        className="enabledClickable"
        onClick={onClose}
        style={{ position: 'absolute', top: 16, right: 16 }}
      />
      <div className="game-protocol-team">
        <div className="game-protocol-team-name">
          {activeTask?.home_team_name}
        </div>
      </div>
      <div className="game-protocol-details">
        {/* <div className="date">{gameProtocol.date}</div> */}
        <div className="protocol-score">
          <div style={{ flex: 1, textAlign: 'right' }}>
            {score.split(':')[0]}
          </div>
          :<div style={{ flex: 1 }}>{score.split(':')[1]}</div>
        </div>
      </div>
      <div className="game-protocol-team">
        {/* <img
            className="game-protocol-logo"
            src={gameProtocol.awayTeam.logo}
            alt=""
          /> */}
        <div className="game-protocol-team-name">
          {activeTask?.away_team_name}
        </div>
      </div>
    </div>
  );
};
export default Title;
