/* eslint-disable indent */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
// eslint-disable-next-line import/no-extraneous-dependencies
import throttle from 'lodash.throttle';
import { useDispatch, useSelector } from 'react-redux';

import {
  SPORTS,
  SPORTS_IMAGE_ASPECT_RATIO,
  filePath,
} from 'constants/constants';
import { RemoveEditedEventAction } from 'store/actions/recorder.actions';
import { SetIsPlaying } from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { EventCoordinate } from 'types';
import {
  findLastModifiedPlayerNumber,
  getCurrentEditedEvent,
} from 'utils/functions';
import './style.css';

export const SelectCoordinateModalContent: FC<any> = ({
  opacity,
  playerSize,
  playerOpacity,
}) => {
  const activeTask = useSelector(
    (state: AppStateType) => state.taskReducer.activeTask,
  );
  const showCoordinatesModal = useSelector(
    (state: AppStateType) => state.fastEventReducer.showCoordinatesModal,
  );
  const currentFastEvent = useSelector(
    (state: AppStateType) => state.fastEventReducer.currentFastEvent,
  );
  const editedEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.editedEvents,
  );
  const { homeActivePlacement, awayActivePlacement } = useSelector(
    (state: AppStateType) => state.gamePlayersReducer,
  );
  const activeEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.activeEvents,
  );
  const task = useSelector((state: AppStateType) => state.rawTaskReducer.task);
  let style = '';
  if (SPORTS_IMAGE_ASPECT_RATIO[task?.sport?.name.toLowerCase()]) {
    style =
      SPORTS_IMAGE_ASPECT_RATIO[task?.sport?.name.toLowerCase()].aspectRatio;
  }
  const {
    playerSettings: { color_settings = {} },
    invertedTeamDisplay,
    urlToBackgroundImage,
  } = useSelector((state: AppStateType) => state.playerReducer);
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.poorPerformancePlayedSec,
  );
  const selectedHomeColor = color_settings?.Home;
  const { HomeInvertText = false, AwayInvertText = false } = color_settings;
  const selectedAwayColor = color_settings?.Away;
  const fieldRef = useRef<any>(null);
  const [eventCoordinate, setEventCoordinate] = useState<EventCoordinate>({
    x: null,
    y: null,
  });
  const [left, setLeft] = useState('0px');
  const [top, setTop] = useState('0px');
  const [prevEvent, setPrevEvent] = useState<any>(undefined);
  const [homeSubtitutions, setHomeSubtitutions] = useState([]);
  const [awaySubtitutions, setAwaySubtitutions] = useState([]);
  const [showAim, setShowAim] = useState(false);
  const [aim, setAim] = useState<{ x: any; y: any }>({
    x: undefined,
    y: undefined,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //const [activeEvent, setActiveEvent] = useState<FastEventType | null>(null);
  useEffect(() => {
    const func = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const edited = editedEvents.filter((evt) => evt.name !== 'Possession')[0];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // activeTask &&
      //   (await setActiveEvent({
      //     ...activeTask?.task_fast_events?.filter(
      //       (evt: FastEventType) => evt?.id === edited?.track.fast_event,
      //     )[0],
      //   }));
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (
        edited &&
        showCoordinatesModal &&
        edited?.track.coord_y &&
        edited?.track.coord_x &&
        fieldRef
      ) {
        await setEventCoordinate({
          x: edited?.track.coord_x,
          y: edited?.track.coord_y,
        });
        await setTop(
          `${fieldRef?.current?.getBoundingClientRect().height * edited?.track?.coord_y - 4}px`,
        );
        await setLeft(
          `${fieldRef?.current?.getBoundingClientRect().width * edited?.track.coord_x - 4}px`,
        );
      }
    };
    func().then();
  }, [editedEvents, showCoordinatesModal, fieldRef]);
  const { setEventCoordinatesAction, setPlayerSkipped } =
    recorderReducerNew.actions;
  const { setShowCoordinatesModal, removeFastEventAction } =
    fastEventReducer.actions;

  const dispatch = useDispatch();
  const setCoordinate = async (event: any) => {
    if (
      fieldRef.current &&
      //@ts-ignore
      fieldRef?.current?.contains(event.target) &&
      editedEvents.length > 0 &&
      currentFastEvent &&
      currentFastEvent.has_coords
    ) {
      //@ts-ignore
      const X =
        event.offsetX / fieldRef?.current?.getBoundingClientRect().width;
      //@ts-ignore
      const Y =
        event.offsetY / fieldRef?.current?.getBoundingClientRect().height;
      const currentEditedEvent = getCurrentEditedEvent(
        editedEvents,
        currentFastEvent,
      );
      await setEventCoordinate({ x: X, y: Y });
      await dispatch(
        setEventCoordinatesAction({
          eventId: currentEditedEvent.id,
          trackId: currentEditedEvent.track.id,
          coord_x: X,
          coord_y: Y,
        }),
      );
      await dispatch(RemoveEditedEventAction(currentFastEvent, playedSec));
    }
    dispatch(setShowCoordinatesModal(false));
    dispatch(SetIsPlaying(true));
    dispatch(removeFastEventAction());
    dispatch(setPlayerSkipped(false));
    document.getElementById('HotkeysManager')?.focus();
  };
  const clearCoordinate = async (event: any) => {
    event.preventDefault();
    // console.log('right click')
    if (
      // eslint-disable-next-line sonarjs/no-identical-expressions
      fieldRef.current &&
      fieldRef.current &&
      //@ts-ignore
      fieldRef?.current?.contains(event.target) &&
      editedEvents.length > 0
      // && activeEvent
      // && activeEvent.has_coordinates
    ) {
      setEventCoordinate({ x: null, y: null });
      const currentEditedEvent = getCurrentEditedEvent(
        editedEvents,
        currentFastEvent,
      );
      await dispatch(
        setEventCoordinatesAction({
          eventId: currentEditedEvent.id,
          trackId: currentEditedEvent.track.id,
          coord_x: null,
          coord_y: null,
        }),
      );
    }
  };
  // const mouseMoveHandler = (ev: any) => {
  //   console.log(ev);
  //   console.log(throttle);
  // };
  const throttleDraw = useCallback(
    throttle((ev: any) => {
      const rect = ev.target.getBoundingClientRect();
      const x = ev.clientX - rect.left;
      const y = ev.clientY - rect.top;
      setAim({ x, y });
    }, 60),
    [],
  );
  const mouseMoveHandler = (ev: any) => {
    throttleDraw(ev);
  };

  const [hasListener, setHasListener] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    !hasListener &&
      fieldRef?.current?.addEventListener('click', setCoordinate) &&
      setHasListener(true);
    //@ts-ignore
    fieldRef?.current?.addEventListener('contextmenu', clearCoordinate);
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      fieldRef?.current?.removeEventListener('click', setCoordinate) &&
        setHasListener(false);
      //@ts-ignore
      fieldRef?.current?.removeEventListener('contextmenu', clearCoordinate);
    };
  }, [setCoordinate, clearCoordinate]);
  useEffect(() => {
    const [Substitution = {}] = activeEvents.filter(
      (ae) => ae.name.toLowerCase() === 'substitution',
    );
    //@ts-expect-error
    const { tracks = [] } = Substitution;
    if (tracks.length > 0) {
      const actualTracks = tracks
        .filter((tr: any) => tr.stop <= playedSec)
        .sort((a: any, b: any) => a.stop - b.stop);
      const homeSubtitution: any = [];
      const awaySubtitution: any = [];
      actualTracks.forEach((track: any) => {
        let team;
        let playerIn;
        let playerOut;
        track.attributes.forEach((attr: any) => {
          if (attr.key == 'team') {
            team = attr.values[0]?.value?.toLowerCase();
          }
          if (attr.key == 'player_in') {
            playerIn = attr.values[0]?.value;
          }
          if (attr.key == 'player_out') {
            playerOut = attr.values[0]?.value;
          }
        });
        const subtitution = {
          in: playerIn,
          out: playerOut,
          start: track.start,
          stop: track.stop,
        };
        if (team == 'home') {
          homeSubtitution.push(subtitution);
          return;
        }
        if (team == 'away') {
          awaySubtitution.push(subtitution);
        }
      });
      setHomeSubtitutions(homeSubtitution);
      setAwaySubtitutions(awaySubtitution);
    }
  }, [activeEvents, playedSec]);
  useEffect(() => {
    if (!showCoordinatesModal) {
      setPrevEvent(undefined);
      return;
    }
    let nearestEvent: any = undefined;
    const activeEvent = getCurrentEditedEvent(editedEvents, currentFastEvent);
    if (!activeEvent) {
      setPrevEvent(undefined);
      return;
    }
    activeEvents.forEach((evGr) => {
      evGr.tracks.forEach((track) => {
        if (
          track.coord_x &&
          track.stop &&
          track.stop <= playedSec &&
          track.id != activeEvent.track.id &&
          (!nearestEvent || nearestEvent.stop <= track.stop)
        ) {
          nearestEvent = track;
        }
      });
    });
    if (!nearestEvent) {
      setPrevEvent(undefined);
    }
    setPrevEvent(nearestEvent);
  }, [editedEvents, activeEvents, showCoordinatesModal, playedSec]);
  return (
    <div
      className={`coordinatesModalContainer ${activeTask ? SPORTS[activeTask.sport.id] : ''}`}
      ref={fieldRef}
      style={{ fontSize: `${playerSize}px`, aspectRatio: style }}
      onMouseMove={mouseMoveHandler}
      onMouseLeave={() => setShowAim(false)}
      onMouseEnter={() => setShowAim(true)}
    >
      {aim.x && showAim && (
        <div className="aimHorisontal" style={{ top: `${aim.y}px` }}></div>
      )}
      {aim.y && showAim && (
        <div className="aimVertical" style={{ left: `${aim.x}px` }}></div>
      )}
      {prevEvent && (
        <div
          className="prevPoint"
          style={{
            left: `${prevEvent.coord_x * 100}%`,
            top: `${prevEvent.coord_y * 100}%`,
          }}
        ></div>
      )}
      <img
        style={{
          position: 'absolute',
          zIndex: 10,
          opacity: opacity,
          height: '100%',
        }}
        src={`${filePath}/files/fields/b_${urlToBackgroundImage}`}
        alt={''}
      />
      {eventCoordinate &&
        eventCoordinate.x !== null &&
        eventCoordinate.y !== null &&
        eventCoordinate.x !== undefined &&
        eventCoordinate.y !== undefined && (
          <svg
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 15,
              top: top,
              left: left,
            }}
            height="8"
            width="8"
          >
            {' '}
            <circle cx="4" cy="4" r="4" fill="red" />
          </svg>
        )}
      {homeActivePlacement?.positions &&
        homeActivePlacement.positions.map((pos: any) => (
          <div
            key={pos.id}
            className={clsx(
              'playerOnMapBlob',
              invertedTeamDisplay && 'invertedHomeDisplay',
            )}
            style={{
              top: invertedTeamDisplay
                ? 'initial'
                : `${Math.floor(pos.coordY * 100)}%`,
              bottom: invertedTeamDisplay
                ? `${Math.floor(pos.coordY * 100)}%`
                : 'initial',
              left: invertedTeamDisplay
                ? 'initial'
                : `${Math.floor(pos.coordX * 100)}%`,
              right: invertedTeamDisplay
                ? `${Math.floor(pos.coordX * 100)}%`
                : 'initial',
              color: HomeInvertText ? '#fff' : '#000',
              backgroundColor: selectedHomeColor,
              opacity: playerOpacity,
            }}
          >
            {findLastModifiedPlayerNumber(homeSubtitutions, pos.player_number)}
          </div>
        ))}
      {awayActivePlacement?.positions &&
        awayActivePlacement.positions.map((pos: any) => (
          <div
            key={pos.id}
            className={clsx(
              'playerOnMapBlob',
              'awayTeam',
              invertedTeamDisplay && 'invertedAwayDisplay',
            )}
            style={{
              top: invertedTeamDisplay
                ? 'initial'
                : `${Math.floor(pos.coordY * 100)}%`,
              bottom: invertedTeamDisplay
                ? `${Math.floor(pos.coordY * 100)}%`
                : 'initial',
              right: invertedTeamDisplay
                ? 'initial'
                : `${Math.floor(pos.coordX * 100)}%`,
              left: invertedTeamDisplay
                ? `${Math.floor(pos.coordX * 100)}%`
                : 'initial',
              color: AwayInvertText ? '#fff' : '#000',
              backgroundColor: selectedAwayColor,
              opacity: playerOpacity,
            }}
          >
            {findLastModifiedPlayerNumber(awaySubtitutions, pos.player_number)}
          </div>
        ))}
    </div>
  );
};
