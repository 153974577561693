import React, { FC, useEffect, useState } from 'react';

import { Row } from 'antd';
import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import { AdditionalValueType } from 'types';

import EventsOptionsListItem from './EventsOptionsListItem';

const EventsOptionsList: FC<any> = ({ showHotKey }) => {
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { currentFastEvent } = useSelector(
    (state: AppStateType) => state.fastEventReducer,
  );
  const [grouppedAdditionalValues, setGrouppedAdditionalValues] = useState([]);
  const [duplicateNames, setDuplicateNames] = useState<any[]>([]);
  const [duplicates, setDuplicates] = useState<any[]>([]);
  useEffect(() => {
    const unique: any = [];
    const existing: string[] = [];
    const localDuplicatesNames: string[] = [];
    for (const value of activeTask?.additional_values || []) {
      if (!existing.includes(`${value.name} ${value.value}`)) {
        existing.push(`${value.name} ${value.value}`);
        unique.push(value);
      } else {
        localDuplicatesNames.push(`${value.name} ${value.value}`);
      }
    }
    const localDuplicates =
      activeTask?.additional_values?.filter((val: any) =>
        localDuplicatesNames.includes(`${val.name} ${val.value}`),
      ) || [];
    setDuplicateNames(localDuplicatesNames);
    setDuplicates(localDuplicates);
    setGrouppedAdditionalValues(
      unique.sort(
        (a: AdditionalValueType, b: AdditionalValueType) =>
          a.ordering - b.ordering,
      ),
    );
  }, [activeTask]);
  return (
    <Row gutter={[8, 8]}>
      {grouppedAdditionalValues?.map((evOp: AdditionalValueType, i: number) => (
        <EventsOptionsListItem
          key={i}
          item={evOp}
          showHotKey={showHotKey}
          active={currentFastEvent !== null} // TODO
          duplicateNames={duplicateNames}
          duplicates={duplicates}
        />
      ))}
    </Row>
  );
};

export default EventsOptionsList;
