import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import showPass from 'resources/img/showPass.svg';
import { LoginAction } from 'store/actions/auth.actions';
import { AppStateType } from 'store/reducers';
import './style.css';

const LoginWindowPage: FC<unknown> = () => {
  const { token } = useSelector((state: AppStateType) => state.authReducer);
  const [inputPassType, setInputPassType] = useState('password');
  const toggleInputPassType = () => {
    if (inputPassType === 'password') {
      setInputPassType('text');
    } else {
      setInputPassType('password');
    }
  };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const localToken = localStorage.getItem('authtoken');
    const localRedirectTo = localStorage.getItem('redirectTo');
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    localToken && localRedirectTo ? navigate(localRedirectTo) : null;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    localToken && localRedirectTo
      ? (window.location.href = localRedirectTo)
      : null;
  }, [token]);

  const handleLogin = () => {
    dispatch(LoginAction(email, password));
  };

  return (
    <div className={'container'}>
      <div className={'innerContainer'}>
        <div className="authFormHolder">
          <div className={'loginWindowHeader'}>Авторизация</div>
          <div className={'loginFormContainer'}>
            <form className={'authForm'}>
              <label htmlFor={'login'}>Логин:</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name={'login'}
                type={'text'}
              />
              <label htmlFor={'password'}>Пароль:</label>
              <div>
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name={'password'}
                  type={inputPassType}
                />
                <img
                  src={showPass}
                  className={'passwordImage'}
                  onClick={() => toggleInputPassType()}
                />
              </div>
              <a href={'#'}>Забыли пароль?</a>
              <input
                className={'loginButton'}
                type={'button'}
                value={'Вход'}
                onClick={() => handleLogin()}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginWindowPage;
