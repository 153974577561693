import { ThunkAction } from 'redux-thunk';

import { Task } from 'store/raw/types';
import { AppStateType } from 'store/reducers';
import { appSlice } from 'store/slices/appSlice';
import { periodSlice } from 'store/slices/periodSlice';
import { placementSlice } from 'store/slices/placementSlice';
import { videoSlice } from 'store/slices/videoSlice';

const { setHasError } = appSlice.actions;
const {
  setIsPlaying,
  setVideoFile,
  setVideoFramerate,
  setVideoLength,
  setPlayedSec,
  setLocalVideoObjectURL,
  setPoorPerformancePlayedSec,
} = videoSlice.actions;

const { setCurrentPeriod, setNextPeriod } = periodSlice.actions;

const { setActivePlacement } = placementSlice.actions;

export const SetVideoData = (
  task: Task,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      dispatch(setVideoFile(task.video_file));
      dispatch(setVideoFramerate(task.video_framerate));
      dispatch(setVideoLength(task.video_length));
    } catch (err: any) {
      dispatch(setHasError('video SetVideoData error'));
    }
  };
};

export const SetIsPlaying = (
  state: boolean,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const poorPerformancePlayedSec =
        getState().videoReducer.poorPerformancePlayedSec;
      await dispatch(setPlayedSec(poorPerformancePlayedSec));
      await dispatch(setIsPlaying(state));
    } catch (err: any) {
      dispatch(setHasError('video SetIsPlaying error'));
    }
  };
};
/**
 * Устанавливает текущее время, так же обновляет все зависимые от времени состояния
 * Сделано для оптимизации, что бы не каждый компонент обновлялся каждый тик,
 * а только по событиям. Пока внедрено не везде.
 */
export const SetPlayingSec = (
  sec: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      // Установка текущего времени видео
      dispatch(setPlayedSec(sec));

      // Установка текущего и следующего приода
      const periodGroup = getState().rawActualEventsReducer.actualEvents.find(
        (group) => group.name.toLowerCase() == 'period',
      );
      if (!periodGroup) {
        dispatch(setCurrentPeriod({}));
        dispatch(setNextPeriod({}));
        return;
      }
      let nextPeriod = {};
      const currentPeriod =
        periodGroup.tracks.find((tr, index) => {
          if (tr.start <= sec && tr.stop >= sec) {
            nextPeriod = periodGroup.tracks[index + 1] ?? {};
            return true;
          }
          return false;
        }) ?? {};
      dispatch(setCurrentPeriod(currentPeriod));
      dispatch(setNextPeriod(nextPeriod));

      // Установка текущих растановок
      const curHomePlacements =
        getState().placementReducer.homePlacements.filter(
          (pl) => pl.started_at / 1000 <= sec && pl.finished_at / 1000 >= sec,
        );
      const curAwayPlacements =
        getState().placementReducer.awayPlacements.filter(
          (pl) => pl.started_at / 1000 <= sec && pl.finished_at / 1000 >= sec,
        );
      dispatch(
        setActivePlacement({
          placement: curHomePlacements[curHomePlacements.length - 1] || {},
          teamType: 'home',
        }),
      );
      dispatch(
        setActivePlacement({
          placement: curAwayPlacements[curAwayPlacements.length - 1] || {},
          teamType: 'away',
        }),
      );
    } catch (err: any) {
      dispatch(setHasError('video SetPlayingSec error'));
    }
  };
};

/**
 * Устанавливает текущее время. Для глупых компонентов.
 * Идёт паралельно с SetPlayingSec но с минимальными задержками
 * НЕ НАГРУЖАТЬ логикой
 */
export const SetPoorPerformancePlayedSec = (
  sec: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      dispatch(setPoorPerformancePlayedSec(sec));
    } catch (err: any) {
      dispatch(setHasError('video SetPlayingSec error'));
    }
  };
};

export const SetLocalVideoUrl = (
  url: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      dispatch(setLocalVideoObjectURL(url));
    } catch (err: any) {
      dispatch(setHasError('video SetLocalVideoUrl error'));
    }
  };
};
