import React, { FC, useEffect } from 'react';

import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/types/base';
import { useDispatch, useSelector } from 'react-redux';

import { MACRO_TIMEOUT, MICRO_TIMEOUT } from 'constants/constants';
import playIcon from 'resources/img/playIcon.svg';
import {
  SetPlayingSec,
  SetPoorPerformancePlayedSec,
} from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';

let timer: ReturnType<typeof setTimeout>;
let lastPlayedSeconds = 0;

const PlayerWrapper: FC<any> = (params) => {
  const dispatch = useDispatch();

  const { localVideoObjectURL } = useSelector(
    (state: AppStateType) => state.videoReducer,
  );

  const handleProgress = (state: OnProgressProps) => {
    dispatch(SetPoorPerformancePlayedSec(state.playedSeconds));
    lastPlayedSeconds = state.playedSeconds;
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      dispatch(SetPlayingSec(lastPlayedSeconds));
      //@ts-expect-error
      timer = undefined;
    }, MACRO_TIMEOUT);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ReactPlayer
        config={{
          file: {
            attributes: {
              preload: 'metadata',
              type: 'video/mp4',
            },
            hlsOptions: {
              forceHLS: false,
              xhrSetup: () => {},
            },
          },
        }}
        ref={params.refPlayer}
        height={params.resizableHeight}
        width={'auto'}
        url={localVideoObjectURL != '' ? localVideoObjectURL : params.url}
        playIcon={playIcon}
        playbackRate={params.playbackRate}
        progressInterval={MICRO_TIMEOUT}
        playing={params.isPlaying}
        onProgress={handleProgress}
        onError={params.handleError}
      />
    </div>
  );
};
export default PlayerWrapper;
