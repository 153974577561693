import { createSlice } from '@reduxjs/toolkit';

import { State } from 'types/app';

import { Placement, PlacementSchema } from '../types';

const initialState: InitialStateType = {
  placementSchemas: [],
  placements: [],
  state: State.init,
};

type InitialStateType = {
  placementSchemas: PlacementSchema[];
  placements: Placement[];
  state: State;
};

export const rawPlacementSchemasSlice = createSlice({
  name: 'rawPlacementSchemasSlice',
  initialState: initialState,
  reducers: {
    setPlacementSchemas(state, action) {
      state.placementSchemas = action.payload;
    },
    setPlacements(state, action) {
      state.placements = action.payload;
    },
  },
});
