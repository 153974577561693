import React, { FC } from 'react';

import Draggable from 'react-draggable';

interface NewSegmentHandleInterface {
  handleRef: any;
  direction: string;
  limitLeft: number;
  limitRight: number;
  posLeft: number;
  handleSegmentDrag: (e: any, newPosition: any, dragger: any) => void;
  offsetLeft: number;
  offsetRight: number;
  handleId: string;
  handleKeyDown: any;
}

export const NewSegmentHandle: FC<NewSegmentHandleInterface> = ({
  handleRef,
  direction,
  limitLeft,
  limitRight,
  posLeft,
  handleSegmentDrag,
  offsetLeft,
  //   offsetRight,
  handleId,
  handleKeyDown,
}) => {
  // const ref = useRef(null);

  const CLASS_NAMES: any = {
    left: 'leftDraggableHandle',
    right: 'rightDraggableHandle',
  };
  const handleDrag = (e: any, data: any) => {
    // Обработка drag ползунка трека
    const x = data.x;
    const parentWidth = data.node.parentNode.clientWidth;
    const newRatio = x / parentWidth;
    handleSegmentDrag(e, newRatio, direction);
  };
  return (
    <Draggable
      axis={'x'}
      nodeRef={handleRef}
      // handle={`.${handleId}`}
      onDrag={handleDrag}
      positionOffset={{ x: offsetLeft, y: 0 }}
      position={{ x: posLeft, y: 0 }}
      bounds={{ left: limitLeft, right: limitRight, top: 0, bottom: 0 }}
    >
      <div
        id={handleId}
        ref={handleRef}
        onKeyDown={() => handleKeyDown(posLeft)}
        style={
          {
            // left: posLeft
          }
        }
        className={`${handleId} handle ${CLASS_NAMES[direction]}`}
      >
        |
      </div>
    </Draggable>
  );
};
