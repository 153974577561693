export function extractDefaultAttr(fastEventDefaultValues = []) {
  const defaultAttributeValues = [];
  for (const attr of fastEventDefaultValues) {
    defaultAttributeValues.push({
      id: attr.attribute_id,
      key: attr.key,
      name: attr.name,
      type: attr.type,
      values: [
        {
          id: attr.id,
          value: attr.value,
          is_default: false,
        },
      ],
    });
  }
  return defaultAttributeValues;
}
