import { createSlice } from '@reduxjs/toolkit';

import { TrackType } from 'types';

const initialState: InitialStateType = {
  periods: [], // TODO Нужно использовать по всей приле
  currentPeriod: {} as TrackType,
  nextPeriod: {} as TrackType,
};

type InitialStateType = {
  periods: any;
  currentPeriod: TrackType;
  nextPeriod: TrackType;
};

export const periodSlice = createSlice({
  name: 'periodSlice',
  initialState: initialState,
  reducers: {
    setPeriods(state, action) {
      state.periods = action.payload;
    },
    setCurrentPeriod(state, action) {
      state.currentPeriod = action.payload;
    },
    setNextPeriod(state, action) {
      state.nextPeriod = action.payload;
    },
  },
});
