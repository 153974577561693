/* eslint-disable indent */
/* eslint-disable import/order */
import React, { FC, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import { ActiveEventType, TrackType } from 'types';
import { SegmentProps } from 'utils/customRanger';
import './style.css';

import { EditedEventActionsModal } from 'components/OperatorEditor/EditedEventActionsModal';
import {
  PossessionRow,
  SegmentsContainer,
} from 'components/OperatorEditor/OperatorEditorVideoTimeline/RecordedEvents';
import { PlacementRow } from 'components/OperatorEditor/OperatorEditorVideoTimeline/RecordedEvents/Placement/PlacementRow';
import { TaskCopyModal } from 'components/OperatorEditor/TaskCopyModal/TaskCopyModal';
import {
  RemoveEditedEventAction,
  UnselectTrackFoEditAction,
} from 'store/actions/recorder.actions';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';

interface RecordedEventsInterface {
  videoEnd: number;
  zoomValue: number;
  setSelectedEventId: (arg0: number) => void;
  handleVideoScroll: (ms: number) => void;
  horizontalScroll: any;
  currentPosition: number;
  keyDownHandler: any;
}
interface RecorderRowInterface {
  evt: ActiveEventType;
  videoEnd: number;
  setSelectedEventId: (arg0: number) => void;
  handleVideoScroll: (ms: number) => void;
  horizontalScroll: any;
  zoomValue: any;
  currentPosition: number;
  keyDownHandler: any;
}
export type ExtendedRangerInterface = {
  trackId: number;
  key?: number;
  isRecorded: boolean;
  isZeroLength: boolean;
  leftHandle?: any;
  rightHandle?: any;
  getSegmentProps?: <T>(props?: T) => T & SegmentProps;
  style?: {
    height: any;
    width: any;
    backgroundColor?: string;
    border?: string;
    borderRadius?: string;
  };
};

export const RecorderRow: FC<RecorderRowInterface> = ({
  evt,
  videoEnd,
  setSelectedEventId,
  handleVideoScroll,
  horizontalScroll,
  zoomValue,
  currentPosition,
}) => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current.style.left = `-${horizontalScroll}px`;
  }, [horizontalScroll]);
  //console.log('Events container render');
  return (
    <div className={'eventRecorderHolder'}>
      <div className={'eventTrackName'}>{evt.label ? evt.label : evt.name}</div>
      <div className="event-track-container" ref={ref}>
        {
          <SegmentsContainer
            event={evt}
            videoLength={videoEnd}
            setSelectedEventId={setSelectedEventId}
            handleVideoScroll={handleVideoScroll}
            zoomValue={zoomValue}
            currentPosition={currentPosition}
          />
        }
      </div>
    </div>
  );
};

export const RecordedEvents: FC<RecordedEventsInterface> = ({
  videoEnd,
  zoomValue,
  setSelectedEventId,
  handleVideoScroll,
  horizontalScroll,
  currentPosition,
  keyDownHandler,
}) => {
  const dispatch = useDispatch();
  const { setPlayerSkipped } = recorderReducerNew.actions;
  const editedEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.editedEvents,
  );
  const activeEvents = useSelector(
    (state: AppStateType) => state.recorderReducerNew.activeEvents,
  );
  const activePossession = useSelector(
    (state: AppStateType) => state.possessionReducer.activePossession,
  );
  const possessions = useSelector(
    (state: AppStateType) => state.possessionReducer.possessions,
  );
  const homePlacements = useSelector(
    (state: AppStateType) => state.placementReducer.homePlacements,
  );
  const awayPlacements = useSelector(
    (state: AppStateType) => state.placementReducer.awayPlacements,
  );
  const { removeFastEventAction, setShowCoordinatesModal } =
    fastEventReducer.actions;
  const [clickedTrack, setClickedTrack] = useState<TrackType | null>(null);
  const [showCopyModal, setShowCopyModal] = useState(false);

  const toggleCopyModal = () => {
    setShowCopyModal(!showCopyModal);
  };
  const isPossessionInActiveEvents = activeEvents.filter(
    (evt) => evt.name && evt.name.toLowerCase() == 'possession',
  )[0];
  const clickHandler = async (ev: any) => {
    // console.log('clickHandler');
    if (ev.target.dataset.id) {
      return;
    }
    if (editedEvents.length == 0) {
      return;
    }
    //console.log('clickHandler 2');
    const createdFastEvent = {
      ...editedEvents[0].track,
      event_id: editedEvents[0].id,
      eventId: editedEvents[0].id,
      event_name: editedEvents[0].name,
      eventName: editedEvents[0].name,
    };
    dispatch(removeFastEventAction());
    dispatch(setShowCoordinatesModal(false));
    dispatch(UnselectTrackFoEditAction(editedEvents[0].track.id));
    dispatch(RemoveEditedEventAction(createdFastEvent, undefined));
    dispatch(setPlayerSkipped(false));
  };
  return (
    <>
      <div
        className={'recordedEventsContainer recordedTracksContainer'}
        onClick={clickHandler}
      >
        {showCopyModal && (
          <TaskCopyModal
            clickedTrack={clickedTrack}
            setHideCopyModal={toggleCopyModal}
            onClickOustide={() => toggleCopyModal()}
          />
        )}
        {!isPossessionInActiveEvents &&
          (activePossession || possessions.length > 0) && (
            <PossessionRow
              handleVideoScroll={handleVideoScroll}
              horizontalScroll={horizontalScroll}
              zoomValue={zoomValue}
            />
          )}
        {activeEvents.map((evt: ActiveEventType) => {
          if (evt.name && evt.name.toLowerCase() == 'possession') {
            return (
              evt.isVisible && (
                <PossessionRow
                  key={evt.id}
                  handleVideoScroll={handleVideoScroll}
                  horizontalScroll={horizontalScroll}
                  zoomValue={zoomValue}
                />
              )
            );
          }
          return (
            evt.isVisible && (
              <RecorderRow
                key={evt.id}
                evt={evt}
                videoEnd={videoEnd}
                setSelectedEventId={setSelectedEventId}
                handleVideoScroll={handleVideoScroll}
                horizontalScroll={horizontalScroll}
                zoomValue={zoomValue}
                currentPosition={currentPosition}
                keyDownHandler={keyDownHandler}
              />
            )
          );
        })}
        <PlacementRow
          handleVideoScroll={handleVideoScroll}
          horizontalScroll={horizontalScroll}
          zoomValue={zoomValue}
          title="Расстановки команды-хозяйки"
          placements={homePlacements}
        />
        <PlacementRow
          handleVideoScroll={handleVideoScroll}
          horizontalScroll={horizontalScroll}
          zoomValue={zoomValue}
          title="Расстановки команды-гостя"
          placements={awayPlacements}
        />
        {
          <EditedEventActionsModal
            isOpen={editedEvents.length > 0}
            toggleCopyModal={toggleCopyModal}
            setCopyTrack={setClickedTrack}
            keyDownHandler={keyDownHandler}
          />
        }
      </div>
    </>
  );
};
