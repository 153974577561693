/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';

import {
  CheckCircleOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import zeroLength from 'resources/img/zeroLength.svg';
import { DeletePlacement } from 'store/actions/placements.action';
import { Placement } from 'store/raw/types';
import { AppStateType } from 'store/reducers';
import { State } from 'types/app';

interface PlacementSegmentInterface {
  placement: Placement;
  prevTrack: Placement;
  nextTrack: Placement;
  parent: any;
  handleVideoScroll: (ms: number) => void;
}

export const PlacementSegment: FC<PlacementSegmentInterface> = ({
  placement,
  parent,
  handleVideoScroll,
}) => {
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );
  const videoLength = useSelector(
    (state: AppStateType) => state.videoReducer.videoLength,
  );
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [isCanClose, setIsCanClose] = useState(true);
  const [deleteAgree, setDeleteAgree] = useState(false);
  const [status, setStatus] = useState(State.init);

  const getWidth = () => {
    return Math.floor(
      parent?.current?.offsetWidth *
        ((placement.finished_at - placement.started_at) / 1000 / videoLength),
    );
  };

  const getStart = () => {
    return (
      parent?.current?.offsetWidth * (placement.started_at / 1000 / videoLength)
    );
  };

  const calculatePositionLeft = () => {
    return getStart() + getWidth();
  };
  const [posLeft, setPosLeft] = useState(calculatePositionLeft());

  // const getTimeCoord = (time: number) => {
  //   return parent?.current?.offsetWidth * (time / videoLength);
  // };
  // const handleTrackDrag = (
  //   e: any,
  //   newPosition: number,
  //   drager: 'left' | 'right',
  // ) => {
  //   dispatch(
  //     UpdateDataActivePossession({
  //       start: drager === 'left' ? newPosition * videoLength : track.start,
  //       stop: drager === 'right' ? newPosition * videoLength : track.stop,
  //     }),
  //   );
  // };

  const segmentClass = () => {
    return `segment${isActive ? ' editedSegment' : ' '} newSegmentTrack placementSegment`;
  };
  const getSegmentStyle = () => {
    const width = getWidth();
    const style: any = {
      width: width || 0,
    };
    if (width === 0) {
      style.position = 'relative';
      style.left = -4;
    }
    return style;
  };
  const handleDelete = async () => {
    setStatus(State.deleting);
    try {
      await dispatch(DeletePlacement(placement.id));
    } catch (error) {
      setStatus(State.error);
    }
    setStatus(State.init);
    setIsActive(false);
    setIsCanClose(true);
    setDeleteAgree(false);
  };

  return (
    <>
      <div
        className={segmentClass()}
        style={{
          left: getStart() || 0,
          display: 'flex',
          position: 'absolute',
        }}
        onFocus={() => setIsActive(true)}
        onBlur={() => {
          if (isCanClose) {
            setIsActive(false);
          }
        }}
        onMouseEnter={() => setIsCanClose(false)}
        onMouseLeave={() => setIsCanClose(true)}
        tabIndex={100}
      >
        <div style={getSegmentStyle()}>
          {placement.finished_at - placement.started_at < 0.1 ? (
            <img src={zeroLength} />
          ) : null}
        </div>
        {isActive && (
          <Button
            className="possessionEventDeleteButton"
            type="text"
            shape="circle"
            icon={
              status == State.deleting ? (
                <LoadingOutlined />
              ) : deleteAgree ? (
                <CheckCircleOutlined />
              ) : (
                <DeleteOutlined />
              )
            }
            size="small"
            danger={status == State.error}
            onClick={() => {
              if (deleteAgree) {
                handleDelete();
              } else {
                setDeleteAgree(!deleteAgree);
              }
            }}
          />
        )}
      </div>
    </>
  );
};
