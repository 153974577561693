/* eslint-disable import/order */
import { combineReducers } from 'redux';

import { gamePlayersSlice } from 'store/slices/gamePlayersSlice';
import { possessionSlice } from 'store/slices/possessionSlice';

import { rawActualEventsSlice } from 'store/raw/slices/rawActualEventsSlice';
import { rawPlacementSchemasSlice } from 'store/raw/slices/rawPlacementSchemasSlice';
import { rawTaskSlice } from 'store/raw/slices/rawTaskSlice';
import { appSlice } from 'store/slices/appSlice';
import { eventsSlice } from 'store/slices/eventsSlice';
import { periodSlice } from 'store/slices/periodSlice';
import { placementSlice } from 'store/slices/placementSlice';
import { videoSlice } from 'store/slices/videoSlice';
import { alertReducer } from './alerts.reducer';
import { authReducer } from './auth.reducer';
import { fastEventReducer } from './fastEvent.reducer';
import { messagesReducer } from './messages.reducer';
import { playerReducer } from './player.reducer';
import { protocolReducer } from './protocol.reducer';
import recorderReducer, { recorderReducerNew } from './recorder.reducer';
import { taskReducer } from './task.reducer';

const mainReducer = combineReducers({
  appReducer: appSlice.reducer,
  taskReducer: taskReducer.reducer,
  recorderReducer: recorderReducer,
  recorderReducerNew: recorderReducerNew.reducer,
  authReducer: authReducer.reducer,
  playerReducer: playerReducer.reducer,
  fastEventReducer: fastEventReducer.reducer,
  possessionReducer: possessionSlice.reducer,
  gamePlayersReducer: gamePlayersSlice.reducer,
  alertReducer: alertReducer.reducer,
  messagesReducer: messagesReducer.reducer,
  rawTaskReducer: rawTaskSlice.reducer,
  rawActualEventsReducer: rawActualEventsSlice.reducer,
  rawPlacementSchemasReducer: rawPlacementSchemasSlice.reducer,
  placementReducer: placementSlice.reducer,
  protocolReducer: protocolReducer.reducer,
  videoReducer: videoSlice.reducer,
  eventsReducer: eventsSlice.reducer,
  periodReducer: periodSlice.reducer,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
