import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

export async function login(email: string, password: string) {
  const url = `${endpoint}/api/token/`;
  try {
    const response = await myAxios.POST(url, {
      email: email,
      password: password,
    });
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}
