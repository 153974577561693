import React, { useEffect, useState } from 'react';

import { Col, Divider, Drawer, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import { protocolReducer } from 'store/reducers/protocol.reducer';
import { ActiveEventType, TrackType } from 'types';
import { formattedTimeOnlyMinutes } from 'utils/functions';
import './index.css';

import PlayerRow from './GameProtocolPlayerRow';
import Title from './GameProtocolTitle';

const GameProtocolSidePanel = () => {
  const { isOpen } = useSelector(
    (state: AppStateType) => state.protocolReducer,
  );
  const { homePlayers, homeMainStaff, awayPlayers, awayMainStaff } =
    useSelector((state: AppStateType) => state.gamePlayersReducer);
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const [protocol, setProtocol] = useState<any>({
    homeTeam: {
      main: [],
      substitution: [],
    },
    awayTeam: {
      main: [],
      substitution: [],
    },
  });
  const dispatch = useDispatch();
  const { toggleGameProtocol } = protocolReducer.actions;
  const onClose = () => {
    dispatch(toggleGameProtocol(!isOpen));
  };

  const processPlayerTag = (
    player: any,
    yellowCards: any,
    secondYellowCards: any,
    redCards: any,
    ownGoals: any,
    shots: any,
    substitutions: any,
    team: string,
  ) => {
    let tagsCount = 0;
    activeEvents.forEach((event) =>
      event.tracks.forEach((track) =>
        track.attributes.forEach((attribute) => {
          if (
            attribute.key === 'player' &&
            attribute.values[0].value === player.number &&
            track.attributes.filter((att) => att.key === 'team')[0].values[0]
              .value === 'Home'
          ) {
            tagsCount += 1;
          }
        }),
      ),
    );
    const playerYellowCards: any = [];
    const playerRedCards: any = [];
    const playerGoals: any = [];
    const playerOwnGoals: any = [];
    const playerSubstsIn: any = [];
    const playerSubstsOut: any = [];
    yellowCards?.tracks.forEach((track: TrackType) => {
      const playerAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'player' &&
          attribute.values.filter(
            (val) => val.value.toString() === player.number.toString(),
          ).length === 1,
      )[0];
      const teamAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'team' &&
          attribute.values.filter((val) => val.value === team).length === 1,
      )[0];
      if (playerAttr && teamAttr) {
        playerYellowCards.push({
          type: 'yellow_card',
          time: formattedTimeOnlyMinutes(track.start),
        });
      }
    });
    secondYellowCards?.tracks.forEach((track: TrackType) => {
      const playerAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'player' &&
          attribute.values.filter(
            (val) => val.value.toString() === player.number.toString(),
          ).length === 1,
      )[0];
      const teamAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'team' &&
          attribute.values.filter((val) => val.value === team).length === 1,
      )[0];
      if (playerAttr && teamAttr) {
        playerRedCards.push({
          type: 'red_card',
          time: formattedTimeOnlyMinutes(track.start),
        });
      }
    });
    redCards?.tracks.forEach((track: TrackType) => {
      const playerAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'player' &&
          attribute.values.filter(
            (val) => val.value.toString() === player.number.toString(),
          ).length === 1,
      )[0];
      const teamAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'team' &&
          attribute.values.filter((val) => val.value === team).length === 1,
      )[0];
      if (playerAttr && teamAttr) {
        playerRedCards.push({
          type: 'red_card',
          time: formattedTimeOnlyMinutes(track.start),
        });
      }
    });
    ownGoals?.tracks.forEach((track: TrackType) => {
      const playerAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'player' &&
          attribute.values.filter(
            (val) => val.value.toString() === player.number.toString(),
          ).length === 1,
      )[0];
      const teamAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'team' &&
          attribute.values.filter((val) => val.value === team).length === 1,
      )[0];
      if (playerAttr && teamAttr) {
        playerOwnGoals.push({
          type: 'own_goal',
          time: formattedTimeOnlyMinutes(track.start),
        });
      }
    });
    shots?.tracks.forEach((track: TrackType) => {
      const playerAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'player' &&
          track.attributes.filter(
            (attr) => attr.key === 'result' && attr.values[0].value === 'Goal',
          ).length &&
          attribute.values.filter(
            (val) => val.value.toString() === player.number.toString(),
          ).length === 1,
      )[0];
      const teamAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'team' &&
          attribute.values.filter((val) => val.value === team).length === 1,
      )[0];
      if (playerAttr && teamAttr) {
        playerGoals.push({
          type: 'goal',
          time: formattedTimeOnlyMinutes(track.start),
        });
      }
    });
    substitutions?.tracks.forEach((track: TrackType) => {
      const playerAttrIn = track.attributes.filter(
        (attribute) =>
          attribute.key === 'player_in' &&
          attribute.values.filter(
            (val) => val.value.toString() === player.number.toString(),
          ).length === 1,
      )[0];
      const teamAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'team' &&
          attribute.values.filter((val) => val.value === team).length === 1,
      )[0];
      if (playerAttrIn && teamAttr) {
        const playerOut = track.attributes.filter(
          (att) => att.key === 'player_out',
        )[0].values[0].value;
        playerSubstsIn.push({
          type: 'substitution',
          time: formattedTimeOnlyMinutes(track.start),
          player: {
            number: playerOut,
          },
        });
      }
    });
    substitutions?.tracks.forEach((track: TrackType) => {
      const playerAttrIn = track.attributes.filter(
        (attribute) =>
          attribute.key === 'player_out' &&
          attribute.values.filter(
            (val) => val.value.toString() === player.number.toString(),
          ).length === 1,
      )[0];
      const teamAttr = track.attributes.filter(
        (attribute) =>
          attribute.key === 'team' &&
          attribute.values.filter((val) => val.value === team).length === 1,
      )[0];
      if (playerAttrIn && teamAttr) {
        const playerIn = track.attributes.filter(
          (att) => att.key === 'player_in',
        )[0].values[0].value;
        playerSubstsOut.push({
          type: 'substitution',
          time: formattedTimeOnlyMinutes(track.start),
          player: {
            number: playerIn,
          },
        });
      }
    });
    return {
      id: player.id,
      name: player.name,
      number: player.number,
      tagsCount: tagsCount,
      events: [
        ...playerYellowCards,
        ...playerRedCards,
        ...playerGoals,
        ...playerOwnGoals,
      ],
      substIn: [...playerSubstsIn],
      substOut: [...playerSubstsOut],
    };
  };
  useEffect(() => {
    if (!isOpen) {
      setProtocol({
        homeTeam: {
          main: [],
          substitution: [],
        },
        awayTeam: {
          main: [],
          substitution: [],
        },
      });
      return;
    }
    const yellowCards: ActiveEventType = activeEvents.filter(
      (evt: any) => evt.name === 'Yellow card',
    )[0];
    const secondYellowCards: ActiveEventType = activeEvents.filter(
      (evt: any) => evt.name === 'Second yellow card',
    )[0];
    const shots = activeEvents.filter((evt: any) => evt.name === 'Shot')[0];
    const ownGoals = activeEvents.filter(
      (evt: any) => evt.name === 'OwnGoal',
    )[0];
    const substitutions = activeEvents.filter(
      (evt: any) => evt.name === 'Substitution',
    )[0];
    const redCards = activeEvents.filter(
      (evt: any) => evt.name === 'Red card',
    )[0];
    const p = {
      homeTeam: {
        main: homePlayers
          .map((player) => {
            return processPlayerTag(
              player,
              yellowCards,
              secondYellowCards,
              redCards,
              ownGoals,
              shots,
              substitutions,
              'Home',
            );
          })
          .sort((a: any, b: any) => a.number - b.number),
        substitution: homeMainStaff
          .map((player) => {
            return processPlayerTag(
              player,
              yellowCards,
              secondYellowCards,
              redCards,
              ownGoals,
              shots,
              substitutions,
              'Home',
            );
          })
          .sort((a: any, b: any) => a.number - b.number),
      },
      awayTeam: {
        main: awayPlayers
          .map((player) => {
            return processPlayerTag(
              player,
              yellowCards,
              secondYellowCards,
              redCards,
              ownGoals,
              shots,
              substitutions,
              'Away',
            );
          })
          .sort((a: any, b: any) => a.number - b.number),
        substitution: awayMainStaff
          .map((player) => {
            return processPlayerTag(
              player,
              yellowCards,
              secondYellowCards,
              redCards,
              ownGoals,
              shots,
              substitutions,
              'Away',
            );
          })
          .sort((a: any, b: any) => a.number - b.number),
      },
    };
    setProtocol(p);
  }, [isOpen]);
  return (
    <Drawer
      width={960}
      closable={false}
      styles={{
        header: {
          padding: 0,
        },
        body: {
          padding: 0,
        },
        content: {
          borderRadius: '12px 0px 0px 12px',
        },
      }}
      onClose={onClose}
      open={isOpen}
      title={<Title />}
    >
      <div className="game-protocol-lineup-title">Стартовый состав</div>
      <Row className="game-protocol-header" gutter={16}>
        <Col span={12}>
          <Row gutter={8}>
            <Col span={2}>№</Col>
            <Col span={8}>ФИО</Col>
            <Col span={4}>Замены</Col>
            <Col span={4}>ЖК/КК</Col>
            <Col span={4}>Голы</Col>
            <Col span={2}>Теги</Col>
          </Row>
          <Divider />
        </Col>
        <Col span={12}>
          <Row gutter={8}>
            <Col span={2}>№</Col>
            <Col span={8}>ФИО</Col>
            <Col span={4}>Замены</Col>
            <Col span={4}>ЖК/КК</Col>
            <Col span={4}>Голы</Col>
            <Col span={2}>Теги</Col>
          </Row>
          <Divider />
        </Col>
      </Row>
      <div className="game-protocol-lineup-container">
        <Row gutter={16}>
          <Col span={12}>
            {protocol.homeTeam?.main.map((player: any) => PlayerRow(player))}
          </Col>
          <Col span={12}>
            {protocol.awayTeam?.main.map((player: any) => PlayerRow(player))}
          </Col>
        </Row>
      </div>
      <div className="game-protocol-lineup-title">Скамейка запасных</div>
      <div className="game-protocol-lineup-container">
        <Row gutter={16}>
          <Col span={12}>
            {protocol.homeTeam.substitution.map((player: any) =>
              PlayerRow(player),
            )}
          </Col>
          <Col span={12}>
            {protocol.awayTeam.substitution.map((player: any) =>
              PlayerRow(player),
            )}
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};
export default GameProtocolSidePanel;
