import { ThunkAction } from 'redux-thunk';

import { getActivePlacementsAPI, getPlacementSchemas } from 'api';
import { rawPlacementSchemasSlice } from 'store/raw/slices/rawPlacementSchemasSlice';
import { AppStateType } from 'store/reducers';
import { placementSlice } from 'store/slices/placementSlice';

import { Placement } from '../types';

const { setPlacementSchemas, setPlacements } = rawPlacementSchemasSlice.actions;
const { setHomePlacement, setAwayPlacement } = placementSlice.actions;

export const GetPlacementSchemas = (
  sportId: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      const response = await getPlacementSchemas(sportId);
      dispatch(setPlacementSchemas(response));
    } catch (err) {
      console.log(err);
    }
  };
};

export const GetPlacements = (
  activityId: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const response: [Placement] = await getActivePlacementsAPI(activityId);
      dispatch(setPlacements(response));
      const { home_team_id, away_team_id } = getState().rawTaskReducer.task;
      const homePlacements = response.filter(
        (pls: Placement) => pls.team == home_team_id,
      );
      const awayPlacements = response.filter(
        (pls: Placement) => pls.team == away_team_id,
      );
      dispatch(setHomePlacement(homePlacements));
      dispatch(setAwayPlacement(awayPlacements));
    } catch (err) {
      console.log(err);
    }
  };
};
