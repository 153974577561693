/* eslint-disable no-undef */
import axios from 'axios';

import { endpoint } from 'constants/constants';

const HEAD_UIID = 'X-Request-UUID';
const CREDENTAILS = 'same-origin';
export const setupResponseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (
        [401, 403].includes(error.response.status) ||
        [401, 403].includes(error.response.statusCode)
      ) {
        if (error.response.config.url === `${endpoint}/api/token/refresh/`) {
          window.location.href = '/login';
        }
        if (error.response.data.code === 'token_not_valid') {
          // TODO Возможно тут ошибка
          // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-use-before-define
          const refreshed = post(`${endpoint}/api/token/refresh/`, {
            refresh: localStorage.getItem('refreshToken'),
          })
            .then((resp) => {
              localStorage.setItem('authtoken', resp.data.access);
            })
            .catch(() => {
              window.location.href = '/login';
            });
        }
      }
      if ([404].includes(error.response.status)) {
        // store.dispatch(goto404());
        // window.location.href = '/404';
      }
      return Promise.reject(error);
    },
  );
};

const get = (
  path,
  params,
  additionalHeaders = {},
  response_type = 'application/json',
) => {
  let headers = {
    'Content-Type': response_type,
    'X-Request-UUID': localStorage.getItem('uuid'),
    ...additionalHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[HEAD_UIID] = uuid;
  }
  return axios({
    headers: headers,
    method: 'get',
    url: path,
    params,
    responseType: response_type,
  });
};
const put = (path, body) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-Request-UUID': localStorage.getItem('uuid'),
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[HEAD_UIID] = uuid;
  }
  return axios({
    headers: headers,
    method: 'put',
    url: path,
    data: body ? JSON.stringify(body) : undefined,
  });
};
const post = (path, body, customHeaders = {}) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-Request-UUID': localStorage.getItem('uuid'),
    ...customHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[HEAD_UIID] = uuid;
  }
  return axios({
    headers: headers,
    credentials: CREDENTAILS,
    method: 'post',
    url: path,
    data: JSON.stringify(body) ? body : undefined,
  });
};
const patch = (path, body, customHeaders = {}) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-Request-UUID': localStorage.getItem('uuid'),
    ...customHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[HEAD_UIID] = uuid;
  }
  return axios({
    headers: headers,
    credentials: CREDENTAILS,
    method: 'patch',
    url: path,
    data: JSON.stringify(body) ? body : undefined,
  });
};
const remove = (path, body, customHeaders) => {
  let headers = {
    'Content-Type': 'application/json',
    'X-Request-UUID': localStorage.getItem('uuid'),
    ...customHeaders,
  };
  const uuid = localStorage.getItem('uuid');
  if (uuid !== null) {
    headers[HEAD_UIID] = uuid;
  }
  return axios({
    headers: headers,
    credentials: CREDENTAILS,
    method: 'delete',
    url: path,
    data: JSON.stringify(body) ? body : undefined,
  });
};
const myAxios = {
  GET: get,
  POST: post,
  PUT: put,
  PATCH: patch,
  DELETE: remove,
  DEFAULTS: axios.defaults,
};
export default myAxios;
