import React, { FC } from 'react';

import { MenuOutlined, TableOutlined } from '@ant-design/icons';
import { Radio } from 'antd';
import { useDispatch } from 'react-redux';

import { taskReducer } from 'store/reducers/task.reducer';

import './style.css';

export const EventsViewSwitcher: FC<any> = () => {
  const dispatch = useDispatch();
  const { setViewTimeline } = taskReducer.actions;
  const handleSwith = (ev: any) => {
    dispatch(setViewTimeline(ev.target.value));
  };
  return (
    <Radio.Group
      className="eventsViewSwitchContainer"
      defaultValue="default"
      buttonStyle="outline"
      onChange={handleSwith}
    >
      <Radio.Button value="default">
        <MenuOutlined />
      </Radio.Button>
      <Radio.Button value="byPlayers">
        <TableOutlined />
      </Radio.Button>
    </Radio.Group>
  );
};
