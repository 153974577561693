import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

import { createCommonHeader } from './_functions';

export async function getDownloadVideoLink(taskId: string | number) {
  const url = `${endpoint}/api/v1/task/${taskId}/video/?download=true`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.GET(url, null, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}
