import React, { FC, useEffect, useState } from 'react';

import { SwapOutlined } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { Button, Col, ConfigProvider, Radio, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { KEYKODES_TO_CHAR } from 'constants/constants';
import { SetInverseTeamDirection } from 'store/actions/player.actions';
import {
  CreateActivePossession,
  StopRecordPossession,
} from 'store/actions/possession.actions';
import { AppStateType } from 'store/reducers';
import { HotkeyType, TrackType } from 'types';
import './style.css';

const POSESSION_HOME = 'Possession home';
const POSSESSION_AWAY = 'Possession away';

export const TeamSwitcher: FC<any> = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<'Home' | 'Away' | 'null'>('null');
  const [isDisabled, setIsDisabled] = useState(false);

  const [posessionHomeHK, setPossessionHomeHK] = useState<string | null>(null);
  const [posessionAwayHK, setPossessionAwayHK] = useState<string | null>(null);
  const [posessionWhistleHK, setPossessionWhistleHK] = useState<string | null>(
    null,
  );
  const [possessionHomeHKId, setPossessionHomeHKId] = useState<string>('');
  const [possessionAwayHKId, setPossessionAwayHKId] = useState<string>('');
  const [possessionOut, setPossessionOut] = useState<string>('');

  const [homeIsDisabled, setHomeIsDisabled] = useState(false);
  const [awayIsDisabled, setAwayIsDisabled] = useState(false);

  const {
    playerSettings: { color_settings = {} },
    invertedTeamDisplay,
  } = useSelector((state: AppStateType) => state.playerReducer);
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );
  const selectedHomeColor = color_settings?.Home;
  const selectedAwayColor = color_settings?.Away;
  const {
    possessionHomeFastEvent,
    possessionAwayFastEvent,
    activePossession,
    possessions,
    homeTeamName,
    awayTeamName,
  } = useSelector((state: AppStateType) => state.possessionReducer);
  const { hotkeysIsDisplayed, hotkeysForm } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );

  const onChange = (e: RadioChangeEvent) => {
    if (!isDisabled) {
      setValue(e.target.value);
    }
  };

  useEffect(() => {
    setIsDisabled(!possessionHomeFastEvent && !possessionAwayFastEvent);
  }, [possessionHomeFastEvent, possessionAwayFastEvent]);

  useEffect(() => {
    const func = async () => {
      if (activePossession && activePossession.track.id == -1) {
        await dispatch(StopRecordPossession());
      }
      if (value != 'null') {
        await dispatch(CreateActivePossession(value, playedSec));
      }
    };
    func().then();
  }, [value]);

  useEffect(() => {
    setPossessionHomeHK(() => {
      const key = hotkeysForm.filter(
        (hk: HotkeyType) => hk.label === POSESSION_HOME,
      )[0]?.key;
      if (key) {
        return KEYKODES_TO_CHAR[key] || '';
      }
    });
    setPossessionHomeHKId(() => {
      const key = hotkeysForm.filter(
        (hk: HotkeyType) => hk.label === POSESSION_HOME,
      )[0];
      if (key) {
        return key.id;
      }
      return '';
    });
    setPossessionAwayHK(() => {
      const key = hotkeysForm.filter(
        (hk: HotkeyType) => hk.label === POSSESSION_AWAY,
      )[0]?.key;
      if (key) {
        return KEYKODES_TO_CHAR[key] || '';
      }
    });
    setPossessionAwayHKId(() => {
      const key = hotkeysForm.filter(
        (hk: HotkeyType) => hk.label === POSSESSION_AWAY,
      )[0];
      if (key) {
        return key.id;
      }
      return '';
    });
    setPossessionWhistleHK(() => {
      const key = hotkeysForm.filter(
        (hk: HotkeyType) => hk.label === 'Аут / свисток',
      )[0]?.key;
      if (key) {
        return KEYKODES_TO_CHAR[key] || '';
      }
    });
    setPossessionOut(() => {
      const key = hotkeysForm.filter(
        (hk: HotkeyType) => hk.label === 'Аут / свисток',
      )[0];
      if (key) {
        return key.id;
      }
      return '';
    });
  }, [hotkeysForm]);

  useEffect(() => {
    if (possessions.length > 0) {
      const [possessionTrack] = possessions.filter(
        (trck: TrackType) =>
          trck.start &&
          trck.start !== null &&
          trck.start <= playedSec &&
          trck.stop &&
          trck.stop !== null &&
          trck.stop >= playedSec,
      );
      if (possessionTrack) {
        setAwayIsDisabled(!possessionTrack.isRecording);
        setHomeIsDisabled(!possessionTrack.isRecording);
      } else {
        setAwayIsDisabled(false);
        setHomeIsDisabled(false);
      }
    } else {
      setAwayIsDisabled(false);
      setHomeIsDisabled(false);
    }
  }, [playedSec]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: '#F5F5F5',
            lineWidth: 3,
          },
          Radio: {
            colorPrimary: '#000',
            algorithm: true,
          },
        },
      }}
    >
      <Radio.Group
        onChange={onChange}
        value={value}
        className="teamSwitcherContainer"
        disabled={isDisabled}
      >
        <Row gutter={8} justify="space-between" wrap={false}>
          <Col order={invertedTeamDisplay ? 3 : 1}>
            <Button style={{ borderColor: selectedHomeColor }}>
              <Radio
                value={'Home'}
                id={possessionHomeHKId}
                disabled={homeIsDisabled}
              >
                {homeTeamName}
                {hotkeysIsDisplayed && posessionHomeHK && (
                  <div className="hotkey-in-radio">{posessionHomeHK}</div>
                )}
              </Radio>
            </Button>
          </Col>
          <Col order={2}>
            <Button
              onClick={() =>
                dispatch(SetInverseTeamDirection(!invertedTeamDisplay))
              }
              type="text"
              icon={<SwapOutlined />}
            />
          </Col>
          <Col order={invertedTeamDisplay ? 1 : 3}>
            <Button style={{ borderColor: selectedAwayColor }}>
              <Radio
                value={'Away'}
                id={possessionAwayHKId}
                disabled={awayIsDisabled}
                className={'ellipsis'}
              >
                {awayTeamName}
                {hotkeysIsDisplayed && posessionAwayHK && (
                  <div className="hotkey-in-radio">{posessionAwayHK} </div>
                )}
              </Radio>
            </Button>
          </Col>
          <Col order={4}>
            <Button onClick={() => setValue('null')} id={possessionOut}>
              <Radio value={'null'} disabled={false}>
                Аут / свисток
                {hotkeysIsDisplayed && posessionWhistleHK && (
                  <div className="hotkey-in-radio">{posessionWhistleHK}</div>
                )}
              </Radio>
            </Button>
          </Col>
        </Row>
      </Radio.Group>
    </ConfigProvider>
  );
};
