import { ThunkAction } from 'redux-thunk';

import {
  deletePlacementAPI,
  removeAllEventsByPlayerAPI,
  savePlacementAPI,
  updatePlacementAPI,
} from 'api';
import {
  Attribute,
  EventTrack,
  Placement,
  PlacementPosition,
} from 'store/raw/types';
import { AppStateType } from 'store/reducers';
import { placementSlice } from 'store/slices/placementSlice';

import { GetCurrentEventsAction } from './recorder.actions';

const {
  setHomePlacement,
  setAwayPlacement,
  setActivePlacement,
  addPlacement,
  setSwapPlayer,
} = placementSlice.actions;

export const DeletePlacement = (
  placementId: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    const activeTask = getState().taskReducer.activeTask;
    const homeActivePlacement = getState().placementReducer.homeActivePlacement;
    const awayActivePlacement = getState().placementReducer.awayActivePlacement;
    if (!activeTask) {
      return;
    }
    try {
      const resp = await deletePlacementAPI(
        activeTask.activity_id,
        placementId,
      );
      dispatch(
        setHomePlacement(
          getState().placementReducer.homePlacements.filter(
            (pl) => pl.id !== placementId,
          ),
        ),
      );
      dispatch(
        setAwayPlacement(
          getState().placementReducer.awayPlacements.filter(
            (pl) => pl.id !== placementId,
          ),
        ),
      );
      if (homeActivePlacement.id == placementId) {
        dispatch(setActivePlacement({ teamType: 'home', placement: {} }));
      }
      if (awayActivePlacement.id == placementId) {
        dispatch(setActivePlacement({ teamType: 'away', placement: {} }));
      }
      return resp;
    } catch (err) {
      console.log(err);
    }
  };
};

export const SavePlacement = (
  placementId: string,
  teamType: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const activeTask = getState().taskReducer.activeTask;
      const playedSec = getState().videoReducer.playedSec;
      const currentPeriod = getState().periodReducer.currentPeriod;
      let activePlacement: Placement = {} as Placement;
      const periodStart = Math.floor((currentPeriod.start || 0) * 1000);
      const periodEnd = Math.floor((currentPeriod.stop || 0) * 1000);
      let startTime = periodStart;
      let endTime = periodEnd;
      let teamId = 0;
      let placementsByTeam = [];
      if (!activeTask || !currentPeriod.id) {
        return;
      }
      if (teamType == 'home') {
        teamId = activeTask.home_team_id;
        activePlacement = getState().placementReducer.homeActivePlacement;
        placementsByTeam = getState().placementReducer.homePlacements;
      } else {
        teamId = activeTask.away_team_id;
        activePlacement = getState().placementReducer.awayActivePlacement;
        placementsByTeam = getState().placementReducer.awayPlacements;
      }
      let placementsInPeriod = placementsByTeam.filter((pl) => {
        return pl.started_at >= periodStart && pl.finished_at <= periodEnd;
      });
      placementsInPeriod = [...placementsInPeriod].sort((a, b) => {
        if (a.finished_at > b.finished_at) {
          return 1;
        }
        if (a.finished_at < b.finished_at) {
          return -1;
        }
        return 0;
      });
      if (!activePlacement?.id) {
        // Создаём расстановку, когда таймлайн в периоде и нет активной расстановки
        // Возможные варианты:
        // - приодов нет (создать расстановку длинной в период)
        if (placementsInPeriod.length == 0) {
          startTime = Math.floor((currentPeriod.start || 0) * 1000);
        }
        // - до создание есть период (старт новой расстановки == конец предыдущей)
        const placementsBefore = placementsInPeriod.filter(
          (pl) => pl.finished_at < playedSec * 1000,
        );
        if (placementsBefore.length > 0) {
          startTime = placementsBefore[placementsBefore.length - 1].finished_at;
        }
        // - после есть период (стоп новой расстановки == начало следующей)
        const placementsAfter = placementsInPeriod.filter(
          (pl) => pl.started_at > playedSec * 1000,
        );
        if (placementsAfter.length > 0) {
          endTime = placementsAfter[0].started_at;
        }
        const resp = await savePlacementAPI(
          placementId,
          teamId,
          activeTask.activity_id,
          startTime,
          endTime,
        );
        dispatch(setActivePlacement({ teamType, placement: resp }));
        dispatch(addPlacement({ teamType, placement: resp }));
      } else {
        // Откусывание расстановки
        // - после есть период (стоп новой расстановки == начало следующей)
        const placementsAfter = placementsInPeriod.filter(
          (pl) => pl.started_at > playedSec * 1000,
        );
        if (placementsAfter.length > 0) {
          endTime = placementsAfter[0].started_at;
        }
        startTime = playedSec * 1000;
        // Сокращаем текущую расстановку
        const updatedPlacement = await updatePlacementAPI(
          activePlacement.id,
          teamId,
          undefined,
          startTime,
        );
        const updatedPlacements = placementsByTeam.map((pl) => {
          if (pl.id == updatedPlacement.id) {
            return updatedPlacement;
          }
          return pl;
        });
        if (teamType == 'home') {
          dispatch(setHomePlacement(updatedPlacements));
        } else {
          dispatch(setAwayPlacement(updatedPlacements));
        }
        // После сокращения текущей расстановки - создаём новую
        const resp = await savePlacementAPI(
          placementId,
          teamId,
          activeTask.activity_id,
          startTime,
          endTime,
        );
        dispatch(setActivePlacement({ teamType, placement: resp }));
        dispatch(addPlacement({ teamType, placement: resp }));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const UpdatePlacement = (
  player: any,
  teamType: string,
  positionId: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const activeTask = getState().taskReducer.activeTask;
      const placementSchemas =
        getState().rawPlacementSchemasReducer.placementSchemas;
      if (!activeTask || placementSchemas.length == 0) {
        return;
      }
      let activePlacement: any;
      let placements: any;
      let teamId = 0;
      let setTeamPlacement: any;
      if (teamType == 'home') {
        activePlacement = getState().placementReducer.homeActivePlacement;
        placements = getState().placementReducer.homePlacements;
        teamId = activeTask.home_team_id;
        setTeamPlacement = setHomePlacement;
      } else {
        activePlacement = getState().placementReducer.awayActivePlacement;
        placements = getState().placementReducer.awayPlacements;
        teamId = activeTask.away_team_id;
        setTeamPlacement = setAwayPlacement;
      }
      const [curSchema] = placementSchemas.filter(
        (pl: any) => pl.id == activePlacement.schema,
      );
      let positions = [];
      if (activePlacement.positions) {
        positions = activePlacement.positions.filter(
          (pos: any) => pos.id != positionId,
        );
      }
      const [baseDataForPos = {}] = curSchema.positions.filter(
        (pos: any) => pos.id == positionId,
      );

      positions.push({
        ...baseDataForPos,
        id: positionId,
        player_id: player.id,
        player_number: player.number,
      });
      const resp = await updatePlacementAPI(
        activePlacement.id,
        teamId,
        positions,
      );
      dispatch(setActivePlacement({ teamType, placement: resp }));
      const updatedPlacements = placements.map((pl: Placement) => {
        if (pl.id == resp.id) {
          return resp;
        }
        return pl;
      });
      dispatch(setTeamPlacement(updatedPlacements));
    } catch (err) {
      console.log(err);
    }
  };
};

export const RemovePlayerFromPlacement = (
  teamType: string,
  positionId: any,
  playerId: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const activeTask = getState().taskReducer.activeTask;
      if (!activeTask) {
        return;
      }
      const placementSchemas =
        getState().rawPlacementSchemasReducer.placementSchemas;
      let placements: any;
      let activePlacement: any;
      let teamId = 0;
      let setTeamPlacement: any;
      if (teamType == 'home') {
        activePlacement = getState().placementReducer.homeActivePlacement;
        placements = getState().placementReducer.homePlacements;
        teamId = activeTask.home_team_id;
        setTeamPlacement = setHomePlacement;
      } else {
        activePlacement = getState().placementReducer.awayActivePlacement;
        placements = getState().placementReducer.awayPlacements;
        teamId = activeTask.away_team_id;
        setTeamPlacement = setAwayPlacement;
      }
      const [curSchema] = placementSchemas.filter(
        (pl: any) => pl.id == activePlacement.schema,
      );
      if (!curSchema.positions) {
        console.error('no positions');
        return;
      }
      let positions = [];
      if (activePlacement.positions && activePlacement.positions.length > 0) {
        positions = activePlacement.positions.filter(
          (pos: any) => pos.id != positionId,
        );
      }
      await removeAllEventsByPlayerAPI(playerId, activeTask.id);
      const placementFromBack = await updatePlacementAPI(
        activePlacement.id,
        teamId,
        positions,
      );
      dispatch(setActivePlacement({ teamType, placement: placementFromBack }));
      const updatedPlacements = placements.map((pl: Placement) => {
        if (pl.id == placementFromBack.id) {
          return placementFromBack;
        }
        return pl;
      });
      dispatch(setTeamPlacement(updatedPlacements));
      dispatch(GetCurrentEventsAction(activeTask.id));
    } catch (err) {
      console.log(err);
    }
  };
};

export const CutAndCloneByRedCard = (
  track: EventTrack,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const activeTask = getState().rawTaskReducer.task;
      const teamType = track.attributes
        .find((atr: Attribute) => atr.key == 'team')
        ?.values[0].value.toString()
        .toLowerCase();
      const playerNumber = track.attributes.find(
        (atr: Attribute) => atr.key == 'player',
      )?.values[0].value;
      let activePlacement: Placement = {} as Placement;
      const teamId =
        teamType == 'home' ? activeTask.home_team_id : activeTask.away_team_id;
      let placementsByTeam = [];
      if (teamType == 'home') {
        activePlacement = getState().placementReducer.homeActivePlacement;
        placementsByTeam = getState().placementReducer.homePlacements;
      } else {
        activePlacement = getState().placementReducer.awayActivePlacement;
        placementsByTeam = getState().placementReducer.awayPlacements;
      }
      const startTime = track.finished_at * 1000;
      const endTime = activePlacement.finished_at;

      // Редактируем текущий трек
      const updatedPlacement = await updatePlacementAPI(
        activePlacement.id,
        teamId,
        undefined,
        track.finished_at * 1000,
      );
      const updatedPlacements = placementsByTeam.map((pl) => {
        if (pl.id == updatedPlacement.id) {
          return updatedPlacement;
        }
        return pl;
      });
      if (teamType == 'home') {
        dispatch(setHomePlacement(updatedPlacements));
      } else {
        dispatch(setAwayPlacement(updatedPlacements));
      }
      // Создаём новый клон трек
      const resp = await savePlacementAPI(
        activePlacement.schema,
        teamId,
        activeTask.activity_id,
        startTime,
        endTime,
        activePlacement.positions?.filter(
          (pos) => pos.player_number !== playerNumber,
        ),
      );
      // Обноваляем расстановки
      dispatch(setActivePlacement({ teamType, placement: resp }));
      dispatch(addPlacement({ teamType, placement: resp }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const CutAndCloneBySubstitution = (
  track: EventTrack,
  // eslint-disable-next-line sonarjs/no-identical-functions
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const activeTask = getState().rawTaskReducer.task;
      const teamType = track.attributes
        .find((atr: Attribute) => atr.key == 'team')
        ?.values[0].value.toString()
        .toLowerCase();
      const playerNumberIn = track.attributes.find(
        (atr: Attribute) => atr.key == 'player_in',
      )?.values[0].value;
      const playerNumberOut = track.attributes.find(
        (atr: Attribute) => atr.key == 'player_out',
      )?.values[0].value;
      let activePlacement: Placement = {} as Placement;
      let mainStaff: any[];
      const teamId =
        teamType == 'home' ? activeTask.home_team_id : activeTask.away_team_id;
      let placementsByTeam = [];
      if (teamType == 'home') {
        activePlacement = getState().placementReducer.homeActivePlacement;
        placementsByTeam = getState().placementReducer.homePlacements;
        mainStaff = getState().gamePlayersReducer.homeMainStaff;
      } else {
        activePlacement = getState().placementReducer.awayActivePlacement;
        placementsByTeam = getState().placementReducer.awayPlacements;
        mainStaff = getState().gamePlayersReducer.awayMainStaff;
      }
      const startTime = track.finished_at * 1000;
      const endTime = activePlacement.finished_at;

      // Редактируем текущий трек
      const updatedPlacement = await updatePlacementAPI(
        activePlacement.id,
        teamId,
        undefined,
        track.finished_at * 1000,
      );
      const updatedPlacements = placementsByTeam.map((pl) => {
        if (pl.id == updatedPlacement.id) {
          return updatedPlacement;
        }
        return pl;
      });
      if (teamType == 'home') {
        dispatch(setHomePlacement(updatedPlacements));
      } else {
        dispatch(setAwayPlacement(updatedPlacements));
      }
      const updatedPositions: any[] = [];
      activePlacement.positions?.forEach((position) => {
        if (position.player_number.toString() === playerNumberOut?.toString()) {
          updatedPositions.push({
            ...position,
            player_number: Number(playerNumberIn),
            player_id: mainStaff.filter(
              (player: any) =>
                player.number.toString() === playerNumberIn?.toString(),
            )[0].id,
          });
        } else {
          updatedPositions.push(position);
        }
      });
      // Создаём новый клон трек
      const resp = await savePlacementAPI(
        activePlacement.schema,
        teamId,
        activeTask.activity_id,
        startTime,
        endTime,
        updatedPositions,
      );
      // Обноваляем расстановки
      dispatch(setActivePlacement({ teamType, placement: resp }));
      dispatch(addPlacement({ teamType, placement: resp }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const SetSwap = (player: {
  num: number;
  team: string;
}): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    dispatch(setSwapPlayer(player));
  };
};

export const SwapPlayer = (rawSecondPlayer: {
  num: number;
  team: string;
}): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const rawFirstPlayer = getState().placementReducer.swapPlayer;
      const activeTask = getState().rawTaskReducer.task;
      const curPlayerSec = getState().videoReducer.poorPerformancePlayedSec;
      let activePlacement: any;
      let placementsByTeam = [];
      let teamId = 0;
      let setTeamPlacement: any;
      if (rawSecondPlayer.team == 'home') {
        activePlacement = getState().placementReducer.homeActivePlacement;
        placementsByTeam = getState().placementReducer.homePlacements;
        teamId = activeTask.home_team_id;
        setTeamPlacement = setHomePlacement;
      } else {
        activePlacement = getState().placementReducer.awayActivePlacement;
        placementsByTeam = getState().placementReducer.awayPlacements;
        teamId = activeTask.away_team_id;
        setTeamPlacement = setAwayPlacement;
      }
      if (activePlacement?.positions.length == 0) {
        return;
      }
      const [firstPlayer = undefined] = activePlacement.positions.filter(
        (pos: PlacementPosition) => pos.player_number == rawFirstPlayer.num,
      );
      const [secondPlayer = undefined] = activePlacement.positions.filter(
        (pos: PlacementPosition) => pos.player_number == rawSecondPlayer.num,
      );
      if (!firstPlayer || !secondPlayer) {
        return;
      }
      const newPositions = activePlacement.positions.filter(
        (pos: PlacementPosition) =>
          pos.player_id !== firstPlayer.player_id &&
          pos.player_id !== secondPlayer.player_id,
      );
      newPositions.push({
        ...firstPlayer,
        player_id: secondPlayer.player_id,
        player_number: secondPlayer.player_number,
      });
      newPositions.push({
        ...secondPlayer,
        player_id: firstPlayer.player_id,
        player_number: firstPlayer.player_number,
      });
      const newActivePlacement = {
        ...activePlacement,
        positions: newPositions,
      };
      const respUpd = await updatePlacementAPI(
        activePlacement.id,
        teamId,
        undefined,
        curPlayerSec * 1000,
      );
      const respNewSave = await savePlacementAPI(
        activePlacement.schema,
        teamId,
        activeTask.activity_id,
        curPlayerSec * 1000,
        activePlacement.finished_at,
        newActivePlacement.positions,
      );
      const updatedPlacements = placementsByTeam.map((pl: Placement) => {
        if (pl.id == respUpd.id) {
          return respUpd;
        }
        return pl;
      });
      updatedPlacements.push(respNewSave);
      dispatch(setTeamPlacement(updatedPlacements));
      dispatch(SetSwap({ num: 0, team: '' }));
      dispatch(
        setActivePlacement({
          teamType: rawSecondPlayer.team,
          placement: respNewSave,
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };
};
