import React, { FC } from 'react';

import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { SeveHotKeysAction } from 'store/actions/player.actions';
import { AppStateType } from 'store/reducers';

import './hotkeysFooter.css';

export const HotkeysFooter: FC<any> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { hotkeysForm, hotkeyFormSaving, playerSettingsError } = useSelector(
    (state: AppStateType) => state.playerReducer,
  );
  const hasError = hotkeysForm.some((hk) => hk.error);
  const handleSaveHotkeys = async () => {
    await dispatch(SeveHotKeysAction(onClose));
  };
  return (
    <div className="hotkeys-drawer-footer">
      <Button onClick={onClose}>Отменить</Button>
      <Button
        loading={hotkeyFormSaving}
        type="primary"
        disabled={hasError}
        onClick={handleSaveHotkeys}
        danger={!!playerSettingsError}
      >
        Сохранить
      </Button>
    </div>
  );
};
