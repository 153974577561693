import { createSlice } from '@reduxjs/toolkit';

import { State } from 'types/app';

import { ActualEvent } from '../types';

const initialState: InitialStateType = {
  actualEvents: [],
  state: State.init,
};

type InitialStateType = {
  actualEvents: [ActualEvent] | [];
  state: State;
};

export const rawActualEventsSlice = createSlice({
  name: 'rawActualEventsSlice',
  initialState: initialState,
  reducers: {
    setActualEvents(state, action) {
      state.actualEvents = action.payload;
    },
  },
});
