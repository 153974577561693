import { ThunkAction } from 'redux-thunk';

import { getActiveTask } from 'api';
import { rawTaskSlice } from 'store/raw/slices/rawTaskSlice';
import { AppStateType } from 'store/reducers';

import { Task } from '../types';

const { setTask } = rawTaskSlice.actions;

export const GetTask = (): ThunkAction<
  Promise<Task>,
  AppStateType,
  undefined,
  any
> => {
  return async (dispatch) => {
    try {
      const response = await getActiveTask();
      dispatch(setTask(response));
      return response;
    } catch (err) {
      console.log(err);
    }
  };
};
