import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  messageText: string;
  show: boolean;
};
const initialState: InitialStateType = {
  messageText: '',
  show: false,
};
export const messagesReducer = createSlice({
  name: 'messagesReducer',
  initialState: initialState,
  reducers: {
    setMessagesAction(state, action) {
      state.messageText = action.payload.message;
      state.show = true;
    },
    hideMessageAction(state) {
      state.messageText = '';
      state.show = false;
    },
  },
});
