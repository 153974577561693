import { ACCEPT } from './_const';

export const createCommonHeader = () => {
  let headers = {};
  const token = localStorage.getItem('authtoken');
  if (token) {
    headers = {
      Accept: ACCEPT,
      Authorization: `Bearer ${token}`,
    };
  }
  return headers;
};
