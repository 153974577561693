import React, { FC } from 'react';

import { Flex, Input, Space } from 'antd';
import { useDispatch } from 'react-redux';

import { KEYKODES_TO_CHAR } from 'constants/constants';
import {
  SetHotKeyAction,
  SetIsHotkeysManagerListens,
} from 'store/actions/player.actions';

export const HotkeysListItem: FC<any> = ({ item }) => {
  const dispatch = useDispatch();

  const keyDisplay =
    item.key == null ? '' : KEYKODES_TO_CHAR[item.key] || 'unsup char';
  const handleKeydown = (e: any) => {
    e.preventDefault();
    if (e.key === 'Tab' || e.key === 'Shift') {
      return;
    }
    if (e.key !== 'Meta') {
      if (e.key === 'Backspace') {
        dispatch(SetHotKeyAction(item.id, item.label, null));
        return;
      }
      dispatch(SetHotKeyAction(item.id, item.label, e.keyCode));
    }
  };
  const handleFocuse = () => {
    dispatch(SetIsHotkeysManagerListens(false));
  };
  const handleBlur = () => {
    dispatch(SetIsHotkeysManagerListens(true));
  };
  return (
    <Flex justify="space-between" align="center">
      <span>{item.label}</span>
      <Space.Compact style={{ position: 'relative' }}>
        <Input
          value={item.key == 'null' ? '' : keyDisplay}
          onFocus={handleFocuse}
          onBlur={handleBlur}
          onKeyDown={handleKeydown}
          status={item.error ? 'error' : ''}
        />
      </Space.Compact>
    </Flex>
  );
};
