import { createSlice } from '@reduxjs/toolkit';

import { State } from 'types/app';

const initialState: InitialStateType = {
  state: State.init,
  hasError: '', // TODO нужно сделать систему показа что была ошибка
};

type InitialStateType = {
  state: State;
  hasError: string;
};

export const appSlice = createSlice({
  name: 'appSlice',
  initialState: initialState,
  reducers: {
    setAppState(state, action) {
      state.state = action.payload;
    },
    // TODO нужно сделать систему показа что была ошибка
    setHasError(state, action) {
      state.hasError = action.payload;
    },
  },
});
