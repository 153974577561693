/* eslint-disable quotes */

import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { SPORTS_IMAGE_ASPECT_RATIO, filePath } from 'constants/constants';
import { AppStateType } from 'store/reducers';

import { PlayersField } from './PlayersField';
import { SelectField } from './SelectPlayer';

import '../style.css';

export const PlayersFieldContainer: FC<any> = () => {
  const task = useSelector((state: AppStateType) => state.rawTaskReducer.task);
  const currentFastEvent = useSelector(
    (state: AppStateType) => state.fastEventReducer.currentFastEvent,
  );
  const urlToBackgroundImage = useSelector(
    (state: AppStateType) => state.playerReducer.urlToBackgroundImage,
  );
  let style = '';
  if (SPORTS_IMAGE_ASPECT_RATIO[task?.sport?.name.toLowerCase()]) {
    style =
      SPORTS_IMAGE_ASPECT_RATIO[task?.sport?.name.toLowerCase()].aspectRatio;
  }
  return (
    <div className="playersFieldContainer" style={{ aspectRatio: style }}>
      <div
        className="bgImage"
        style={{
          backgroundImage: `url('${filePath}/files/fields/${urlToBackgroundImage}')`,
        }}
      ></div>
      {currentFastEvent && currentFastEvent.id ? (
        <SelectField playerSize={21} />
      ) : (
        <PlayersField playerSize={21} />
      )}
    </div>
  );
};

export default PlayersFieldContainer;
