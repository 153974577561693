import React, { FC, useEffect, useState } from 'react';

import {
  DndContext,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { baseUpdateActualEventsAction } from 'store/actions/recorder.actions';
import { AppStateType } from 'store/reducers';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import { ActiveEventType } from 'types';
import { filterAndIndex } from 'utils/functions';

import { SortableAttributeItem } from './SortableAttributeItem';
import './index.css';

interface Interface {
  setIsModalOpen: any;
  isModalOpen: boolean;
}
export const AttributesReorderModal: FC<Interface> = ({
  setIsModalOpen,
  isModalOpen,
}) => {
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducer,
  );
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );
  const dispatch = useDispatch();
  const [reorderedAttributes, setReorderedAttributes] =
    useState<Array<ActiveEventType>>(activeEvents);
  function handleDragEnd(event: any) {
    const { active, over } = event;
    // console.log('active, over', active, over)
    if (active?.id !== over?.id) {
      // console.log('hrere')
      const payload: any = { target_event_id: active.id };
      if (
        active.data.current.sortable.index > over.data.current.sortable.index
      ) {
        payload.new_sibling_id = over.id;
      } else {
        payload.new_parent_id = over.id;
      }
      const oldIndex = activeEvents.findIndex((i: any) => i.id === active.id);
      const newIndex = activeEvents.findIndex((i: any) => i.id === over.id);
      // console.log('oldIndex, newIndex', oldIndex, newIndex);
      setReorderedAttributes(arrayMove(activeEvents, oldIndex, newIndex));
    }
  }
  useEffect(() => {
    setReorderedAttributes(activeEvents);
  }, [activeEvents]);
  const { setActiveEvents } = recorderReducerNew.actions;
  const applyReorder = () => {
    // console.log(reorderedAttributes);
    dispatch(baseUpdateActualEventsAction(reorderedAttributes));
    dispatch(setActiveEvents(reorderedAttributes));
    setIsModalOpen((prev: boolean) => !prev);
  };
  const toggleElementVisible = (id: number) => {
    const attr = filterAndIndex(reorderedAttributes, id, 'id');
    const alteredAttr = {
      ...attr.element,
      isVisible: !attr.element.isVisible,
    };
    setReorderedAttributes([
      ...reorderedAttributes.slice(0, attr.index),
      alteredAttr,
      ...reorderedAttributes.slice(attr.index + 1),
    ]);
  };
  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen((prev: boolean) => !prev)}
      maskClosable={true}
      footer={[
        <Button
          onClick={() => setIsModalOpen((prev: boolean) => !prev)}
          key={1}
        >
          Отменить
        </Button>,
        <Button
          onClick={applyReorder}
          className={'enabledClickable modalActionButton'}
          key={2}
        >
          Сохранить
        </Button>,
      ]}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          minHeight: '50vh',
        }}
      >
        <div className={'modalTitle'}>Настроить порядок</div>
        <div className={'sortableAttributesContainer'}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[
              restrictToVerticalAxis,
              restrictToFirstScrollableAncestor,
            ]}
          >
            <SortableContext
              items={reorderedAttributes}
              strategy={verticalListSortingStrategy}
            >
              {reorderedAttributes.map((attribute: ActiveEventType) => {
                return (
                  <SortableAttributeItem
                    key={`attr-${attribute.id}`}
                    id={attribute.id}
                    attribute={attribute}
                    toggleElementVisible={toggleElementVisible}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
        </div>
      </div>
    </Modal>
  );
};
