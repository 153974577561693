import React, { FC, useEffect, useState } from 'react';

import { Button, Col, ConfigProvider, Radio, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { AddNewPeriodEvent } from 'store/actions/recorder.actions';
import { AppStateType } from 'store/reducers';
import { ActiveEventType, AdditionalValueType } from 'types';
import './PeriodsAddControl.css';

const PERIODS_ORDER = [
  '1st time',
  '2nd time',
  '1st extra',
  '2nd extra',
  'Shootout',
];

export const PeriodsAddControl: FC<any> = () => {
  const {
    playerSettings: { color_settings = {} },
  } = useSelector((state: AppStateType) => state.playerReducer);

  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );

  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );

  const [teamValue, setTeamValue] = useState('Home');
  const [createdPeriods, setCreatedPeriods] = useState<any[]>([]);
  const [lastTeamPeriod, setLastTeamPeriod] = useState('');

  const selectedHomeColor = color_settings?.Home;
  const selectedAwayColor = color_settings?.Away;
  const dispatch = useDispatch();

  useEffect(() => {
    const periods = activeEvents.filter(
      (evt: ActiveEventType) => evt.name === 'Period',
    );
    if (periods.length > 0) {
      setCreatedPeriods(periods[0].tracks);
      const [lastTrack] = periods[0].tracks.slice(-1);
      if (!lastTrack) {
        return;
      }
      const [team] = lastTrack.attributes.filter(
        (atr: any) =>
          atr.values[0].value.toLowerCase() == 'away' ||
          atr.values[0].value.toLowerCase() == 'home',
      );
      if (team && team.values.length > 0) {
        setLastTeamPeriod(team.values[0].value + '');
      }
    } else {
      setCreatedPeriods([]);
      setLastTeamPeriod('');
    }
  }, [activeEvents]);
  // Попросили не принимать решение за пользователя
  useEffect(() => {
    setTeamValue('');
  }, [lastTeamPeriod]);

  const addPeriod = () => {
    if (!(activeTask && activeTask.periods && createdPeriods.length < 5)) {
      return;
    }
    let newStart;

    const DEFAULT_PERIOD_LENGTH = Math.floor(
      activeTask.video_length / (PERIODS_ORDER.length + 1),
    );
    const DEFAULT_PERIOD_INDENT = Math.floor(
      DEFAULT_PERIOD_LENGTH / PERIODS_ORDER.length,
    );

    const nextPeriodName = PERIODS_ORDER[createdPeriods.length];
    const av = activeTask.periods[0];

    const [periodAttribute] = av.additional_values.filter(
      (val: AdditionalValueType) => val.value === nextPeriodName,
    );
    const [teamAttribute] = av.additional_values.filter(
      (val: AdditionalValueType) =>
        val.key === 'team' &&
        val.value.toLowerCase() === teamValue.toLowerCase(),
    );

    if (createdPeriods.length > 0) {
      newStart =
        createdPeriods[createdPeriods.length - 1]?.stop + DEFAULT_PERIOD_INDENT;
    } else {
      newStart = DEFAULT_PERIOD_INDENT;
    }
    if (newStart >= activeTask.video_length) {
      return;
    }
    if (newStart + DEFAULT_PERIOD_LENGTH > activeTask.video_length) {
      dispatch(
        AddNewPeriodEvent(
          periodAttribute,
          teamAttribute,
          teamValue,
          newStart,
          activeTask.video_length,
        ),
      );
    } else {
      dispatch(
        AddNewPeriodEvent(
          periodAttribute,
          teamAttribute,
          teamValue,
          newStart,
          newStart + DEFAULT_PERIOD_LENGTH,
        ),
      );
    }
  };
  return (
    <div className="add-periods-wrapper">
      <Row>
        <Col span={12}>
          <div style={{ marginBottom: '10px' }}>Команда слева</div>
          <ConfigProvider
            theme={{
              components: {
                Button: {
                  defaultBg: '#F5F5F5',
                  lineWidth: 3,
                },
                Radio: {
                  colorPrimary: '#000',
                  algorithm: true,
                },
              },
            }}
          >
            <div className="add-periods-control">
              <Radio.Group
                onChange={(e) => setTeamValue(e.target.value)}
                value={teamValue}
                className="teamSwitcherContainer"
                disabled={false}
              >
                <Button
                  disabled={
                    (createdPeriods.length === 1 ||
                      createdPeriods.length === 3) &&
                    lastTeamPeriod == 'Home'
                  }
                  style={{
                    borderColor: selectedHomeColor,
                    marginRight: '10px',
                  }}
                >
                  <Radio
                    disabled={
                      (createdPeriods.length === 1 ||
                        createdPeriods.length === 3) &&
                      lastTeamPeriod == 'Home'
                    }
                    value={'Home'}
                  >
                    {activeTask?.home_team_name || 'Home'}
                  </Radio>
                </Button>
                <Button
                  disabled={
                    (createdPeriods.length === 1 ||
                      createdPeriods.length === 3) &&
                    lastTeamPeriod == 'Away'
                  }
                  style={{ borderColor: selectedAwayColor }}
                >
                  <Radio
                    disabled={
                      (createdPeriods.length === 1 ||
                        createdPeriods.length === 3) &&
                      lastTeamPeriod == 'Away'
                    }
                    value={'Away'}
                  >
                    {activeTask?.away_team_name || 'Away'}
                  </Radio>
                </Button>
              </Radio.Group>
            </div>
          </ConfigProvider>
        </Col>
        <Col span={12}>
          <div style={{ marginBottom: '10px' }}>
            {createdPeriods.length} периодов из 5
          </div>
          <Button
            block
            disabled={
              createdPeriods.length >= 5 ||
              // lastTeamPeriod == teamValue ||
              teamValue == ''
            }
            style={{ flex: 1 }}
            onClick={addPeriod}
          >
            Добавить период
          </Button>
        </Col>
      </Row>
    </div>
  );
};
