import React, { FC, useState } from 'react';

import { Col, Flex, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CloseTask from 'components/CloseTask';
import GameScore from 'components/GameScore';
import Logout from 'components/Logout';
import Messages from 'components/Messages';
import { OperatorSettings } from 'components/OperatorSettings';
import ProtocolButton from 'components/ProtocolButton';
import TeamSwitcher from 'components/TeamSwitcher';
// TODO REFACTOR TaskAcceptModal
import TaskAcceptModal from 'pages/OperatorWindow/components/TaskAcceptModal';
import { AppStateType } from 'store/reducers';
import './style.css';
import { messagesReducer } from 'store/reducers/messages.reducer';
import { protocolReducer } from 'store/reducers/protocol.reducer';
import { ActiveEventType, EventAttributeType, TrackType } from 'types';
import { formattedTime } from 'utils/functions';

export const OperatorEditorHeader: FC<any> = () => {
  const [showFinishModal, setShowFinishModal] = useState(false);
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducer,
  );
  const { setMessagesAction } = messagesReducer.actions;
  const showTaskModal = () => {
    const hasTask = activeTask && Object.keys(activeTask).length > 0;
    const taskIsWaiting = hasTask
      ? [10, 30, 40].includes(activeTask.status)
      : false;
    return !hasTask || taskIsWaiting;
  };
  const allowedToEdit = () => {
    //пока грязный хак
    const searchParams = new URLSearchParams(window.location.search);
    const taskId = searchParams.get('taskId');
    if (taskId) {
      return Number(searchParams.get('taskId')) !== activeTask?.id;
    }
    return showTaskModal() || showFinishModal;
  };
  const messages = () => {
    const m = [];
    if (activeTask && activeTask.description) {
      m.push(activeTask.description);
    }
    return m;
  };
  const hasMessages = Boolean(activeTask && activeTask.description);
  const dispatch = useDispatch();
  const getPassesWithoutAccuracy = () => {
    const tracks: TrackType[] = [];
    activeEvents.forEach((event: ActiveEventType) => {
      if (event.name === 'Pass') {
        event.tracks.forEach((track: TrackType) => {
          if (
            track.attributes.filter(
              (att: EventAttributeType) => att.key === 'accuracy',
            ).length === 0
          ) {
            tracks.push(track);
          }
        });
      }
    });
    return tracks;
  };
  const finishTask = () => {
    const passes = getPassesWithoutAccuracy();
    if (passes.length > 0) {
      const message = passes.map(
        (pass: TrackType) => `#${pass.id} - ${formattedTime(pass.start)}<br/>`,
      );
      dispatch(setMessagesAction({ message: message }));
    } else {
      setShowFinishModal(true);
    }
  };
  const { toggleGameProtocol } = protocolReducer.actions;
  const showProtocol = () => {
    dispatch(toggleGameProtocol(true));
  };
  return (
    <Row gutter={8}>
      <Col span={7}></Col>
      <Col span={7}>
        <Flex gap={8} justify="flex-start" align="center">
          <OperatorSettings />
          <GameScore />
          <TeamSwitcher />
        </Flex>
      </Col>
      <Col span={10} style={{ alignContent: 'center', display: 'flex' }}>
        <Flex
          wrap="wrap"
          gap="small"
          justify="flex-end"
          align="center"
          flex={1}
        >
          <Messages messages={messages()} hasMessages={hasMessages} />
          <ProtocolButton showProtocol={showProtocol} />
          <CloseTask finishTask={finishTask} />
          <Logout />
        </Flex>
        {/* TODO REFACTOR TaskAcceptModal */}
        {allowedToEdit() && (
          <TaskAcceptModal
            setShowFinishModal={setShowFinishModal}
            onClickOustide={() => setShowFinishModal}
          />
        )}
      </Col>
    </Row>
  );
};
