import React from 'react';
const CopyIconGrey = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 24C23.1046 24 24 23.1046 24 22V11C24 9.89543 23.1046 9 22 9H19H11C9.89543 9 9 9.89543 9 11V19V22C9 23.1046 9.89543 24 11 24H22Z"
        fill="#9FAAB8"
      />
      <path
        d="M19 9H11C9.89543 9 9 9.89543 9 11V19M19 9H22C23.1046 9 24 9.89543 24 11V22C24 23.1046 23.1046 24 22 24H11C9.89543 24 9 23.1046 9 22V19M19 9V6C19 4.89543 18.1046 4 17 4H6C4.89543 4 4 4.89543 4 6V17C4 18.1046 4.89543 19 6 19H9"
        stroke="#9FAAB8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default CopyIconGrey;
