import { createSlice } from '@reduxjs/toolkit';

import { State } from 'types/app';

import { Task } from '../types';

const initialState: InitialStateType = {
  task: {} as Task,
  state: State.init,
};

type InitialStateType = {
  task: Task;
  state: State;
};

export const rawTaskSlice = createSlice({
  name: 'rawTaskSlice',
  initialState: initialState,
  reducers: {
    setTask(state, action) {
      state.task = action.payload;
    },
  },
});
