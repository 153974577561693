import { createSlice } from '@reduxjs/toolkit';

type InitialStateType = {
  alertText: string;
  type: 'success' | 'info' | 'warning' | 'error';
  show: boolean;
};
const initialState: InitialStateType = {
  alertText: '',
  type: 'success',
  show: false,
};
export const alertReducer = createSlice({
  name: 'alertReducer',
  initialState: initialState,
  reducers: {
    setNotificationAction(state, action) {
      state.alertText = action.payload.message;
      state.type = action.payload.type;
      state.show = true;
    },
    hideNotificationAction(state) {
      state.alertText = '';
      state.type = 'success';
      state.show = false;
    },
  },
});
