import { ThunkAction } from 'redux-thunk';

import { FIELD_IMAGES } from 'constants/constants';
import { SetVideoData } from 'store/actions/video.action';
import { GetActualEvents } from 'store/raw/actions/rawActualEvents.actions';
import {
  GetPlacementSchemas,
  GetPlacements,
} from 'store/raw/actions/rawPlacementSchemas.actions';
import { GetSettings } from 'store/raw/actions/rawSettings.actions';
import { GetTask } from 'store/raw/actions/rawTask.actions';
import { Task } from 'store/raw/types';
import { AppStateType } from 'store/reducers';
import { appSlice } from 'store/slices/appSlice';
import { State } from 'types/app';

import { SetUrlToBackgroundImage } from './player.actions';

const { setAppState, setHasError } = appSlice.actions;
export const AppInit = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => {
  return async (dispatch) => {
    try {
      dispatch(setAppState(State.pending));
      // TODO find how set return type from GetTask()
      //@ts-expect-error
      const activeTask: Task = await dispatch(GetTask());
      await dispatch(GetSettings());
      await dispatch(GetPlacementSchemas(activeTask.sport.id.toString()));
      await dispatch(GetPlacements(Number(activeTask.activity_id)));
      await dispatch(GetActualEvents(activeTask.id));
      await dispatch(SetVideoData(activeTask));
      await dispatch(
        SetUrlToBackgroundImage(FIELD_IMAGES[activeTask.sport.id][0].value),
      );
      dispatch(setAppState(State.fulfilled));
    } catch (err: any) {
      dispatch(setAppState(State.error));
      dispatch(setHasError(err));
    }
  };
};
