import { ThunkAction } from 'redux-thunk';

import * as API from 'api/index';
import { AppStateType } from 'store/reducers';
import { playerReducer } from 'store/reducers/player.reducer';
import { findRepeatingValues, getUniqueKeys } from 'utils/functions';

const {
  setPlayerSettingsAction,
  setColorSettings,
  setPlayerSettingsError,
  setInverseTeamDirection,
  setHotkeysDisplay,
  updateHotkeyForm,
  updateHotkeysFormRepeatErrors,
  setHotkeyFormSaving,
  setIsHotkeysManagerListens,
  setUrlToBackgroundImage,
} = playerReducer.actions;
/*
 *  Get video player settings
 */
export const GetPlayerSettingsAction = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => {
  return async (dispatch) => {
    try {
      const response = await API.getPlayerSettings();
      dispatch(setPlayerSettingsAction(response));
    } catch (e) {
      dispatch(setPlayerSettingsError(e));
    }
  };
};
export const SetHotkeyFormSaving = (
  loading?: boolean,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    await dispatch(setHotkeyFormSaving(loading));
  };
};
/*
 *  Save Hotkeys to server
 *  TODO WARNING DANGER dupe key by lable
 */
export const SeveHotKeysAction = (
  callBackFn: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      const hotkeys = getState().playerReducer.hotkeysForm;
      const payload: any = hotkeys.map((hk) => ({ id: hk.id, key: hk.key }));
      await dispatch(SetHotkeyFormSaving(true));
      await API.updateSettings({
        hotkeys: payload,
      });
      await dispatch(GetPlayerSettingsAction());
      await dispatch(SetHotkeyFormSaving(false));
      callBackFn();
    } catch (e) {
      await dispatch(SetHotkeyFormSaving(false));
      dispatch(setPlayerSettingsError(e));
    }
  };
};
export const SetHotKeyAction = (
  id: any,
  label: string,
  keyCode: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      await dispatch(updateHotkeyForm({ id, label, keyCode }));
      const formKeys = getUniqueKeys(getState().playerReducer.hotkeysForm);
      const repeatingValues = findRepeatingValues(formKeys);
      await dispatch(updateHotkeysFormRepeatErrors(repeatingValues));
    } catch (e) {
      console.error(e);
      dispatch(setPlayerSettingsError(e));
    }
  };
};
/*
 * Update color settings for the current user
 */
export const SetColorSettingsAction = (
  colorSettings?: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch, getState) => {
    try {
      await dispatch(setColorSettings(colorSettings));
      await API.updateSettings({
        color_settings: getState().playerReducer.playerSettings.color_settings,
      });
    } catch (e) {
      dispatch(setPlayerSettingsError(e));
    }
  };
};

export const SetInverseTeamDirection = (
  direction?: boolean,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    await dispatch(setInverseTeamDirection(direction));
  };
};

export const SetHotkeysDisplay = (
  status?: boolean,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    await dispatch(setHotkeysDisplay(status));
  };
};

export const SetIsHotkeysManagerListens = (
  status?: boolean,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    await dispatch(setIsHotkeysManagerListens(status));
  };
};

export const SetUrlToBackgroundImage = (
  url: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    await dispatch(setUrlToBackgroundImage(url));
  };
};
