/* eslint-disable indent */
import React, { FC, useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { UpdatePlacement } from 'store/actions/placements.action';
import { AppStateType } from 'store/reducers';

import PlayesManagerDrawer from '../PlayersManager/PlayersManagerDrawer';

export const PlayersFieldSelect: FC<any> = ({
  team,
  close,
  positionId,
  invertedText = false,
  selectPlayer,
  exclude = [],
}) => {
  const dispatch = useDispatch();
  const { color } = team;
  const name = team.name.toLowerCase();
  const mainStaff =
    name == 'home'
      ? useSelector(
          (state: AppStateType) => state.gamePlayersReducer.homeMainStaff,
        )
      : useSelector(
          (state: AppStateType) => state.gamePlayersReducer.awayMainStaff,
        );
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const playerSelect = (player: any) => {
    if (positionId) {
      dispatch(UpdatePlacement(player, team.name, positionId));
    }
    if (selectPlayer) {
      if (team.name == 'away') {
        const fixTeam = { ...team, name: 'Away' };
        selectPlayer(fixTeam, player.number, false);
      } else {
        selectPlayer(team, player.number, false);
      }
    }
    close();
  };

  return (
    <div className="playersFieldSelect">
      <Row gutter={8}>
        {mainStaff.map((player: any) => {
          const isUsed = exclude.indexOf(player.number) > -1;
          return (
            <Col span={12} key={player.id}>
              <div
                className={clsx('playerData', isUsed && 'disabled')}
                onClick={() => {
                  if (!isUsed) {
                    playerSelect(player);
                  }
                }}
              >
                <div
                  className="playerNumber"
                  style={{
                    backgroundColor: color,
                    color: invertedText ? '#fff' : '#000',
                  }}
                >
                  {player.number}
                </div>
                {player.name}
              </div>
            </Col>
          );
        })}
        <Col span={12}>
          <div
            className="playerData"
            style={{ color: '#1677FF' }}
            onClick={showDrawer}
          >
            <div className="playerNumber">
              <EditOutlined />
            </div>
            Управление игроками
          </div>
        </Col>
      </Row>
      <PlayesManagerDrawer
        open={open}
        setOpen={setOpen}
        team={team}
        invertedText={invertedText}
      />
    </div>
  );
};
