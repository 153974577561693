/* eslint-disable import/order */
import React, { FC, useEffect, useState } from 'react';

import { ConfigProvider, Modal, Slider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  RemoveEditedEventAction,
  RemoveTrackActionV2,
} from 'store/actions/recorder.actions';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { FastEventType } from 'types';

import { SPORTS, SPORTS_IMAGE_ASPECT_RATIO } from 'constants/constants';
import { SelectField } from '../OperatorEditorPlayers/PlayersField/SelectPlayer';
import { SelectCoordinateModalContent } from './SelectCoordinateModalContent';
import './style.css';

export const OperatorEditorFieldCoordinatesModal: FC<any> = () => {
  const dispatch = useDispatch();
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { showCoordinatesModal, currentFastEvent } = useSelector(
    (state: AppStateType) => state.fastEventReducer,
  );
  const { editedEvents, playerIsSkipped } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const task = useSelector((state: AppStateType) => state.rawTaskReducer.task);
  let style = '';
  if (SPORTS_IMAGE_ASPECT_RATIO[task?.sport?.name.toLowerCase()]) {
    style =
      SPORTS_IMAGE_ASPECT_RATIO[task?.sport?.name.toLowerCase()].aspectRatio;
  }
  const { setShowCoordinatesModal, removeFastEventAction } =
    fastEventReducer.actions;

  const [opacity, setOpacity] = useState(
    sessionStorage.getItem('fieldOpacity') || 0.4,
  );
  const [playerOpacity, setPlayerOpacity] = useState(
    sessionStorage.getItem('playerOpacity') || 0.4,
  );
  const [playerSize, setPlayerSize] = useState(
    sessionStorage.getItem('playerSize') || 0.4,
  );
  const [isPlayerSelect, setIsPlayerSelect] = useState(false);
  const [activeEvent, setActiveEvent] = useState<FastEventType | null>(null);
  useEffect(() => {
    const func = async () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      const edited = editedEvents.filter((evt) => evt.name !== 'Possession')[0];
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      activeTask &&
        (await setActiveEvent({
          ...activeTask?.task_fast_events?.filter(
            (evt: FastEventType) => evt?.id === edited?.track.fast_event,
          )[0],
        }));
    };
    func().then();
  }, [editedEvents, showCoordinatesModal]);

  const handleClose = async () => {
    const editedEvent = editedEvents.filter(
      (evt: any) => evt.name !== 'Possession' && evt.name !== 'Period',
    );
    if (activeEvent && editedEvent.length > 0) {
      // если событие только записывается
      if (editedEvent[0]?.track?.id === -1) {
        await dispatch(
          RemoveTrackActionV2(editedEvent[0].track.id, editedEvent[0].id),
        );
      } else {
        await dispatch(RemoveEditedEventAction(activeEvent));
      }
    }
    await dispatch(setShowCoordinatesModal(false));
    await dispatch(removeFastEventAction());
  };
  const handleOpacityChange = (val: number) => {
    const cssOpacity = (val / 100).toFixed(2);
    sessionStorage.setItem('fieldOpacity', cssOpacity);
    setOpacity(cssOpacity);
  };

  const handlePlayerOpacityChange = (val: number) => {
    const cssPlayerOpacity = (val / 100).toFixed(2);
    sessionStorage.setItem('playerOpacity', cssPlayerOpacity);
    setPlayerOpacity(cssPlayerOpacity);
  };

  const handlePlayerSizeChange = (val: number) => {
    sessionStorage.setItem('playerSize', val.toString());
    setPlayerSize(val);
  };
  useEffect(() => {
    if (editedEvents?.length <= 0 || !currentFastEvent?.id) {
      return;
    }
    if (!currentFastEvent.has_coords) {
      setIsPlayerSelect(true);
      return;
    }
    if (currentFastEvent.participants == 0) {
      setIsPlayerSelect(false);
      return;
    }
    const editedEventTrack = editedEvents[0].track;
    if (currentFastEvent.participants == 1) {
      if (
        editedEventTrack.attributes &&
        editedEventTrack.attributes.length < 2
      ) {
        setIsPlayerSelect(true);
        return;
      }
      const isValid =
        editedEventTrack.attributes.filter(
          (atr: any) => atr.key == 'player' || atr.key == 'team',
        ).length == 2;
      if (!isValid) {
        setIsPlayerSelect(true);
      } else {
        setIsPlayerSelect(false);
      }
      return;
    }
    if (currentFastEvent.participants == 2) {
      const correction =
        currentFastEvent.player_skip && playerIsSkipped ? 2 : 3;
      if (
        editedEventTrack.attributes &&
        editedEventTrack.attributes.length < correction
      ) {
        setIsPlayerSelect(true);
        return;
      }
      const isValid =
        editedEventTrack.attributes.filter(
          (atr: any) =>
            atr.key == 'player' ||
            atr.key == 'team' ||
            atr.key == 'player_in' ||
            atr.key == 'player_out' ||
            atr.key == 'player_home' ||
            atr.key == 'player_away' ||
            atr.key == 'player_opposite',
        ).length >= correction;
      if (!isValid) {
        setIsPlayerSelect(true);
      } else {
        setIsPlayerSelect(false);
      }
    }
  }, [editedEvents, currentFastEvent, playerIsSkipped]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            contentBg: 'rgba(0, 0, 0, 0.1)',
          },
        },
      }}
    >
      <Modal
        maskClosable={false}
        open={showCoordinatesModal}
        keyboard={false}
        onCancel={handleClose}
        width={'fit-content'}
        footer={null}
        style={{
          top: activeTask?.sport?.id === 1 ? '5rem' : '9rem',
          right: '-20rem',
        }}
        styles={{
          mask: {
            background: 'transparent',
          },
        }}
        mask={false}
        className="field-modal"
      >
        <div className="fieldOpacitySliderContainer">
          <Slider
            defaultValue={Math.floor(+opacity * 100)}
            min={5}
            max={100}
            vertical
            onChange={handleOpacityChange}
          />
        </div>
        <div className="fieldOpacityPlayerSliderContainer">
          <Slider
            defaultValue={Math.floor(+playerOpacity * 100)}
            min={0}
            max={100}
            vertical
            onChange={handlePlayerOpacityChange}
          />
        </div>
        <div className="fieldSizePlayerSliderContainer">
          <Slider
            defaultValue={+playerSize}
            min={0}
            max={60}
            vertical
            onChange={handlePlayerSizeChange}
          />
        </div>
        {!isPlayerSelect && (
          <SelectCoordinateModalContent
            opacity={opacity}
            playerSize={playerSize}
            playerOpacity={playerOpacity}
          />
        )}
        {isPlayerSelect && (
          <div
            className={`coordinatesModalContainer ${activeTask ? SPORTS[activeTask.sport.id] : ''}`}
            style={{ aspectRatio: style }}
          >
            <SelectField
              playerSize={playerSize}
              popoverDisable={true}
              playerOpacity={playerOpacity}
            />
          </div>
        )}
      </Modal>
    </ConfigProvider>
  );
};
