import React, { FC } from 'react';

import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';

import {
  OperatorEditorBreadcrumb,
  PlayersFieldContainer,
} from 'components/OperatorEditor/';
import OperatorEditorEvents from 'components/OperatorEditor/OperatorEditorEvents/OperatorEditorEvents';
import { AppStateType } from 'store/reducers';

export const OperatorEditorLeftMenu: FC<any> = () => {
  const { currentFastEvent } = useSelector(
    (state: AppStateType) => state.fastEventReducer,
  );
  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>{currentFastEvent && <OperatorEditorBreadcrumb />}</Col>
      <Col span={24}>
        <PlayersFieldContainer />
      </Col>
      <Col span={24}>
        <OperatorEditorEvents />
      </Col>
    </Row>
  );
};
