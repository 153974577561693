import React, { FC } from 'react';

import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Modal } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import {
  RemovePlayerFromPlacement,
  SetSwap,
  SwapPlayer,
} from 'store/actions/placements.action';
import { AppStateType } from 'store/reducers';

//const { confirm } = Modal;

const items: MenuProps['items'] = [
  {
    key: '1',
    label: <div>Удалить</div>,
  },
  {
    key: '2',
    label: <div>Своп</div>,
  },
  // {
  //   key: '3',
  //   label: <div>Change wrong number</div>,
  // },
];

const showDeletePromiseConfirm = (
  teamName: any,
  positionId: any,
  playerId: any,
  dispatch: any,
) => {
  Modal.confirm({
    title: 'Delete player',
    icon: <ExclamationCircleFilled />,
    content:
      'Are you sure you want to delete? All events with this player will be permanently removed',
    onOk: async () => {
      await dispatch(RemovePlayerFromPlacement(teamName, positionId, playerId));
    },
    onCancel() {},
    okText: 'Delete',
    cancelText: 'Cancel',
  });
};
// TODO next time
// const showReplacePromiseConfirm = (
//   teamName: any,
//   positionId: any,
//   playerId: any,
//   dispatch: any,
// ) => {
//   Modal.confirm({
//     title: 'Delete player',
//     icon: <ExclamationCircleFilled />,
//     content:
//       'Are you sure you want to delete? All events with this player will be permanently removed',
//     onOk: async () => {
//       await dispatch(removePlayerFromPlacement(teamName, positionId, playerId));
//     },
//     onCancel() {},
//     okText: 'Delete',
//     cancelText: 'Cancel',
//   });
// };

export const PlayersFieldItem: FC<any> = ({
  team,
  style,
  positionId,
  color,
  isActive,
  selectPlayer,
  activePlayerPos = [],
  invertedText = false,
  replacedPlayerNumber,
}) => {
  const dispatch = useDispatch();
  const { swapPlayer } = useSelector(
    (state: AppStateType) => state.placementReducer,
  );
  const [activePos = {}] = activePlayerPos;
  const activeColor = invertedText ? '#fff' : '#000';
  const isSwap =
    swapPlayer.num == (replacedPlayerNumber || activePos.player_number) &&
    swapPlayer.team == team.name;
  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key == '1') {
      showDeletePromiseConfirm(
        team.name,
        positionId,
        activePos.player_id,
        dispatch,
      );
    }
    if (key == '2') {
      dispatch(
        SetSwap({
          num: replacedPlayerNumber || activePos.player_number,
          team: team.name,
        }),
      );
    }
  };
  return (
    <Dropdown
      menu={{ items, onClick }}
      placement="bottom"
      disabled={!activePos.player_number}
      arrow={{ pointAtCenter: true }}
    >
      <div
        className={clsx({
          playersFieldItem: true,
          isActive: isSwap || isActive,
        })}
        onClick={(ev) => {
          ev.stopPropagation();
          if (
            isSwap ||
            (swapPlayer.team !== '' && swapPlayer.team !== team.name)
          ) {
            return;
          }
          if (swapPlayer.team !== '') {
            dispatch(
              SwapPlayer({
                num: replacedPlayerNumber || activePos.player_number,
                team: team.name,
              }),
            );
            return;
          }
          if (activePos.player_number) {
            return;
          }
          selectPlayer(team, positionId);
        }}
        style={{
          ...style,
          color:
            isSwap || isActive || activePos.player_number ? activeColor : color,
          backgroundColor:
            isSwap || isActive || activePos.player_number
              ? color
              : style.backgroundColor,
        }}
      >
        {!activePos.player_number && <PlusOutlined />}
        {activePos.player_number && (
          <div>{replacedPlayerNumber || activePos.player_number}</div>
        )}
      </div>
    </Dropdown>
  );
};
