import React, { FC } from 'react';

export const BottomScroller: FC<any> = ({ scrollerRef, onScroll, width }) => {
  return (
    <div
      style={{ overflowX: 'scroll', marginLeft: '1rem', marginRight: '1rem' }}
      ref={scrollerRef}
      onScroll={(e: any) => onScroll(e.target?.scrollLeft)}
    >
      <div
        style={{
          width: `${width}%`,
          height: '10px',
          backgroundColor: 'rgb(240, 243, 247)',
        }}
      >
        &nbsp;
      </div>
    </div>
  );
};
