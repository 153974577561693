import React, { FC } from 'react';

import { Button } from 'antd';

interface ShowProtocolInterface {
  showProtocol: any;
}
const ProtocolButton: FC<ShowProtocolInterface> = ({ showProtocol }) => {
  return (
    <Button type="primary" onClick={showProtocol}>
      Протокол
    </Button>
  );
};
export default ProtocolButton;
