/* eslint-disable indent */
import React, { FC, useState } from 'react';

import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Card,
  Col,
  ConfigProvider,
  Drawer,
  InputNumber,
  Row,
  Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddNewPlayer,
  AddToMainPlayers,
  RemoveFromMainPlayers,
} from 'store/actions/gamePlayers.action';
import { AppStateType } from 'store/reducers';

export const PlayersManagerDrawer: FC<any> = ({
  open,
  setOpen,
  team,
  invertedText = false,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isAddPlayerActive, setAddPlayerActive] = useState(false);
  const [playerNumber, setPlayerNumber] = useState<null | number>(null);
  const name = team.name.toLowerCase();
  const mainStaff =
    name == 'home'
      ? useSelector(
          (state: AppStateType) => state.gamePlayersReducer.homeMainStaff,
        )
      : useSelector(
          (state: AppStateType) => state.gamePlayersReducer.awayMainStaff,
        );
  const teamStaff =
    name == 'home'
      ? useSelector(
          (state: AppStateType) => state.gamePlayersReducer.homePlayers,
        )
      : useSelector(
          (state: AppStateType) => state.gamePlayersReducer.awayPlayers,
        );
  const addPlayer = () => {
    setAddPlayerActive(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChangeAddPlayer = (val: number | null) => {
    setPlayerNumber(val);
  };
  const savePlayer = () => {
    if (playerNumber == null) {
      return;
    }
    dispatch(AddNewPlayer(playerNumber, team.name.toLowerCase()));
    setPlayerNumber(null);
    setAddPlayerActive(false);
  };
  const movePlayerToMain = async (player: any) => {
    setIsLoading(true);
    await dispatch(AddToMainPlayers(player, team.name.toLowerCase()));
    setIsLoading(false);
  };
  const movePlayerToTeam = async (player: any) => {
    setIsLoading(true);
    await dispatch(RemoveFromMainPlayers(player, team.name.toLowerCase()));
    setIsLoading(false);
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: '#fff',
        },
      }}
    >
      <Drawer
        title="ИГРОКИ"
        onClose={onClose}
        open={open}
        placement={'left'}
        size={'large'}
        className="managePlayers"
      >
        <Spin tip="Обновление..." spinning={isLoading} delay={200}>
          <div className="playerContainerWrapper">
            <div className="playerContainer">
              <h3>ВСЕ ИГРОКИ</h3>
              <Row gutter={[16, 16]} className="overflowPlayersContainer">
                {teamStaff.map((player: any) => (
                  <Col span={12} key={player.id}>
                    <div
                      className="playerData"
                      onClick={() => {
                        movePlayerToMain(player);
                      }}
                    >
                      <div
                        className="playerNumber"
                        style={{
                          backgroundColor: team.color,
                          color: invertedText ? '#fff' : '#000',
                        }}
                      >
                        {player.number}
                      </div>
                      {player.name}
                      <PlusOutlined className="hoveredPlus" />
                    </div>
                  </Col>
                ))}
                {!isAddPlayerActive && (
                  <Col span={12}>
                    <div
                      className="playerData"
                      style={{ color: '#1677FF' }}
                      onClick={addPlayer}
                    >
                      <div
                        className="playerNumber"
                        style={{
                          backgroundColor: '#F5F5F5',
                        }}
                      >
                        <PlusOutlined />
                      </div>
                      Добавить игрока
                    </div>
                  </Col>
                )}
                {isAddPlayerActive && (
                  <Col span={12}>
                    <InputNumber
                      onKeyUp={(ev) => {
                        ev.stopPropagation();
                      }}
                      size="small"
                      value={playerNumber}
                      min={0}
                      onChange={onChangeAddPlayer}
                      onBlur={savePlayer}
                      addonAfter={<CheckOutlined />}
                    />
                  </Col>
                )}
              </Row>
            </div>

            <div className="playerContainer">
              <Card>
                <h3 style={{ marginTop: '0' }}>ИГРОКИ В МАТЧЕ</h3>
                {mainStaff.length == 0 && (
                  <p style={{ textAlign: 'center' }}>
                    В состав на матч не добавлено игроков. Добавьте игроков из
                    списка выше
                  </p>
                )}
                <Row gutter={16} className="overflowPlayersContainer">
                  {mainStaff.map((player: any) => (
                    <Col span={12} key={player.id}>
                      <div
                        className="playerData"
                        onClick={() => {
                          movePlayerToTeam(player);
                        }}
                      >
                        <div
                          className="playerNumber"
                          style={{
                            backgroundColor: team.color,
                            color: invertedText ? '#fff' : '#000',
                          }}
                        >
                          {player.number}
                        </div>
                        {player.name}
                        <CloseOutlined className="hoveredPlus" />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Card>
            </div>
          </div>
        </Spin>
      </Drawer>
    </ConfigProvider>
  );
};

export default PlayersManagerDrawer;
