/* eslint-disable indent */
import React, { FC, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { NewSegmentHandle } from 'components/OperatorEditor/OperatorEditorVideoTimeline/RecordedEvents/NewSegmentHandle';
import { RemoveEditedEventAction } from 'store/actions/recorder.actions';
import { SetIsPlaying } from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import { recorderReducerNew } from 'store/reducers/recorder.reducer';
import {
  ActiveEventType,
  FastEventType,
  NewSegmentType,
  TrackType,
} from 'types';

interface NewSegmentInterface {
  event: ActiveEventType;
  segment: NewSegmentType;
  parentWidth: any;
  videoLength: number;
  allSegments: Array<NewSegmentType>;
  setSelectedEventId: any;
  handleVideoScroll: (ms: number) => void;
  currentPosition: number;
}

export const NewSegment: FC<NewSegmentInterface> = ({
  segment,
  parentWidth,
  videoLength,
  setSelectedEventId,
  event,
  handleVideoScroll,
  currentPosition,
}) => {
  const dispatch = useDispatch();
  const copyDeleteModalRef = useRef(null);
  const leftDragHandleRef = useRef(null);
  const rightDragHandleRef = useRef(null);
  // const { selectTrackForEdit, setPlayerSkipped } = recorderReducerNew.actions;
  const { selectTrackForEdit } = recorderReducerNew.actions;
  // const { removeFastEventAction, setShowCoordinatesModal } =
  //   fastEventReducer.actions;
  const { setShowCoordinatesModal } = fastEventReducer.actions;
  // const { showCoordinatesModal } = useSelector(
  //   (state: AppStateType) => state.fastEventReducer,
  // ); // Редактируемые/записываемые в настоящий момент события
  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/no-shadow
  //   const handleClickOutside = async (event: any) => {
  //     console.log('click outside');
  //     // @ts-ignore
  //     if (
  //       recordedEventsRef.current &&
  //       recordedEventsRef.current.contains(event.target) &&
  //       copyDeleteModalRef.current &&
  //       //@ts-ignore
  //       !copyDeleteModalRef.current.contains(event.target) &&
  //       //@ts-ignore
  //       !leftDragHandleRef?.current?.contains(event.target) &&
  //       //@ts-ignore
  //       !rightDragHandleRef?.current?.contains(event.target) &&
  //       segment.id !== -1
  //     ) {
  //       setLocalEditedEvents({});
  //       await setLocalEditedEvent(null);
  //       setSelectedEventId(null);
  //       dispatch(removeFastEventAction());
  //       if (showCoordinatesModal) {
  //         await dispatch(setShowCoordinatesModal(false));
  //       }
  //       await dispatch(RemoveEditedEventAction(segment, undefined));
  //       await dispatch(setPlayerSkipped(false));
  //     }
  //   };
  //   document.addEventListener('click', handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside, true);
  //   };
  // }, [localEditedEvents]);

  const getWidth = () => {
    if (segment.isRecorded) {
      return parentWidth * ((currentPosition - segment.start) / videoLength);
    }
    return parentWidth * ((segment.stop - segment.start) / videoLength);
  };

  const getStart = () => {
    return parentWidth * (segment.start / videoLength);
  };

  const calculatePositionLeft = () => {
    return getStart() + getWidth();
  };
  const [posLeft, setPosLeft] = useState(calculatePositionLeft());
  useEffect(() => {
    setPosLeft(calculatePositionLeft());
  }, [segment.start, segment.stop, currentPosition]);

  const getTimeCoord = (time: number) => {
    return parentWidth * (time / videoLength);
  };
  const { updateEditedTrack } = recorderReducerNew.actions;
  const handleSegmentDrag = (e: any, newPosition: number, drager: any) => {
    if (segment.id) {
      handleVideoScroll(newPosition * videoLength * 1000);
      dispatch(
        updateEditedTrack({
          eventId: segment.eventId,
          trackId: segment.id | 0,
          start: drager === 'left' ? newPosition * videoLength : segment.start,
          stop: drager === 'right' ? newPosition * videoLength : segment.stop,
        }),
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { editedEvents, eventWindowMode } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { addFastEventAction } = fastEventReducer.actions;
  const { activeEvents } = useSelector(
    (state: any) => state.recorderReducerNew,
  );
  const detectFastEventFromEvent = async (
    eventId: number,
    trackId: string | number,
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (activeTask) {
      const fast_events: FastEventType[] = activeTask.task_fast_events.filter(
        (fe: FastEventType) => fe.event_id === eventId,
      );
      if (fast_events.length === 1) {
        await dispatch(addFastEventAction(fast_events[0]));
      } else {
        const track: TrackType = activeEvents
          .filter((evt: ActiveEventType) => evt.id === eventId)[0]
          .tracks.filter((tr: TrackType) => tr.id === trackId)[0];
        const fast_event: FastEventType[] = activeTask.task_fast_events.filter(
          (fe: FastEventType) => fe.id === track.fast_event,
        )[0];
        dispatch(setShowCoordinatesModal(true));
        return await dispatch(addFastEventAction(fast_event));
      }
    }
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const showEditModals = async (e: any, clicked: number | null) => {
    e.preventDefault();
    handleVideoScroll(segment.start * 1000);
    // обработка клика по треку для показа иконок копирования и удаления трека
    if (!segment.isRecorded && clicked !== null) {
      // редактируем только уже завершенную запись
      // if (localEditedEvent !== clicked) {
      // показываем модалку только если мы не редактируем какой-то другой трек этого события
      const currentEditedEvents: any = {};
      currentEditedEvents[segment.eventId] = clicked;
      // dispatch(SelectTrackForEditAction(segment.eventId, clicked));
      await detectFastEventFromEvent(segment.eventId, clicked);
      const otherEdited = editedEvents.filter(
        (evt: any) => !evt.name.includes('Possession'),
      )[0];
      if (otherEdited) {
        dispatch(RemoveEditedEventAction(otherEdited, undefined));
      }
      dispatch(
        selectTrackForEdit({ eventId: segment.eventId, trackId: clicked }),
      );
      setSelectedEventId(clicked);
      dispatch(SetIsPlaying(false));
      // } else {
      // если кликнули на уже редактируемое событие
      // if (localEditedEvent === clicked) {
      // если кликнули на уже редактируемый трек
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      // localEditedEvent === clicked
      //   ? setLocalEditedEvent(null)
      //   : setLocalEditedEvent(clicked);
      // await dispatch(UnselectTrackFoEditAction(segment.eventId));
      // dispatch(RemoveEditedEventAction(segment, undefined));
      // await setLocalEditedEvents(currentEditedEvents);
      // }
      // }
    }
  };

  const segmentIsViewed = () => {
    const offset =
      eventWindowMode && activeTask?.window_mode_width
        ? activeTask?.window_mode_width
        : 0;
    return (
      segment.start - offset <= currentPosition &&
      segment.stop + offset >= currentPosition
    );
  };
  const segmentClass = () => {
    const segmentFrameClassName = segmentIsViewed()
      ? 'currentlyViewedSegmentTrack'
      : 'newSegmentTrack';
    return `segment ${
      segment.id !== null
        ? segment.isRecorded
          ? 'recordedNewSegment '
          : segmentFrameClassName
        : ''
    }`;
  };
  const getSegmentStyle = () => {
    const width = getWidth();
    const style: any = {
      width: width,
    };
    if (width === 0) {
      style.position = 'relative';
      style.left = -4;
    }
    return style;
  };
  const handleDragKeyDown = (position: number) => {
    handleVideoScroll(position * videoLength * 1000);
  };
  return (
    <>
      {segment.hasLeftHandle && event.has_duration && (
        <NewSegmentHandle
          data-id={segment.id}
          handleKeyDown={handleDragKeyDown}
          handleRef={leftDragHandleRef}
          handleId={`${segment.id}-left-${segment.eventId}`}
          handleSegmentDrag={handleSegmentDrag}
          posLeft={getStart()}
          offsetLeft={-10}
          offsetRight={0}
          direction={'left'}
          limitLeft={getTimeCoord(segment.prev)}
          limitRight={getTimeCoord(segment.stop)}
        />
      )}
      <div
        id={`${segment.id}-${segment.eventId}-edited`}
        data-id={segment.id}
        className={segmentClass()}
        style={{
          left: getStart(),
          display: 'flex',
          position: 'absolute',
        }}
        onClick={(e) => showEditModals(e, segment.id)}
      >
        <div style={getSegmentStyle()} data-id={segment.id}>
          {segment.isZeroLength || segment.stop - segment.start < 0.1 ? (
            <div className="rhombus rhombusYellow" data-id={segment.id}></div>
          ) : null}
        </div>
        {segment.id ? (
          // {% TODO артефакт, надо отрефакторить и убрать, Пока завязано на клик вне зоны событий %}
          <div
            data-id={segment.id}
            ref={copyDeleteModalRef}
            style={{ position: 'absolute', top: '-50px', width: 0, height: 0 }}
          />
        ) : null}
      </div>
      {segment.hasRightHandle && event.has_duration && (
        <NewSegmentHandle
          data-id={segment.id}
          handleKeyDown={handleDragKeyDown}
          handleRef={rightDragHandleRef}
          handleId={`${segment.id}-right-${segment.eventId}`}
          handleSegmentDrag={handleSegmentDrag}
          posLeft={posLeft}
          direction={'right'}
          offsetLeft={0}
          offsetRight={0}
          limitLeft={getTimeCoord(segment.start)}
          limitRight={getTimeCoord(segment.next)}
        />
      )}
    </>
  );
};
