/* eslint-disable no-case-declarations */
// import { Active } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { createSlice } from '@reduxjs/toolkit';

import {
  ADD_NEW_EDITED_EVENT,
  EVENT_WINDOW_MODE,
  OBSERVE_RECORDING,
  REMOVE_EDITED_EVENT,
  REMOVE_TRACK,
  SET_EVENT_COORDINATES_ACTION,
  SET_PLAYED_FRAME,
  UPDATE_ALL_ACTIVE_EVENTS,
  UPDATE_EDITED_EVENT,
} from 'constants/actionTypes';
import { RecorderActionTypes } from 'store/actions/recorder.actions';
import {
  ActiveEventType,
  EditedEventType,
  EventAttributeType,
  PlayedFrameType,
  TrackType,
  UserPointType,
} from 'types';
import { filterAndIndex } from 'utils/functions';

type InitialStateType = {
  editedEvents: Array<EditedEventType>;
  activeEvents: Array<ActiveEventType>;
  userPoints: Array<UserPointType>;
  playerSettings: any;
  playedFrame: PlayedFrameType | null;
  eventWindowMode: boolean;
  playerIsSkipped: boolean;
  errors: Array<string>;
};
const initialState: InitialStateType = {
  activeEvents: [],
  editedEvents: [],
  userPoints: [],
  playerSettings: null,
  playedFrame: null,
  eventWindowMode: false,
  playerIsSkipped: false,
  errors: [],
};

const recorderReducer = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action: RecorderActionTypes,
): InitialStateType => {
  switch (action.type) {
    // done
    case ADD_NEW_EDITED_EVENT: // +++
      // eslint-disable-next-line no-case-declarations
      const newEdited = [
        { ...action.payload, track: { ...action.payload.track } },
        ...state.editedEvents,
      ];

      // eslint-disable-next-line no-case-declarations
      const activeEvent = filterAndIndex(
        state.activeEvents,
        action.payload.id,
        'id',
      );
      // eslint-disable-next-line no-case-declarations
      let newActiveEvents;
      if (activeEvent.index >= 0) {
        const newTracks = [
          ...activeEvent.element.tracks,
          { ...action.payload.track },
        ];
        // @ts-ignore
        newTracks.sort((a, b) => {
          if (!!a.start && !!b.start) {
            return a.start - b.start;
          }
        });
        newActiveEvents = [
          ...state.activeEvents.slice(0, activeEvent.index),
          {
            ...activeEvent.element,
            tracks: [...newTracks],
          },
          ...state.activeEvents.slice(activeEvent.index + 1),
        ];
      } else {
        newActiveEvents = [
          ...state.activeEvents,
          {
            id: action.payload.id,
            name: action.payload.name,
            tracks: [{ ...action.payload.track }],
            isVisible: true,
          },
        ];
      }
      return {
        ...state,
        editedEvents: [...newEdited],
        activeEvents: [...newActiveEvents],
      };
    // done
    case REMOVE_EDITED_EVENT:
      return {
        ...state,
        activeEvents: [...action.payload.activeEvents],
        editedEvents: [...action.payload.editedEvents],
      };
    // done
    case UPDATE_EDITED_EVENT:
      return {
        ...state,
        editedEvents: [...action.payload],
      };
    // done
    case SET_EVENT_COORDINATES_ACTION:
      return {
        ...state,
        editedEvents: [],
      };
    // done
    case OBSERVE_RECORDING:
      return {
        ...state,
        activeEvents: [...action.payload.activeEvents],
        editedEvents: [...action.payload.editedEvents],
      };
    // done
    case UPDATE_ALL_ACTIVE_EVENTS:
      return {
        ...state,
        activeEvents: [...action.payload],
      };
    // done
    case REMOVE_TRACK:
      return {
        ...state,
        activeEvents: action.payload.activeEvents,
      };
    // done
    case SET_PLAYED_FRAME:
      return {
        ...state,
        playedFrame: action.payload,
      };
    // done
    case EVENT_WINDOW_MODE:
      return {
        ...state,
        eventWindowMode: action.payload,
      };
    default:
      return state;
  }
};
export default recorderReducer;

export const recorderReducerNew = createSlice({
  name: 'recorderReducer',
  initialState: initialState,
  reducers: {
    setNewError(state, action) {
      state.errors.push(action.payload.error);
    },
    removeError(state, action) {
      state.errors = state.errors.filter(
        (error: string) => error !== action.payload.error,
      );
    },
    setPlayerSkipped(state, action) {
      state.playerIsSkipped = action.payload.isSkipped;
    },
    addEditedEventAction(state, action) {
      state.activeEvents = action.payload.activeEvents;
      state.editedEvents = action.payload.editedEvents;
    },
    removeEditedEventAction(state, action) {
      // TODO getCurrentEditedEvent
      state.editedEvents = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id !== action.payload.eventId,
      );
    },
    observeRecordingAction(state, action) {
      // Process ActiveEvents
      let overlapsNext = false;
      const fni = state.activeEvents.filter(
        (el: ActiveEventType) => el.id === action.payload.eventId,
      );
      const fniIndex = state.activeEvents.findIndex(
        (el: ActiveEventType) => el.id === action.payload.eventId,
      );
      const activeEventTrack = fni[0].tracks.filter(
        (track: TrackType) => track.isRecording,
      );
      const activeEventTrackIndex = fni[0].tracks.findIndex(
        (el) => el.id === activeEventTrack[0].id,
      );
      let oldStop = activeEventTrack[0].stop;
      const updatedActiveEventTrack = {
        ...activeEventTrack[0],
        stop: action.payload.stop,
        isRecording: action.payload.isRecording,
      };
      // проверить, что текущий трек не уперся в следующий трек
      if (activeEventTrackIndex >= 0) {
        // записываемый трек найден
        if (fni[0].tracks.length - 1 > activeEventTrackIndex) {
          // если этот трек не последний
          const st = fni[0].tracks[activeEventTrackIndex + 1].start;
          if (st !== null && st > action.payload.stop) {
            oldStop = null;
          } else {
            updatedActiveEventTrack.stop =
              fni[0].tracks[activeEventTrackIndex + 1].start;
            updatedActiveEventTrack.isRecording = action.payload.isRecording;
            overlapsNext = true;
          }
        } else {
          oldStop = null;
        }
        fni[0].tracks.splice(activeEventTrackIndex, 1, updatedActiveEventTrack);
      }
      if (fniIndex >= 0) {
        state.activeEvents.splice(fniIndex, 1, fni[0]);
      }
      // Process EditedEvents
      const editedEvent = state.editedEvents.filter(
        (event: EditedEventType) => event.id === action.payload.eventId,
      )[0];
      if (action.payload.isRecording !== undefined) {
        editedEvent.track.isRecording = action.payload.isRecording;
      }
      editedEvent.track.stop = overlapsNext ? oldStop : action.payload.stop;
    },
    removeTrackAction(state, action) {
      const activeEvent = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.eventId,
      )[0];
      activeEvent.tracks = activeEvent.tracks.filter(
        (track: TrackType) => track.id !== action.payload.trackId,
      );
      if (activeEvent.tracks.length > 0) {
        // @ts-ignore
        activeEvent.tracks.sort((a, b) => {
          if (!!a.start && !!b.start) {
            return a.start - b.start;
          }
        });
      } else {
        state.activeEvents = state.activeEvents.filter(
          (evt: ActiveEventType) => evt.id !== action.payload.eventId,
        );
      }
      state.editedEvents = state.editedEvents.filter(
        (evt) => evt.id !== action.payload.eventId,
      );
    },
    moveTrackToNewEvent(state, action) {
      const activeEvent = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.eventId,
      )[0];
      const removedTrack = activeEvent.tracks.filter(
        (track: TrackType) => track.id === -1,
      )[0];
      activeEvent.tracks = activeEvent.tracks.filter(
        (track: TrackType) => track.id !== -1,
      );
      removedTrack.id = action.payload.trackId;
      removedTrack.stop = action.payload.stop;
      removedTrack.isRecording = false;
      if (activeEvent.tracks.length > 0) {
        // @ts-ignore
        activeEvent.tracks.sort((a, b) => {
          if (!!a.start && !!b.start) {
            return a.start - b.start;
          }
        });
      } else {
        state.activeEvents = state.activeEvents.filter(
          (evt: ActiveEventType) => evt.id !== action.payload.eventId,
        );
      }
      state.editedEvents = state.editedEvents.filter(
        (evt) => evt.id !== action.payload.eventId,
      );
      const newActiveEvents = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.targetEventId,
      );
      if (newActiveEvents.length > 0) {
        newActiveEvents[0].tracks.push(removedTrack);
        // @ts-ignore
        newActiveEvents[0].tracks.sort((a, b) => {
          if (!!a.start && !!b.start) {
            return a.start - b.start;
          }
        });
      } else {
        const newEvent = {
          id: action.payload.targetEventId,
          name: action.payload.eventName,
          tracks: [removedTrack],
          isVisible: true,
        };
        state.activeEvents.unshift(newEvent);
      }
    },
    selectTrackForEdit(state, action) {
      const selectedActive = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.eventId,
      )[0];
      const selectedTrack = selectedActive.tracks.filter(
        (track: any) => track.id === action.payload.trackId,
      )[0];
      const newEditedEvent: EditedEventType = {
        id: selectedActive.id,
        name: selectedActive.name,
        track: selectedTrack,
      };
      state.editedEvents = [newEditedEvent];
    },
    stopEditingOrRecordingEventAction(state, action) {
      const element = state.editedEvents.filter(
        (evt) => evt.id === action.payload.eventId,
      )[0];
      const wasRecording = element?.track?.isRecording;
      if (
        element?.track.id === action.payload.trackId ||
        (element?.track.id === -1 && element.track.isRecording)
      ) {
        if (
          element.track.isRecording &&
          action.payload.currentPosition !== null &&
          element.track.id === -1
        ) {
          element.track.stop = action.payload.currentPosition;
          element.track.isRecording = false;
          element.track.id = action.payload.trackId;
        }
        const newTrack = {
          start: element.track.start,
          stop: element.track.stop,
          id: action.payload.trackId,
          isRecording: false,
          coord_x: element.track.coord_x,
          coord_y: element.track.coord_y,
          attributes: [...element.track.attributes],
          fast_event: element.track.fast_event,
        };
        const stoppedEvent = state.activeEvents.filter(
          (evt: ActiveEventType) => evt.id === action.payload.eventId,
        )[0];
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const trackIndex = stoppedEvent.tracks.findIndex((spec: any) => {
          if (wasRecording) {
            return spec.id === -1;
          } else {
            return spec.id === action.payload.trackId;
          }
        });
        stoppedEvent.tracks.splice(trackIndex, 1, newTrack);
        state.editedEvents = state.editedEvents.filter(
          (evt) => evt.id !== action.payload.eventId,
        );
      }
    },
    setEventCoordinatesAction(state, action) {
      const activeEvent = state.activeEvents.filter(
        (evt) => evt.id === action.payload.eventId,
      )[0];
      const editedEvent = state.editedEvents.filter(
        (evt) => evt.id === action.payload.eventId,
      )[0];
      const activeEventTrack = activeEvent.tracks.filter(
        (track) => track.id === action.payload.trackId,
      )[0];
      editedEvent.track.coord_x = action.payload.coord_x;
      editedEvent.track.coord_y = action.payload.coord_y;
      activeEventTrack.coord_x = action.payload.coord_x;
      activeEventTrack.coord_y = action.payload.coord_y;
    },
    setActiveEvents(state, action) {
      state.activeEvents = action.payload;
    },
    pushActiveEventAction(state, action) {
      const fni = state.activeEvents.filter(
        (el: ActiveEventType) => el.id === action.payload.eventId,
      );
      const fniIndex = state.activeEvents.findIndex(
        (el: ActiveEventType) => el.id === action.payload.eventId,
      );
      if (fniIndex >= 0) {
        fni[0]?.tracks.push(action.payload.track);
      } else {
        state.activeEvents.push(action.payload.event);
      }
    },
    addNewEditedEventAction(state, action) {
      state.editedEvents.push({
        ...action.payload,
        track: action.payload.track,
      });
      const activeEvents = state.activeEvents.filter(
        (evt) => evt.id === action.payload.id,
      );
      if (activeEvents.length > 0) {
        activeEvents[0].tracks.push(action.payload.track);
        //@ts-ignore
        activeEvents[0].tracks.sort((a, b) => {
          if (!!a.start && !!b.start) {
            return a.start - b.start;
          }
        });
      } else {
        state.activeEvents.push({
          id: action.payload.id,
          name: action.payload.name,
          tracks: [action.payload.track],
          isVisible: true,
        });
        state.activeEvents = arrayMove(
          state.activeEvents,
          state.activeEvents.length - 1,
          0,
        );
      }
    },
    setPlayerFrameAction(state, action) {
      state.playedFrame = action.payload;
    },
    setEventWindowModeAction(state, action) {
      state.eventWindowMode = action.payload;
    },
    updateTrackAttributes(state, action) {
      const activeEvent = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.eventId,
      )[0];
      const activeEventTrack = activeEvent.tracks.filter(
        (trck: TrackType) => trck.id === action.payload.trackId,
      )[0];
      activeEventTrack.attributes = action.payload.attributes;
      const editedEvents = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id === action.payload.eventId,
      );
      if (
        editedEvents.length &&
        editedEvents[0].track.id === action.payload.trackId
      ) {
        editedEvents[0].track.attributes = action.payload.attributes;
      }
    },
    updateEditedTrackAttributes(state, action) {
      const editedEvents = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id === action.payload.eventId,
      );
      if (editedEvents.length) {
        editedEvents[0].track.attributes = action.payload.attributes;
      }
    },
    updateEditedTrack(state, action) {
      const activeEvent = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.eventId,
      )[0];
      const activeEventTrack = activeEvent.tracks.filter(
        (trck: TrackType) => trck.id === action.payload.trackId,
      )[0];
      const editedEvent = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id === action.payload.eventId,
      )[0];
      editedEvent.track.start = action.payload.start;
      editedEvent.track.stop = action.payload.stop;
      activeEventTrack.start = action.payload.start;
      activeEventTrack.stop = action.payload.stop;
    },
    setPlayerActionToEdit(state, action) {
      const editedEvent = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id === action.payload.eventId,
      )[0];
      const activeEvent = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.eventId,
      )[0];
      for (const newAttr of action.payload.attributes) {
        const existingAttribute = editedEvent.track.attributes.filter(
          (attr: any) => attr.id === newAttr.id,
        )[0];
        if (existingAttribute) {
          const el = filterAndIndex(
            editedEvent.track.attributes,
            newAttr.id,
            'id',
          );
          editedEvent.track.attributes.splice(el.index, 1, newAttr);
        } else if (Object.keys(newAttr).length) {
          editedEvent.track.attributes.push(newAttr);
        }
        const activeTrack = activeEvent.tracks.filter(
          (tr: any) => tr.id === editedEvent.track.id,
        )[0];
        if (activeTrack) {
          const existingActiveAttribute = activeTrack.attributes.filter(
            (attr: any) => attr.id === newAttr.id,
          )[0];
          if (existingActiveAttribute) {
            const el = filterAndIndex(activeTrack.attributes, newAttr.id, 'id');
            activeTrack.attributes.splice(el.index, 1, newAttr);
          } else if (Object.keys(newAttr).length) {
            activeTrack.attributes.push(newAttr);
          }
        }
      }
    },
    deletePlayerActionToEdit(state, action) {
      const editedEvent = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id === action.payload.eventId,
      )[0];
      const activeEvent = state.activeEvents.filter(
        (evt: ActiveEventType) => evt.id === action.payload.eventId,
      )[0];
      editedEvent.track.attributes = editedEvent.track.attributes.filter(
        (attr: any) => attr.id !== action.payload.player.id,
      );
      const activeTrack = activeEvent.tracks.filter(
        (tr: any) => tr.id === editedEvent.track.id,
      )[0];
      if (activeTrack) {
        activeTrack.attributes = activeTrack.attributes.filter(
          (attr: any) => attr.id !== action.payload.player.id,
        );
      }
    },
    addAdditionalAttributeToTrack(state, action) {
      const editedEvent = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id === action.payload.eventId,
      )[0];
      const exisingAttribute = editedEvent.track.attributes.filter(
        (attr: EventAttributeType) => attr.id === action.payload.attribute.id,
      );
      if (exisingAttribute.length > 0) {
        exisingAttribute[0].values.push(...action.payload.attribute.values);
      } else {
        editedEvent.track.attributes.push(action.payload.attribute);
      }
    },
    removeAdditionalAttributeFromTrack(state, action) {
      const editedEvent = state.editedEvents.filter(
        (evt: EditedEventType) => evt.id === action.payload.eventId,
      )[0];
      const attributeIndex = editedEvent.track.attributes.findIndex(
        (att: EventAttributeType) => att.id === action.payload.attributeId,
      );
      editedEvent.track.attributes[attributeIndex].values =
        editedEvent.track.attributes[attributeIndex].values.filter(
          (val: any) => val.id !== action.payload.valueId,
        );
      if (editedEvent.track.attributes[attributeIndex].values.length === 0) {
        editedEvent.track.attributes = editedEvent.track.attributes.filter(
          (attr: EventAttributeType) => attr.id !== action.payload.attributeId,
        );
      }
    },
  },
});
