import React, { FC, useEffect, useRef, useState } from 'react';

import {
  CheckCircleOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import zeroLength from 'resources/img/zeroLength.svg';
import zeroLengthRed from 'resources/img/zeroLengthRed.svg';
import {
  RemoveActivePossession,
  ReplaceActivePossession,
  SendDeleteTrackRequest,
  UpdateDataActivePossession,
} from 'store/actions/possession.actions';
import { AppStateType } from 'store/reducers';
import { ActiveEventType } from 'types';
import { State } from 'types/app';

import { NewSegmentHandle } from '../NewSegmentHandle';

interface PossessionSegmentInterface {
  track: any;
  prevTrack: any;
  nextTrack: any;
  event: ActiveEventType;
  parent: any;
  videoLength: number;
  isActive: boolean;
  handleVideoScroll: (ms: number) => void;
}

export const PossessionSegment: FC<PossessionSegmentInterface> = ({
  track,
  videoLength,
  parent,
  event,
  isActive,
  prevTrack,
  nextTrack,
  handleVideoScroll,
}) => {
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );
  const dispatch = useDispatch();
  const leftDragHandleRef = useRef(null);
  const rightDragHandleRef = useRef(null);
  const [deleteAgree, setDeleteAgree] = useState(false);
  const [status, setStatus] = useState(State.init);

  const getWidth = () => {
    return (
      parent?.current?.offsetWidth *
      ((track?.stop - track?.start) / videoLength)
    );
  };

  const getStart = () => {
    return parent?.current?.offsetWidth * (track?.start / videoLength);
  };

  const calculatePositionLeft = () => {
    return getStart() + getWidth();
  };
  const [posLeft, setPosLeft] = useState(calculatePositionLeft());
  useEffect(() => {
    setPosLeft(calculatePositionLeft());
  }, [track.start, track.stop]);

  const getTimeCoord = (time: number) => {
    return parent?.current?.offsetWidth * (time / videoLength);
  };
  const handleTrackDrag = (
    e: any,
    newPosition: number,
    drager: 'left' | 'right',
  ) => {
    dispatch(
      UpdateDataActivePossession({
        start: drager === 'left' ? newPosition * videoLength : track.start,
        stop: drager === 'right' ? newPosition * videoLength : track.stop,
      }),
    );
  };

  const segmentClass = () => {
    return `segment${isActive ? ' editedSegment' : ' '} ${track.isRecording ? 'recordedNewSegment' : 'newSegmentTrack'}`;
  };
  const getSegmentStyle = () => {
    const width = getWidth();
    const style: any = {
      width: width || 0,
    };
    if (width === 0) {
      style.position = 'relative';
      style.left = -4;
    }
    return style;
  };
  const handleClick = () => {
    if (isActive) {
      return;
    }
    handleVideoScroll(track.start * 1000);
    dispatch(ReplaceActivePossession(track));
  };
  const handleDelete = async () => {
    setStatus(State.deleting);
    const result = await dispatch(SendDeleteTrackRequest(track));
    if (result != undefined) {
      setStatus(State.init);
    } else {
      setStatus(State.error);
    }
    dispatch(RemoveActivePossession(track.id));
  };

  useEffect(() => {
    if (!track.isRecording) {
      return;
    }
    dispatch(
      UpdateDataActivePossession({
        stop: playedSec > track.start ? playedSec : track.stop,
      }),
    );
  }, [playedSec]);
  const handleDragKeyDown = (position: number) => {
    handleVideoScroll(position * videoLength * 1000);
  };
  return (
    <>
      {isActive && (
        <NewSegmentHandle
          handleKeyDown={handleDragKeyDown}
          handleRef={leftDragHandleRef}
          handleId={`${track.id}-left-${event.id}`}
          handleSegmentDrag={handleTrackDrag}
          posLeft={getStart()}
          offsetLeft={-10}
          offsetRight={0}
          direction={'left'}
          limitLeft={getTimeCoord(prevTrack?.stop || 0)}
          limitRight={getTimeCoord(track.stop)}
        />
      )}
      <div
        className={segmentClass()}
        style={{
          left: getStart() || 0,
          display: 'flex',
          position: 'absolute',
        }}
        onClick={() => handleClick()}
      >
        <div style={getSegmentStyle()}>
          {track.stop - track.start < 0.1 ? (
            <img src={track.isRecording ? zeroLengthRed : zeroLength} />
          ) : null}
        </div>
        {isActive && (
          <Button
            className="possessionEventDeleteButton"
            type="text"
            shape="circle"
            icon={
              status == State.deleting ? (
                <LoadingOutlined />
              ) : deleteAgree ? (
                <CheckCircleOutlined />
              ) : (
                <DeleteOutlined />
              )
            }
            size="small"
            danger={status == State.error}
            onClick={() => {
              if (deleteAgree) {
                handleDelete();
              } else {
                setDeleteAgree(!deleteAgree);
              }
            }}
          />
        )}
      </div>
      {isActive && (
        <NewSegmentHandle
          handleKeyDown={handleDragKeyDown}
          handleRef={rightDragHandleRef}
          handleId={`${track.id}-right-${event.id}`}
          handleSegmentDrag={handleTrackDrag}
          posLeft={posLeft}
          direction={'right'}
          offsetLeft={0}
          offsetRight={0}
          limitLeft={getTimeCoord(track.start)}
          limitRight={getTimeCoord(nextTrack?.start || videoLength)}
        />
      )}
    </>
  );
};
