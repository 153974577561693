/* eslint-disable sonarjs/no-duplicate-string */
import React, { FC } from 'react';

import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import CopyIconGrey from 'resources/img/copyIconGrey';
import DeleteIcon from 'resources/img/deleteIcon.svg';
import { RemoveTrackActionV2 } from 'store/actions/recorder.actions';
import { AppStateType } from 'store/reducers';
import { fastEventReducer } from 'store/reducers/fastEvent.reducer';
import './index.css';
// import { recorderReducerNew } from 'store/reducers/recorder.reducer';
interface Interface {
  isOpen: boolean;
  toggleCopyModal: any;
  setCopyTrack: any;
  // setLocalEditedEvent: any
  keyDownHandler: any;
  // eventHandlers: { goToPreviousEvent: any; goToNextEvent: any };
}

export const EditedEventActionsModal: FC<Interface> = ({
  isOpen,
  toggleCopyModal,
  setCopyTrack,
  // setLocalEditedEvent,
  // keyDownHandler,
  // eventHandlers,
}) => {
  const { activeEvents, editedEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  ); // Редактируемые/записываемые в настоящий момент события
  const { showCoordinatesModal } = useSelector(
    (state: AppStateType) => state.fastEventReducer,
  ); // Редактируемые/записываемые в настоящий момент события
  const { setShowCoordinatesModal, removeFastEventAction } =
    fastEventReducer.actions;
  const copyClickHandle = () => {
    // обработчик клика по иконке копирования
    const tracks = activeEvents.filter(
      (evt: any) => evt.id === editedEvents[0].id,
    )[0].tracks;
    setCopyTrack(
      tracks.filter((track: any) => track.id === editedEvents[0].track.id)[0],
    );
    toggleCopyModal();
  };
  // const scrollOneSecondBack = () => {
  //   const e = new KeyboardEvent('keypress', {
  //     key: 'ArrowLeft',
  //     code: 'ArrowLeft',
  //     shiftKey: false,
  //   });
  //   keyDownHandler(e);
  // };
  // const scrollOneSecondForward = () => {
  //   const e = new KeyboardEvent('keypress', {
  //     key: 'ArrowRight',
  //     code: 'ArrowRight',
  //     shiftKey: false,
  //   });
  //   keyDownHandler(e);
  // };
  // const goToNextEvent = () => {
  //   // eventHandlers.goToNextEvent();
  //   console.log('depreceted');
  // };
  // const goToPreviousEvent = () => {
  //   // eventHandlers.goToPreviousEvent();
  //   console.log('depreceted');
  // };
  const dispatch = useDispatch();
  const deleteEvent = () => {
    // удаляем трек
    dispatch(RemoveTrackActionV2(editedEvents[0].track.id, editedEvents[0].id));
    if (showCoordinatesModal) {
      dispatch(setShowCoordinatesModal(false));
    }
    dispatch(removeFastEventAction());
  };
  return (
    <ReactModal
      overlayClassName="editEventOverlay"
      isOpen={isOpen}
      shouldCloseOnEsc={false}
      className={'editedEventActionsModalContainer'}
      ariaHideApp={false}
      style={{
        content: {
          position: 'fixed',
          top: '92%',
          left: '43%',
          bottom: '10%',
          transform: 'translateY(-10%)',
          // width: "14%",
          gap: '16px',
          borderRadius: '8px',
          background: '#FFF',
          height: 'fit-content',
        },
      }}
    >
      <div>
        {/* TODO убран так как не работает обработчик <SecondBackIconV2Grey
          className={'enabledClickable hoverBlueIcon'}
          data-tooltip-id={'editedEventModalTooltip'}
          data-tooltip-content={'Переместить на 1 кадр назад (<=)'}
          data-tooltip-place="top"
          onClick={scrollOneSecondBack}
        />
          TODO убран так как не работает обработчик <SecondForwardIconV2Grey
          data-tooltip-id={'editedEventModalTooltip'}
          data-tooltip-content={'Переместить на 1 кадр вперед (=>)'}
          data-tooltip-place="top"
          className={'enabledClickable hoverBlueIcon'}
          onClick={scrollOneSecondForward}
        /> */}
      </div>
      <div>
        {/* <ArrowLeftIconGrey
          data-tooltip-id={'editedEventModalTooltip'}
          data-tooltip-content={'Предыдущее событие (c)'}
          data-tooltip-place="top"
          className={'enabledClickable hoverBlueIcon'}
          style={{}}
          onClick={goToPreviousEvent}
        />
        <ArrowLeftIconGrey
          data-tooltip-id={'editedEventModalTooltip'}
          data-tooltip-content={'Следующее событие (v)'}
          data-tooltip-place="top"
          className={'enabledClickable hoverBlueIcon'}
          style={{ transform: 'rotate(180deg)' }}
          onClick={goToNextEvent}
        /> */}
      </div>
      <div>
        <CopyIconGrey
          onClick={copyClickHandle}
          data-tooltip-id={'editedEventModalTooltip'}
          data-tooltip-content={'Копировать событие'}
          data-tooltip-place="top"
          className={'enabledClickable hoverBlueIcon hoverBlueFillIcon'}
        />
      </div>
      <div>
        <img
          data-tooltip-id={'editedEventModalTooltip'}
          data-tooltip-content={'Удалить событие'}
          data-tooltip-place="top"
          onClick={deleteEvent}
          className={'enabledClickable'}
          src={DeleteIcon}
        />
      </div>
      <Tooltip id={'editedEventModalTooltip'} noArrow={true} />
    </ReactModal>
  );
};
