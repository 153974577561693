import React, { FC, useEffect, useRef } from 'react';

import { Placement } from 'store/raw/types';

import { PlacementSegment } from './PlacementSegment';

interface PlacementRowInterface {
  handleVideoScroll: (ms: number) => void;
  horizontalScroll: any;
  zoomValue: any;
  title: string;
  placements: Placement[];
}

export const PlacementRow: FC<PlacementRowInterface> = ({
  handleVideoScroll,
  horizontalScroll,
  zoomValue,
  title,
  placements,
}) => {
  const ref = useRef<any>();
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.style.left = `-${horizontalScroll}px`;
  }, [horizontalScroll]);
  const sortedPlacements = [...placements].sort((a, b) => {
    if (a.finished_at > b.finished_at) {
      return 1;
    }
    if (a.finished_at < b.finished_at) {
      return -1;
    }
    return 0;
  });
  return (
    <div className={'eventRecorderHolder'}>
      <div className={'eventTrackName'}>{title}</div>
      <div className="event-track-container">
        <div
          ref={ref}
          className="segments-container"
          style={{
            width: `${100 * (1 + zoomValue / 100)}%`,
          }}
        >
          {sortedPlacements.map((placement: Placement, index: number) => {
            return (
              <PlacementSegment
                key={`${placement.id}`}
                placement={placement}
                parent={ref}
                handleVideoScroll={handleVideoScroll}
                prevTrack={sortedPlacements[index - 1]}
                nextTrack={sortedPlacements[index + 1]}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
