import React, { FC } from 'react';

import { Layout } from 'antd';
import './style.css';
const { Header, Content } = Layout;

const DefaultLayout: FC<any> = ({ children, header }) => {
  return (
    <Layout style={{ height: '100vh' }}>
      <Header className="header">{header}</Header>
      <Content className="content">{children}</Content>
    </Layout>
  );
};

export default DefaultLayout;
