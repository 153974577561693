import React, { FC } from 'react';

import { Button } from 'antd';

interface CloseTaskInterface {
  finishTask: any;
}
const CloseTask: FC<CloseTaskInterface> = ({ finishTask }) => {
  return (
    <Button type="primary" onClick={finishTask}>
      Завершить
    </Button>
  );
};
export default CloseTask;
