import React, { FC } from 'react';

import { HotkeyType } from 'types';

import { HotkeysListItem } from './HotkeysListItem';

import './hotkeysList.css';

export const HotkeysList: FC<any> = ({
  group,
}: {
  group: Array<HotkeyType>;
}) => {
  return (
    <div className="hotkeyList">
      {group?.map((item: HotkeyType, i: number) => (
        <HotkeysListItem item={item} key={i} />
      ))}
    </div>
  );
};
