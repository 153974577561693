import { ThunkAction } from 'redux-thunk';

import * as API from 'api';
import { AppStateType } from 'store/reducers';
import { possessionSlice } from 'store/slices/possessionSlice';
import { EditedEventType, TrackType } from 'types';
import { validateEvent } from 'utils/functions';
import { extractDefaultAttr } from 'utils/storeHelpers';

import { RecorderActionTypes } from './recorder.actions';

const {
  setPossessionHomeFastEvent,
  setPossessionAwayFastEvent,
  setHomeTeamName,
  setAwayTeamName,
  setPossessions,
  setEvent,
  updateEvent,
  setActivePossession,
} = possessionSlice.actions;

export const SetPossessionsFromTaskActions = (
  actions: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      const [action] = actions.filter(
        (act: any) => act.name.toLowerCase() == 'possession',
      );
      if (action) {
        dispatch(setPossessions(action.tracks));
        dispatch(setEvent(action));
      } else {
        console.log('no possesions in Task');
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const SetPossessionsTeamAndEventFromTask = (
  fastActions: any,
  homeTeamName: string,
  awayTeamName: string,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  return async (dispatch) => {
    try {
      const possessionFastEvent = fastActions.filter(
        (fe: any) => fe.key.toLowerCase() == 'possession',
      );
      const [homePossesionFastEvent] = possessionFastEvent.filter(
        (fe: any) => fe.name.toLowerCase() == 'possession home',
      );
      const [awayPossesionFastEvent] = possessionFastEvent.filter(
        (fe: any) => fe.name.toLowerCase() == 'possession away',
      );
      dispatch(setPossessionHomeFastEvent(homePossesionFastEvent));
      dispatch(setPossessionAwayFastEvent(awayPossesionFastEvent));
      dispatch(setHomeTeamName(homeTeamName));
      dispatch(setAwayTeamName(awayTeamName));
      dispatch(
        updateEvent({
          id: homePossesionFastEvent.event_id,
          name: homePossesionFastEvent.event_name,
        }),
      );
    } catch (err) {
      console.log(err);
    }
  };
};

export const SendCreateOrUpdateRequest = (
  track: any,
): ThunkAction<Promise<void>, AppStateType, undefined, RecorderActionTypes> => {
  return async (dispatch, getState) => {
    const activeTaskId = getState().taskReducer.activeTask?.id;
    const activeEvent = getState().possessionReducer.event;
    const apiTrackHolder: TrackType = {
      id: track.id,
      start: track.start,
      stop: track.stop,
      coord_x: track.coord_x,
      coord_y: track.coord_y,
      attributes: track.attributes,
      isRecording: false,
      fast_event: track.fast_event,
    };
    if (!validateEvent(activeEvent, getState().taskReducer.activeTask)) {
      return;
    }
    if (track.id != -1) {
      return await API.updateEvent(
        apiTrackHolder.start,
        apiTrackHolder.stop,
        apiTrackHolder.attributes,
        activeEvent.id,
        activeTaskId,
        track.id,
        apiTrackHolder.coord_x,
        apiTrackHolder.coord_y,
        apiTrackHolder.fast_event,
      );
    } else {
      return await API.saveNewEvent(
        apiTrackHolder.start,
        apiTrackHolder.stop,
        apiTrackHolder.attributes,
        activeEvent.id,
        activeTaskId,
        apiTrackHolder.coord_x,
        apiTrackHolder.coord_y,
        apiTrackHolder.fast_event,
      );
    }
  };
};

export const AddPossesion = (
  track: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      const possessions = [...getState().possessionReducer.possessions];
      possessions.push(track);
      await dispatch(setPossessions(possessions));
    } catch (err) {
      console.log(err);
    }
  };
};

// export const RemovePossesion = (
//   track: any,
// ): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   return async (dispatch, getState) => {
//     try {
//       const possessions = getState().possessionReducer.possessions.filter(
//         (pos) => pos.id !== track.id,
//       );
//       await dispatch(setPossessions(possessions));
//     } catch (err) {
//       console.log(err);
//     }
//   };
// };

export const SendDeleteTrackRequest = (
  track: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      if (track.id == -1) {
        return;
      }
      const activeTaskId = getState().taskReducer.activeTask?.id;
      if (activeTaskId) {
        return await API.deleteEvent(activeTaskId, track.id);
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };
};

export const CreateActivePossession = (
  team: 'Home' | 'Away' | '',
  startSeconds: number,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      let fastEvent = undefined;
      const possessions = [...getState().possessionReducer.possessions];
      if (team == 'Home') {
        fastEvent = getState().possessionReducer.possessionHomeFastEvent;
      } else if (team == 'Away') {
        fastEvent = getState().possessionReducer.possessionAwayFastEvent;
      } else {
        return;
      }
      const newEditEvent: EditedEventType = {
        id: fastEvent.event_id,
        name: fastEvent.event_name,
        track: {
          id: -1,
          isRecording: true,
          start: startSeconds,
          stop: startSeconds,
          coord_x: null,
          coord_y: null,
          attributes: extractDefaultAttr(fastEvent.default_values),
          fast_event: fastEvent.id,
        },
      };
      await dispatch(setActivePossession(newEditEvent));
      possessions.push(newEditEvent.track);
      await dispatch(setPossessions(possessions));
    } catch (err) {
      console.log(err);
    }
  };
};

export const RemoveActivePossession = (
  id: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      const possessions = getState().possessionReducer.possessions.filter(
        (pos) => pos.id != id,
      );
      await dispatch(setPossessions(possessions));
      await dispatch(setActivePossession(undefined));
    } catch (err) {
      console.log(err);
    }
  };
};

export const ReplaceActivePossession = (
  track: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      const activePossession = getState().possessionReducer.activePossession;
      if (activePossession) {
        // Если есть активное ведение
        const activePossessionClone = {
          ...getState().possessionReducer.activePossession,
        };
        // Сохраняем прошлое активное ведение на бэк
        const response = await dispatch(
          SendCreateOrUpdateRequest(activePossession.track),
        );
        if (activePossession.track.id == -1) {
          // Если прошлое активное ведение еще небыло на бэке, нужно обновить его id после сохранения
          await dispatch(RemoveActivePossession(activePossession.track.id));
          await dispatch(
            AddPossesion({
              ...activePossession.track,
              // @ts-expect-error
              id: response.id,
              isRecording: false,
            }),
          );
        }
        activePossessionClone.track = track;
        await dispatch(setActivePossession(activePossessionClone));
      } else {
        // Если нет активного ведения
        const event = getState().possessionReducer.event;
        const editEvent: EditedEventType = {
          id: event.id,
          name: event.name,
          track,
        };
        await dispatch(setActivePossession(editEvent));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const StopRecordPossession = (): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  any
> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      const activePossession = getState().possessionReducer.activePossession;
      if (!activePossession || activePossession.track.id != -1) {
        return;
      }
      const response = await dispatch(
        SendCreateOrUpdateRequest(activePossession.track),
      );
      await dispatch(
        AddPossesion({
          ...activePossession.track,
          // @ts-expect-error
          id: response.id,
          isRecording: false,
        }),
      );
      await dispatch(RemoveActivePossession(-1));
    } catch (err) {
      console.log(err);
    }
  };
};

export const UpdateDataActivePossession = (
  updatedAttrs: any,
): ThunkAction<Promise<void>, AppStateType, undefined, any> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      const activePossession = {
        ...getState().possessionReducer.activePossession,
      };
      const possessions = getState().possessionReducer.possessions.map(
        (pos) => {
          if (pos.id == activePossession.track.id) {
            return { ...pos, ...updatedAttrs };
          }
          return pos;
        },
      );
      activePossession.track = { ...activePossession.track, ...updatedAttrs };
      await dispatch(setPossessions(possessions));
      await dispatch(setActivePossession(activePossession));
    } catch (err) {
      console.log(err);
    }
  };
};
