/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import './styles.css';

import expandRightBlack from 'resources/img/expandRightBlack.svg';
import { CopyTrackToEventAction } from 'store/actions/recorder.actions';
import { AppStateType } from 'store/reducers';
import {
  ActiveEventType,
  EventAttributeType,
  FastEventType,
  TaskEventsType,
  TrackType,
} from 'types';

interface TaskAcceptModalInterface {
  setHideCopyModal: any;
  clickedTrack: TrackType | null;
  onClickOustide: () => void;
}
export const TaskCopyModal: FC<TaskAcceptModalInterface> = ({
  setHideCopyModal,
  clickedTrack,
  onClickOustide,
}) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const [clickedValueIds, setClickedValueIds] = useState<any>({}); // хранит кликнутные значения атрибутов
  const [selectedEvent, setSelectedEvent] = useState<TaskEventsType | null>(
    null,
  );
  const [shownAttributes, setShownAttributes] = useState<EventAttributeType[]>(
    [],
  );
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>();
  const [selectedAttribute, setSelectedAttribute] = useState<
    EventAttributeType | undefined | null
  >(undefined);
  const [shownAttributeValues, setShownAttributeValues] = useState<Array<any>>(
    [],
  );
  const [showNumberInput, setShowNumberInput] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newNumberValue, setNewNumberValue] = useState<number | undefined>(); // пока не используется

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    availableEvents();
  }, [activeEvents]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setHideCopyModal(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOustide]);

  const finishCopy = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    clickedTrack &&
      selectedEvent &&
      dispatch(
        CopyTrackToEventAction(clickedTrack, selectedEvent, clickedValueIds),
      );
    setHideCopyModal(false);
  };

  const availableEvents = () => {
    // console.log('copy clicked', clickedTrack);
    if (clickedTrack) {
      return activeTask?.task_fast_events.filter((evt: FastEventType) => {
        const activeEvent: ActiveEventType = activeEvents.filter(
          (activeEvt: any) => activeEvt.id === evt.id,
        )[0];
        // console.log('filtered event', activeEvent?.name, 'evt', evt.name);
        if (activeEvent === undefined) {
          // console.log('can copy', evt)
          return true;
        } else {
          // console.log('tracks', activeEvent.tracks)
          const tracks = activeEvent.tracks.filter((track) => {
            return (
              // @ts-ignore
              clickedTrack?.start < track.stop &&
              // @ts-ignore
              clickedTrack.stop > track.start
            );
          });
          // console.log('event', evt.name,'tracks', tracks)
          return tracks.length === 0;
        }
      });
    }
    return [];
  };

  const handleEventClick = (event: TaskEventsType) => {
    setSelectedEvent(event);
    setShownAttributes(event.event_attributes);
  };

  const handleAttributeClick = (attribute: EventAttributeType) => {
    setSelectedAttributeId(attribute.id);
    setShownAttributeValues(attribute.values);
    setSelectedAttribute(attribute);
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleValueClick = (value: any) => {
    // обновляем в памяти выбранные в модалке события
    const holder = { ...clickedValueIds };
    const key = `${selectedAttribute?.id}-${value.id}`;
    if (selectedAttribute) {
      if (selectedAttribute.type !== 'MULTIPLE') {
        if (key in holder) {
          const nh = Object.fromEntries(
            Object.entries(holder).filter(([k]) => k !== key),
          );
          setClickedValueIds({ ...nh });
        } else {
          // TODO гарантировать что при клике по обычным значениям, выбирается только одно
          // const possibleAttributesIds = selectedEvent?.event_attributes.values();
          const nh: any = { ...holder };
          // eslint-disable-next-line @typescript-eslint/no-shadow
          for (const key of Object.keys(clickedValueIds)) {
            if (Number(key.split('-')[0]) === selectedAttribute.id) {
              delete nh[key];
            }
          }
          nh[key] = value.id;
          setClickedValueIds(nh);
          // console.log('заменяем значение 1')
        }
      } else {
        if (key in holder) {
          // console.log('удаляем значение', value, selectedAtt, selectedEventId)
          const nh = Object.fromEntries(
            Object.entries(holder).filter(([k]) => k !== key),
          );
          setClickedValueIds({ ...nh });
        } else {
          // console.log('AAA', editedEvents)
          const nh: any = { ...holder };
          nh[key] = value.id;
          setClickedValueIds({ ...nh });
        }
      }
    }
  };

  const makeValueClassName = (valueId: number) => {
    // стилизация значений атрибутов
    let className =
      selectedAttribute?.type === 'MULTIPLE' ? 'multipleCheckbox ' : '';
    // console.log('update class', clickedValueIds);
    className +=
      !!clickedValueIds &&
      `${selectedAttribute?.id}-${valueId}` in clickedValueIds
        ? 'attributeValueClass selectedElement'
        : 'attributeValueClass';
    return className;
  };
  const handleInputChange = (e: any) => {
    // пока не используется
    setNewNumberValue(e.target.value);
  };
  const handleInputSend = (e: any) => {
    // Подтверждение добавления значения числового атрибута
    if (e.key === 'Enter') {
      // dispatch(addNumericValueToAttribute(e.target.value, selectedAttributeId, selectedEvent.id));
      setNewNumberValue(undefined);
      setShowNumberInput(!showNumberInput);
    }
  };
  return (
    <div className={'modalContainer'}>
      <section
        ref={ref}
        className={'modalCopyModal'}
        style={{ minHeight: '400px' }}
      >
        <div className={'modalContent'}>
          <div
            className={'modalHeader'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {selectedEvent && (
                <img
                  src={expandRightBlack}
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => {
                    setSelectedEvent(null);
                    setShownAttributes([]);
                    setShownAttributeValues([]);
                    setSelectedAttributeId(-1);
                    setClickedValueIds({});
                  }}
                />
              )}
              Событие:
              {selectedEvent && (
                <div
                  style={{ marginLeft: '10px' }}
                  className={'attributeValueClass copy'}
                >
                  {' '}
                  {selectedEvent.name}
                </div>
              )}
            </div>
          </div>
          <div>
            {!selectedEvent && (
              <div className={'eventsHolder'} style={{ marginTop: '1rem' }}>
                {availableEvents()?.map(
                  (evt: TaskEventsType, index: number) => (
                    <div
                      className={'attributeValueClass copy'}
                      onClick={() => handleEventClick(evt)}
                      key={index}
                    >
                      {evt.name}
                    </div>
                  ),
                )}
              </div>
            )}
            {shownAttributes && (
              <div
                style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1rem' }}
              >
                {/*<hr>*/}
                {shownAttributes.map(
                  (attribute: EventAttributeType, index: number) => (
                    <div
                      style={{ marginTop: '1rem' }}
                      className={`attributeValueClass ${attribute.id === selectedAttributeId ? 'selectedElement' : 'inactive'}`}
                      onClick={() => handleAttributeClick(attribute)}
                      key={index}
                    >
                      {attribute.name}
                    </div>
                  ),
                )}
              </div>
            )}
            {shownAttributeValues && (
              <div
                style={{ display: 'flex', flexWrap: 'wrap', marginTop: '1rem' }}
              >
                {shownAttributeValues.map((value: any, index: number) => (
                  <div
                    style={{ marginTop: '0.5rem' }}
                    className={makeValueClassName(value.id)}
                    // className={`attributeValueClass inactive`}
                    key={index}
                    onClick={(e) => handleValueClick(value)}
                  >
                    {value.value}
                  </div>
                ))}
              </div>
            )}
            {/* Пока данный функционал убран, так как надо спроектировать процесс:
                                1) создать новый трек
                                2) запомнить его
                                3) создать новое значение
                                4) обновить ранее созданный трек.
                             */}
            {/*{selectedAttribute?.type === 'NUMBER' ?*/}
            {/*    <>{showNumberInput &&*/}
            {/*    <input type={'number'} name={'newNumberValue'}*/}
            {/*           onChange={(e) => handleInputChange(e)}*/}
            {/*           value={newNumberValue}*/}
            {/*           onKeyDown={(e) => handleInputSend(e, selectedAttribute.id)}/>*/}
            {/*    }*/}
            {/*        <div className={"attributeValueClass"}*/}
            {/*             onClick={() => setShowNumberInput(!showNumberInput)}*/}
            {/*        >+</div>*/}
            {/*    </>: null}*/}
          </div>
          {selectedEvent && (
            <div className={'finishCopyButton'} onClick={() => finishCopy()}>
              Готово
            </div>
          )}
        </div>
      </section>
    </div>
  );
};
