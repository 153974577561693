import React, { FC } from 'react';

import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { SavePlacement } from 'store/actions/placements.action';
import { AppStateType } from 'store/reducers';
import './style.css';

export const OperatorSettingsSchemaPlacement: FC<any> = ({ teamType }) => {
  const dispatch = useDispatch();
  const { placementSchemas } = useSelector(
    (state: AppStateType) => state.rawPlacementSchemasReducer,
  );
  const { homeActivePlacement, awayActivePlacement } = useSelector(
    (state: AppStateType) => state.placementReducer,
  );
  const { currentPeriod } = useSelector(
    (state: AppStateType) => state.periodReducer,
  );

  const handleChange = (val: string) => {
    dispatch(SavePlacement(val, teamType));
  };

  const options = placementSchemas.map((opt) => ({
    value: opt.id,
    label: opt.name,
  }));

  const loadedValue =
    teamType == 'home'
      ? homeActivePlacement?.schema
      : awayActivePlacement?.schema;

  return (
    <Select
      value={loadedValue}
      className="selectGamePlayersPlacement"
      placeholder={
        currentPeriod.id ? 'Выберите схему расстановки' : 'Создайте период'
      }
      onChange={handleChange}
      options={options}
      disabled={!currentPeriod.id}
    />
  );
};
