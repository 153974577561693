import React, { FC, useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { LogoutAction } from 'store/actions/auth.actions';
import { GetCurrentEventsAction } from 'store/actions/recorder.actions';
import { UpdateTaskStatusAction } from 'store/actions/task.actions';
import { AppStateType } from 'store/reducers';
import './styles.css';

interface TaskAcceptModalInterface {
  setShowFinishModal: any;
  onClickOustide: () => void;
}
const TaskAcceptModal: FC<TaskAcceptModalInterface> = ({
  setShowFinishModal,
  onClickOustide,
}) => {
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const dispatch = useDispatch();

  const startTaskHandler = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    activeTask && dispatch(UpdateTaskStatusAction(activeTask?.id, 20));
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    activeTask && dispatch(GetCurrentEventsAction(activeTask.id));
  };

  const finishTaskHandler = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    activeTask && dispatch(UpdateTaskStatusAction(activeTask?.id, 40));
    setShowFinishModal(false);
  };

  const hasActiveTask = activeTask && Object.keys(activeTask).length > 0;

  const header = () => {
    if (activeTask && Object.keys(activeTask).length > 1) {
      return (
        <div>
          <label>Задача: </label>
          {activeTask.name}
        </div>
      );
    } else {
      return (
        <>
          Ожидаем задачу
          <input
            type={'button'}
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onClick={() => logoutButtonHandler()}
            className={'finishButton'}
            value={'Выйти'}
          />
        </>
      );
    }
  };

  const actionButton = () => {
    if (activeTask?.status === 10) {
      return (
        <button className={'actionButton'} onClick={() => startTaskHandler()}>
          Взять в работу
        </button>
      );
    }
    if (activeTask?.status === 30) {
      return (
        <button className={'actionButton'} onClick={() => startTaskHandler()}>
          Задача на паузе. Возобновить в работу
        </button>
      );
    }
    if (activeTask?.status === 20) {
      return (
        <button className={'actionButton'} onClick={() => finishTaskHandler()}>
          Подтвердить выполнение
        </button>
      );
    }
  };
  const ref = useRef(null);
  const logoutButtonHandler = () => {
    dispatch(LogoutAction());
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setShowFinishModal(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOustide]);

  return (
    <div className={'modalContainer'}>
      <section ref={ref} className={'modal'}>
        <div className={'modalContent'}>
          <div className={'modalHeader'}>{header()}</div>
          {hasActiveTask && (
            <div className={'taskComment'}>
              <label>Комментарий:</label>
              <div>{activeTask.description}</div>
            </div>
          )}
          {hasActiveTask && (
            <div className={'taskEvents'}>
              <label>События:</label>
              <div className={'eventsHolder'}>
                {activeTask.task_events?.map((evt: any, index: number) => (
                  <div className={'attributeValueModalClass'} key={index}>
                    {evt.name}
                  </div>
                ))}
              </div>
            </div>
          )}
          {activeTask && activeTask?.status === 40 ? (
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>Задача на проверке</div>
              <input
                type={'button'}
                onClick={() => logoutButtonHandler()}
                className={'finishButton'}
                value={'Выйти'}
              />
            </div>
          ) : (
            actionButton()
          )}
        </div>
      </section>
    </div>
  );
};
export default TaskAcceptModal;
