import React, { FC } from 'react';

import { ConfigProvider, Slider } from 'antd';

export const ScrollerV2: FC<any> = ({
  videoLength,
  value,
  handleVideoScroll,
}) => {
  const handleChange = (v: number) => {
    handleVideoScroll(v * 1000);
  };
  function formatMillisecondsToTime(milliseconds: number) {
    const totalSecondsRaw = milliseconds / 1000;
    const partOfASecond = Math.floor((totalSecondsRaw % 1) * Math.pow(10, 3));
    const totalSeconds = Math.floor(totalSecondsRaw);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}.${partOfASecond}`;
  }
  const formatMillisecondsToTimeWithUndefined = (
    seconds: number | undefined,
  ) => {
    if (seconds !== undefined) {
      return formatMillisecondsToTime(seconds * 1000);
    }
  };
  const color = 'var(--Primary-5, #40A9FF)';
  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            railBg: 'var(--colorFill)',
            railHoverBg: 'var(--colorFill)',
            railSize: 4,
            trackBg: 'var(--colorPrimaryHover)',
            handleActiveColor: color,
            handleColor: color,
            handleSizeHover: 10,
            handleSize: 10,
            handleLineWidthHover: 2,
            handleLineWidth: 2,
            colorPrimaryBorderHover: color,
            colorFillContentHover: color,
          },
        },
      }}
    >
      <Slider
        style={{ margin: 0 }}
        styles={{
          track: { background: color },
        }}
        value={value}
        min={0}
        max={videoLength}
        defaultValue={0}
        step={0.001}
        onChange={handleChange}
        tooltip={{ formatter: formatMillisecondsToTimeWithUndefined }}
      />
    </ConfigProvider>
  );
};
