import { endpoint } from 'constants/constants';
import myAxios from 'utils/axios';

import { createCommonHeader } from './_functions';

export async function saveNewEvent(
  startedAt: number | null,
  finishedAt: number | null,
  attributes: any,
  possibleEvent: number,
  taskId: number | undefined,
  coord_x: number | null,
  coord_y: number | null,
  fast_event: string | null,
) {
  const url = `${endpoint}/api/v2/task/${taskId}/actual_events/`;
  const headers = createCommonHeader();
  const payload = {
    started_at: startedAt,
    finished_at: finishedAt,
    possible_event: possibleEvent,
    attributes: attributes,
    coord_x: coord_x,
    coord_y: coord_y,
    fast_event: fast_event,
  };
  try {
    const response = await myAxios.POST(url, payload, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}
export async function deleteEvent(taskId: number, eventId: number) {
  const url = `${endpoint}/api/v1/task/${taskId}/events/${eventId}/`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.DELETE(url, {}, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}
export async function updateEvent(
  startedAt: number | null,
  finishedAt: number | null,
  attributes: any,
  possibleEvent: number,
  taskId: number | undefined,
  trackId: number,
  coord_x: number | null,
  coord_y: number | null,
  fast_event: string | null,
) {
  const url = `${endpoint}/api/v2/task/${taskId}/actual_events/`;
  const headers = createCommonHeader();
  const payload = {
    id: trackId,
    started_at: startedAt,
    finished_at: finishedAt,
    possible_event: possibleEvent,
    attributes: attributes,
    coord_x: coord_x,
    coord_y: coord_y,
    fast_event: fast_event,
  };
  const response = await myAxios.PATCH(url, payload, headers);
  return response.data;
}

export async function getCurrentEvents(taskId: number) {
  const url = `${endpoint}/api/v1/task/${taskId}/actual_events/`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.GET(url, null, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}
export async function addNewValue(
  eventId: number,
  attributeId: number,
  value: any,
) {
  const url = `${endpoint}/api/v1/events/${eventId}/attribute/${attributeId}/value/`;
  const headers = createCommonHeader();
  try {
    const response = await myAxios.POST(url, value, headers);
    return response.data;
  } catch (e: any) {
    throw new Error(e);
  }
}

export async function removeAllEventsByPlayerAPI(
  playerId: number,
  taskId: any,
) {
  const url = `${endpoint}/api/v1/task/${taskId}/actual_events/clear_by_player/`;
  const headers = createCommonHeader();
  const payload = {
    player_id: playerId,
  };
  const response = await myAxios.POST(url, payload, headers);
  return response.data;
}
