import React, { FC, useEffect, useRef, useState } from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import * as API from 'api';
import { SetLocalVideoUrl } from 'store/actions/video.action';
import { AppStateType } from 'store/reducers';

import './style.css';

const loadVideo = (videoURL: any) =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
        resolve(this);
      };

      video.onerror = function () {
        reject('Invalid video. Please select a video file.');
      };

      video.src = videoURL;
    } catch (e) {
      reject(e);
    }
  });

const requestVideoLink = async (
  taskId: string | number,
  setLink: any,
  setLoad: any,
  setError: any,
) => {
  try {
    setLoad(true);
    setError(false);
    const resp: any = await API.getDownloadVideoLink(taskId);
    setLink(resp.url);
    setLoad(false);
  } catch (e) {
    setLoad(false);
    setError(true);
  }
};

export const OperatorSettingsLoacalVideo: FC<any> = () => {
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { localVideoObjectURL } = useSelector(
    (state: AppStateType) => state.videoReducer,
  );
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const [downloadLink, setDownloadLink] = useState('');
  const [isCreateLinkLoading, setIsCreateLinkLoading] = useState(false);
  const [isCreateLinkError, setIsCreateLinkError] = useState(true);

  const [modal, contextHolder] = Modal.useModal();

  const handleOfflineClick = () => {
    //@ts-expect-error
    fileInputRef?.current?.click();
  };

  const handleOnlineClick = () => {
    dispatch(SetLocalVideoUrl(''));
    if (fileInputRef && fileInputRef.current) {
      // @ts-expect-error
      fileInputRef.current.value = '';
    }
  };

  const openErrorModal = (content: string) => {
    modal.error({
      title: 'Offline video',
      content,
    });
  };

  const fileLinked = async (ev: any) => {
    try {
      const videoURL = URL.createObjectURL(ev.target.files[0]);
      const preloadVideo = await loadVideo(videoURL);
      if (
        preloadVideo //&&
        // Math.floor(preloadVideo.duration) === Math.floor(videoLength)
      ) {
        dispatch(SetLocalVideoUrl(videoURL));
      } else {
        openErrorModal(
          'There was an error while trying to insert your video. Please try another file or stay online.',
        );
      }
    } catch (e) {
      openErrorModal(
        'There was an error. Please try another file or stay online.',
      );
    }
  };

  const downloadVideo = () => {
    if (!activeTask?.id) {
      openErrorModal(
        'There was an error while trying download video. No task was fount, check you page.',
      );
    }
    // fetch download
    // fetch(downloadLink)
    //   .then((resp) => resp.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.style.display = 'none';
    //     a.href = url;
    //     a.download = `${taskId}`;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    //     window.URL.revokeObjectURL(url);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     openErrorModal(
    //       'There was an error while trying download video. Please try again or stay online.',
    //     );
    //   });

    // href download
    const link = document.createElement('a');
    link.href = downloadLink;
    link.target = '_blank';
    link.download = `${activeTask?.id}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    requestVideoLink(
      activeTask?.id || '',
      setDownloadLink,
      setIsCreateLinkLoading,
      setIsCreateLinkError,
    );
  }, []);

  return (
    <div className="videoSourceSwitchContainer">
      {contextHolder}
      <div className="videoSourceSwitchButtons">
        <Button
          type="text"
          shape="round"
          size="small"
          className={clsx({ active: !localVideoObjectURL })}
          onClick={handleOnlineClick}
          disabled={!localVideoObjectURL}
        >
          Online видео
        </Button>
        <Button
          type="text"
          shape="round"
          size="small"
          className={clsx({ active: !!localVideoObjectURL })}
          onClick={handleOfflineClick}
          disabled={!!localVideoObjectURL}
        >
          Offline видео
        </Button>
      </div>
      <Button
        type="text"
        shape="round"
        icon={<DownloadOutlined />}
        size="small"
        loading={isCreateLinkLoading || !downloadLink}
        onClick={downloadVideo}
        danger={isCreateLinkError}
      ></Button>
      <input
        ref={fileInputRef}
        type="file"
        style={{
          visibility: 'hidden',
          width: '1px',
          height: '1px',
        }}
        onChange={fileLinked}
        accept=".mp4, .webm, .ogg"
      />
    </div>
  );
};
