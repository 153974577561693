import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { AppStateType } from 'store/reducers';
import { getScore } from 'utils/functions';
import './style.css';

export const GameScore: FC<any> = () => {
  const { activeTask } = useSelector(
    (state: AppStateType) => state.taskReducer,
  );
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const getGameName = () => {
    if (activeTask?.away_team_name && activeTask.home_team_name) {
      return `${activeTask.home_team_name} ${getScore(activeEvents)} ${activeTask.away_team_name}`;
    }
    return `${activeTask?.name}`;
  };
  return <div className="scoreContainer">{getGameName()}</div>;
};
