/* eslint-disable indent */
import React, { FC, useEffect, useState } from 'react';

import { ConfigProvider, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { SetSwap } from 'store/actions/placements.action';
import { AppStateType } from 'store/reducers';
import { findLastModifiedPlayerNumber, hexToRgb } from 'utils/functions';

import { PlayersFieldItem } from './PlayersFieldItem';
import { PlayersFieldSelect } from './PlayersFieldSelect';

export const PlayersField: FC<any> = ({ playerSize = 16 }) => {
  const dispatch = useDispatch();
  const {
    playerSettings: { color_settings = {} },
    invertedTeamDisplay,
  } = useSelector((state: AppStateType) => state.playerReducer);
  const { placementSchemas } = useSelector(
    (state: AppStateType) => state.rawPlacementSchemasReducer,
  );
  const { homeActivePlacement, awayActivePlacement, swapPlayer } = useSelector(
    (state: AppStateType) => state.placementReducer,
  );
  const { activeEvents } = useSelector(
    (state: AppStateType) => state.recorderReducerNew,
  );
  const playedSec = useSelector(
    (state: AppStateType) => state.videoReducer.playedSec,
  );
  const isSwap = swapPlayer.team !== '';
  const selectedHomeColor = color_settings?.Home;
  const { HomeInvertText = false, AwayInvertText = false } = color_settings;
  const selectedAwayColor = color_settings?.Away;
  const [homePlacementSchema] = placementSchemas.filter(
    (pl) => pl.id == homeActivePlacement?.schema,
  );
  const [awayPlacementSchema] = placementSchemas.filter(
    (pl) => pl.id == awayActivePlacement?.schema,
  );

  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState({ name: '', color: '' });
  const [editedPositionId, setEditedPositionId] = useState(0);
  const [homeSubtitutions, setHomeSubtitutions] = useState([]);
  const [awaySubtitutions, setAwaySubtitutions] = useState([]);

  const hide = () => {
    setOpen(false);
    setSelectedTeam({ name: '', color: '' });
    setEditedPositionId(0);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      setOpen(newOpen);
      setSelectedTeam({ name: '', color: '' });
      setEditedPositionId(0);
    }
  };

  const selectPlayer = (team: any, playerId: number) => {
    setSelectedTeam(team);
    setEditedPositionId(playerId);
    setOpen(true);
  };

  useEffect(() => {
    if (selectedTeam.name !== '') {
      setOpen(true);
    }
  }, [selectedTeam]);

  useEffect(() => {
    const [Substitution = {}] = activeEvents.filter(
      (ae) => ae.name.toLowerCase() === 'substitution',
    );
    //@ts-expect-error
    const { tracks = [] } = Substitution;
    if (tracks.length > 0) {
      const actualTracks = tracks
        .filter((tr: any) => tr.stop <= playedSec)
        .sort((a: any, b: any) => a.stop - b.stop);
      const homeSubtitution: any = [];
      const awaySubtitution: any = [];
      actualTracks.forEach((track: any) => {
        let team;
        let playerIn;
        let playerOut;
        track.attributes.forEach((attr: any) => {
          if (attr.key == 'team') {
            team = attr.values[0]?.value?.toLowerCase();
          }
          if (attr.key == 'player_in') {
            playerIn = attr.values[0]?.value;
          }
          if (attr.key == 'player_out') {
            playerOut = attr.values[0]?.value;
          }
        });
        const subtitution = {
          in: playerIn,
          out: playerOut,
          start: track.start,
          stop: track.stop,
        };
        if (team == 'home') {
          homeSubtitution.push(subtitution);
          return;
        }
        if (team == 'away') {
          awaySubtitution.push(subtitution);
        }
      });
      setHomeSubtitutions(homeSubtitution);
      setAwaySubtitutions(awaySubtitution);
    }
  }, [activeEvents, playedSec]);

  const getPlayerNumber = (activePlacement: any, posId: any) => {
    if (activePlacement?.positions == null) {
      return undefined;
    }
    const [activePos = {}] = activePlacement.positions.filter(
      (actPos: any) => actPos.id == posId,
    );
    return activePos?.player_number;
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: '#E6F4FF',
        },
      }}
    >
      <Popover
        content={
          <PlayersFieldSelect
            close={hide}
            team={{ name: selectedTeam.name, color: selectedTeam.color }}
            positionId={editedPositionId}
            invertedText={
              selectedTeam.name == 'home' ? HomeInvertText : AwayInvertText
            }
            exclude={
              selectedTeam.name == 'home'
                ? homeActivePlacement?.positions?.map(
                    (pos: any) => pos.player_number,
                  )
                : awayActivePlacement?.positions?.map(
                    (pos: any) => pos.player_number,
                  )
            }
          />
        }
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottom"
      >
        <div className="playersField" style={{ fontSize: `${playerSize}px` }}>
          <div className="teamContainer">
            {homePlacementSchema &&
              homePlacementSchema.positions.map((pos: any) => (
                <PlayersFieldItem
                  key={pos.id}
                  positionId={pos.id}
                  isActive={
                    editedPositionId == pos.id && selectedTeam.name == 'home'
                  }
                  activePlayerPos={
                    homeActivePlacement.positions != null
                      ? homeActivePlacement.positions.filter(
                          (activePos: any) => activePos.id == pos.id,
                        )
                      : []
                  }
                  replacedPlayerNumber={findLastModifiedPlayerNumber(
                    homeSubtitutions,
                    getPlayerNumber(homeActivePlacement, pos.id),
                  )}
                  selectPlayer={selectPlayer}
                  team={{ name: 'home', color: selectedHomeColor }}
                  invertedText={HomeInvertText}
                  color={hexToRgb(selectedHomeColor)}
                  style={{
                    top: invertedTeamDisplay
                      ? 'initial'
                      : `${Math.floor(pos.coordY * 100)}%`,
                    bottom: invertedTeamDisplay
                      ? `${Math.floor(pos.coordY * 100)}%`
                      : 'initial',
                    left: invertedTeamDisplay
                      ? 'initial'
                      : `${Math.floor(pos.coordX * 100)}%`,
                    right: invertedTeamDisplay
                      ? `${Math.floor(pos.coordX * 100)}%`
                      : 'initial',
                    marginLeft: !invertedTeamDisplay ? '-1.1em' : 'initial',
                    marginRight: invertedTeamDisplay ? '-1.1em' : 'initial',
                    marginTop: !invertedTeamDisplay ? '-1.1em' : 'initial',
                    marginBottom: invertedTeamDisplay ? '-1.1em' : 'initial',
                    backgroundColor: 'rgba(0, 0, 0, 0.165)',
                  }}
                />
              ))}
            {awayPlacementSchema &&
              awayPlacementSchema.positions.map((pos: any) => (
                <PlayersFieldItem
                  key={pos.id}
                  positionId={pos.id}
                  isActive={
                    editedPositionId == pos.id && selectedTeam.name == 'away'
                  }
                  activePlayerPos={
                    awayActivePlacement.positions != null
                      ? awayActivePlacement.positions.filter(
                          (activePos: any) => activePos.id == pos.id,
                        )
                      : []
                  }
                  replacedPlayerNumber={findLastModifiedPlayerNumber(
                    awaySubtitutions,
                    getPlayerNumber(awayActivePlacement, pos.id),
                  )}
                  selectPlayer={selectPlayer}
                  team={{ name: 'away', color: selectedAwayColor }}
                  invertedText={AwayInvertText}
                  color={hexToRgb(selectedAwayColor)}
                  style={{
                    top: invertedTeamDisplay
                      ? `${Math.floor(pos.coordY * 100)}%`
                      : 'initial',
                    bottom: invertedTeamDisplay
                      ? 'initial'
                      : `${Math.floor(pos.coordY * 100)}%`,
                    right: invertedTeamDisplay
                      ? 'initial'
                      : `${Math.floor(pos.coordX * 100)}%`,
                    left: invertedTeamDisplay
                      ? `${Math.floor(pos.coordX * 100)}%`
                      : 'initial',
                    marginLeft: invertedTeamDisplay ? '-1.1em' : 'initial',
                    marginRight: !invertedTeamDisplay ? '-1.1em' : 'initial',
                    marginTop: '-1.1em',
                    marginBottom: invertedTeamDisplay ? 'initial' : '-1.1em',
                    backgroundColor: 'rgba(255, 255, 255, 0.165)',
                  }}
                />
              ))}
          </div>
        </div>
      </Popover>
      {isSwap && (
        <div
          className="swapBackdrop"
          onClick={() => dispatch(SetSwap({ num: 0, team: '' }))}
        ></div>
      )}
    </ConfigProvider>
  );
};

export default PlayersField;
